import Modal from "react-modal"
import React from 'react'
const nl2br = require('react-nl2br');

interface Props {
  title: string;
  okLabel?: string;
  isOpen: boolean;
  handleCloseModal(): void;
  handleSubmit(): void;
}

const ConfirmModal = (props: Props) => {
  return (
    <div className="modal confirm-modal">
      <Modal
        isOpen={props.isOpen}
        className="modal-dialog"
        overlayClassName="Overlay"
        onRequestClose={props.handleCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={props.handleCloseModal}>
              <span aria-hidden="true">
                <i className="icon icon-close-dark-sm"></i>
              </span>
            </button>
          </div>
          <div className="modal-body">
            <h5 className="modal-text font-weight-normal mb-3 pb-1 mb-lg-4 text-center">{nl2br(props.title)}</h5>
            <ul className="btn-list">
              <li>
                <button className="btn btn-lg btn-border" onClick={props.handleCloseModal}>キャンセル</button>
              </li>
              <li>
                <button className="btn btn-lg btn-primary" onClick={props.handleSubmit}>{ props.okLabel || 'OK' }</button>
              </li>
            </ul>
          </div>
          <div className="modal-footer">
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ConfirmModal