import React, { useCallback } from 'react';

import { useDropzone, DropzoneOptions } from 'react-dropzone'

import {
  AttachmentInput
} from '../../api'

import icon_movie from '../../assets/images/icon_movie.svg';
import icon_media_upload from '../../assets/images/icon_media_uoload.svg';

import classNames from 'classnames';

type Props = {
  attachment?: AttachmentInput | null;
  onDelete: () => void;
  onSelect: (files: any) => void;
  options?: DropzoneOptions;
  isLarge?: boolean;
  isInvalid?: boolean;
}

const FileUploadField: React.FC<Props> = (props: Props) => {
  const onDrop = useCallback(acceptedFiles => {
    props.onSelect(acceptedFiles)
  }, [props])

  const { getRootProps, getInputProps, open } = useDropzone({
    ...props.options,
    noClick: true,
    onDrop,
  })

  return (
    <div className={classNames("media-upload form-control", {
      'is-lg' : props.isLarge,
      'is-invalid' : props.isInvalid,
    })}>
    {
      props.attachment ?
      <>
        {
          props.attachment.mimeType.startsWith('image/') ?
          <img src={props.attachment.url} alt={props.attachment.fileName} className="uploaded" />
          :
          <div className="box-has-icon">
            <i className="icon">
              <img src={icon_movie} width="48" height="31" alt={props.attachment.fileName} />
            </i>
            <p className="file-name">{props.attachment.fileName}</p>
          </div>
        }
        <div className="box-button">
          <button
            className="button btn btn-border"
            onClick={e => {
            e.preventDefault()
            props.onDelete()
            // MARK: 遅延しないとopenが動作しない
            setTimeout(() => {
              open()
            }, 0)
          }}>変更</button>

          <button
            className="button btn btn-border ml-2"
            onClick={e => {
            e.preventDefault()
            props.onDelete()
          }}>削除</button>
        </div>
      </>:
      <>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="box-has-icon">
            <i className="icon">
              <img src={icon_media_upload} width="48" height="32" alt='UPLOAD' />
            </i>
            <p>
              アップロードするファイルをドロップ
              <br />
              またはファイルを選択
            </p>
          </div>
          <div className="box-button">
            <button
              className="button btn btn-border"
              onClick={e => {
                e.preventDefault()
                open()
              }}>
              ファイルを選択</button>
          </div>
        </div>
      </>
    }
    </div>
  )
}

export default FileUploadField;
