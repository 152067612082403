import React, { useMemo } from 'react';

import { DocumentPageModel } from '../../api';

import { DocumentPageLayoutImage, DocumentPageLayoutImageDesignModern } from './DocumentPageLayoutImage';
import { DocumentPageLayoutOverview, DocumentPageLayoutOverviewDesignModern } from './DocumentPageLayoutOverview';
import { DocumentPageLayoutDetailFirst, DocumentPageLayoutDetailFirstDesignModern } from './DocumentPageLayoutDetailFirst';
import { DocumentPageLayoutDetailSecond, DocumentPageLayoutDetailSecondDesignModern } from './DocumentPageLayoutDetailSecond';
import { 
  DocumentPageCompanyProfileFirst,
  DocumentPageCompanyProfileSecond,
  DocumentPageCompanyProfileThird,
} from './DocumentPageCompanyProfile';
import classNames from 'classnames';

export type DocumentPageEditableProp =
  'title' |
  'linkUrl' |
  'startedMonth' |
  'clientName' |
  'productName' |
  'targetSection' |
  'backgroundSection' |
  'overviewSection' |
  'pointSection' |
  'resultSection' |
  'catchphrase'

export interface DocumentPageProps {
  designType: string;
  documentPage: DocumentPageModel;
  index: number;
  isSelected?: boolean;
  editable?: boolean;
  onChange?: (key: DocumentPageEditableProp, value: string) => void;
}

const DocumentPage = React.forwardRef<HTMLDivElement, DocumentPageProps>((props, ref) => {
  const content = useMemo(() => {
    if (props.documentPage.type === 'achivement') {
      if (props.designType === 'modern') {
        if (props.documentPage.layoutType === 'image') {
          return <DocumentPageLayoutImageDesignModern {...props} />
        }
        else if (props.documentPage.layoutType === 'overview') {
          return <DocumentPageLayoutOverviewDesignModern {...props} />
        } else {
          if (props.documentPage.layoutPage === 1) {
            return <DocumentPageLayoutDetailFirstDesignModern {...props} />
          }
          return <DocumentPageLayoutDetailSecondDesignModern {...props} />
        }
      } else {
        if (props.documentPage.layoutType === 'image') {
          return <DocumentPageLayoutImage {...props} />
        }
        else if (props.documentPage.layoutType === 'overview') {
          return <DocumentPageLayoutOverview {...props} />
        } else {
          if (props.documentPage.layoutPage === 1) {
            return <DocumentPageLayoutDetailFirst {...props} />
          }
          return <DocumentPageLayoutDetailSecond {...props} />
        }
      }
    } else {
      if (props.documentPage.layoutPage === 1) {
        return <DocumentPageCompanyProfileFirst {...props} />
      }
      else if (props.documentPage.layoutPage === 2) {
        return <DocumentPageCompanyProfileSecond {...props} />
      } else {
        return <DocumentPageCompanyProfileThird {...props} />
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.designType,
    props.documentPage.type,
    props.documentPage.layoutPage,
    props.documentPage.layoutType,
    props.documentPage.title,
    props.documentPage.linkUrl,
    props.documentPage.startedMonth,
    props.documentPage.clientName,
    props.documentPage.productName,
    props.documentPage.targetSection,
    props.documentPage.backgroundSection,
    props.documentPage.overviewSection,
    props.documentPage.pointSection,
    props.documentPage.resultSection,
    props.documentPage.catchphrase,
    props.editable,
    props.index,
  ]);

  return <div ref={ref}
    className={classNames('doc-works-slide', {
      'layout-image': props.documentPage.layoutType === 'image',
      'layout-overview': props.documentPage.layoutType === 'overview',
      'layout-detail-1': props.documentPage.layoutType === 'detail' && props.documentPage.layoutPage === 1,
      'layout-detail-2': props.documentPage.layoutType === 'detail' && props.documentPage.layoutPage === 2,
      'is-company': props.documentPage.type === 'profile',
      'company-1': props.documentPage.type === 'profile' && props.documentPage.layoutPage === 1,
      'company-2': props.documentPage.type === 'profile' && props.documentPage.layoutPage === 2,
      'company-3': props.documentPage.type === 'profile' && props.documentPage.layoutPage === 3,
    })}
    style={{ zIndex: props.isSelected ? 1 : 0 }}
  >{ content }</div>
});

export default DocumentPage;
