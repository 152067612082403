import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import { AchivementModel } from '../../api';
import AchivementDetailComponent from '../coding/common/AchivementDetail';
import { useUiContext } from "../../contexts/ui";

interface Props {
  achivement?: AchivementModel;
}

const AchivementDetail = (props: Props) => {
  const { achivement } = props;
  const history = useHistory();

  const [viewIndex, setViewIndex] = useState(0);

  const [uiState, uiDispatch] = useUiContext()

  const searchCondition = uiState.achivementsSearch

  const searchByCategory = (category: string) => {
    uiDispatch({
      type: 'setSearchCondition',
      payload: {
        ...searchCondition,
        categories: [category],
        submitCount: searchCondition.submitCount + 1,
      }
    })
    history.push('/achivements/search')
  }

  useEffect(() => {
    if (achivement && !achivement.mainVisual && achivement.subVisuals.length > 0) {
      // MARK: 下書き状態でメインビジュアルがない場合の初期表示を変更
      setViewIndex(1)
    }
  }, [achivement, setViewIndex])

  if (!achivement) return <></>;

  return (
    <AchivementDetailComponent
      viewIndex={viewIndex}
      onClickCategory={(e: React.MouseEvent, category: string) => {
        e.preventDefault()
        searchByCategory(category)
      }}
      onClickVisualThumbnail={(e: React.MouseEvent, index: number) => {
        e.preventDefault()
        setViewIndex(index)
      }}
      achivement={achivement} />
  )
}

export default AchivementDetail;
