import React from "react";
import ReactDOM from "react-dom";

import ConfirmModal from '../modals/ConfirmModal'

const UserConfirmation = (message: string, callback: (ok: boolean) => void) => {
   const container = document.createElement("div");
   container.setAttribute("custom-confirmation-navigation", "");
   document.body.appendChild(container);
   const closeModal = (callbackState: boolean) => {
      ReactDOM.unmountComponentAtNode(container);
      callback(callbackState);
   };
   ReactDOM.render(
      <ConfirmModal
        title={message}
        isOpen={true}
        handleCloseModal={() => closeModal(false)}
        handleSubmit={() => closeModal(true)}         
      />,
      container);
};
export default UserConfirmation;