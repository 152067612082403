import {
  getApi,
  login,
  upload,
  uploadPresigned,
  generatePdf,
} from '../repos/api';

import {
  getAuthCookie,
  setAuthCookie,
  removeAuthCookie,
} from '../repos/cookies';

export default class AppService {
  async authenticate(id: string): Promise<string> {
    const api = getApi();
    const response = await login(api, { id })
    setAuthCookie(response.data.token, response.data.expires)
    return response.data.token
  }

  async upload(token: string, file: any) {
    const api = getApi(token);

    const formData = new FormData();
    formData.append("file", file);

    const response = await upload(api, formData)
    return response;
  }

  async uploadDirect(token: string, file: any) {
    const api = getApi(token);

    const uploadPresignedParams = {
      fileName: file.name,
      mimeType: file.type,
      fileSize: file.size,
    }
    const response = await uploadPresigned(api, uploadPresignedParams)

    const formData = new FormData();
    Object.entries(response.data.fields).forEach(([k, v]) => {
      formData.append(k, v as string);
    });
    formData.append("file", file); // must be the last one
    const headers = {
      "accept": "multipart/form-data"
    }

    await fetch(
      response.data.url,
      {
        method: 'POST',
        headers,
        body: formData,
      })

    const url = response.data.urlBase + response.data.fields.key
    return {
      url,
      ...uploadPresignedParams
    };
  }

  async generatePdf(token: string, url: string) {
    const api = getApi(token);
    const response = await generatePdf(api, { url })
    return response;
  }

  async generateDocThumbnail(token: string, url: string) {
    const api = getApi(token);
    const response = await generatePdf(api, { url })
    return response;
  }

  unauthenticate(){
    removeAuthCookie();
  }

  getAuthToken() {
    return getAuthCookie();
  }
}
