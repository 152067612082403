import React, { useState, useEffect, } from "react";
import { useFormContext } from 'react-hook-form';
import { useHistory } from "react-router-dom";

import {
  DocumentModel,
} from '../../api';

import {
  DocumentForm
} from '../../schema'
import classNames from "classnames";
import Moment from "react-moment";

type Props = {
  children?: React.ReactNode;
  document?: DocumentModel;
}

const designTypeProp = 'designType'

const designTypes = [
  {
    key: 'simple',
    name: 'SIMPLE',
  },
  {
    key: 'dark',
    name: 'DARK',
  },
  {
    key: 'modern',
    name: 'MODERN',
  },
]

const HeaderNav = (props: Props) => {
  const history = useHistory();
  const [showMemo, setShowMemo] = useState(false)
  const [showDesignType, setShowDesignTYpe] = useState(false)

  const { register, unregister, watch, setValue, errors } = useFormContext<DocumentForm>()

  useEffect(() => {
    register(designTypeProp);

    return () => {
      unregister(designTypeProp);
    }
  }, [register, unregister])

  const designType = watch(designTypeProp)

  const memoPopoverClick = (e: React.MouseEvent) => {
    e.preventDefault()
    setShowMemo(!showMemo)
  }
  const closeMemoPopoverClick = (e: React.MouseEvent) => {
    e.preventDefault()
    setShowMemo(false)
  }
  const saveMemoClick = (e: React.MouseEvent) => {
    setShowMemo(false)
  }
  const finishClick = (e: React.MouseEvent) => {
    e.preventDefault()
    if (props.document) {
      history.push(`/documents/${props.document.id}`)
    }
  }
  const designTypeDropdownClick = (e: React.MouseEvent) => {
    e.preventDefault()
    setShowDesignTYpe(!showDesignType)
  }
  const designTypeSelectClick = (e: React.MouseEvent, key: string) => {
    e.preventDefault()
    setValue(designTypeProp, key)
    setShowDesignTYpe(false)
  }

  return (
    <div className="navbar-header navbar navbar-document bg-white only-wide">
      <nav className="navbar-nav">
        <button
          onClick={memoPopoverClick}
          className="nav-item btn-primary only-icon has-popover border-0">
          <i className="icon icon-edit"></i>
        </button>
        <div
          className={classNames("popover-nav-left popover fade bs-popover-bottom", {
            show: showMemo,
          })}
          role="tooltip"
          x-placement="bottom">
          <div className="arrow"></div>
          <h3 className="popover-header"> </h3>
          <div className="popover-body">
            <textarea
              ref={register}
              name="memo"
              className="form-control mb-3 input-memo" placeholder="メモ"></textarea>
            <div className="row row-narrow-gutter">
              <div className="col">
                <button
                  onClick={closeMemoPopoverClick}
                  className="btn btn-border btn-block">閉じる</button>
              </div>
              <div className="col">
                <button
                  type="submit"
                  onClick={saveMemoClick}
                  className="btn btn-primary btn-block">保存</button>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-document">
          <div className="doc-title mr-2-5">
            <input 
              ref={register}
              name="title"
              className={classNames("form-control form-control-md",
                { 'is-invalid': errors.title })}
              type="text"
              placeholder="資料タイトル" />
          </div>
          <div className="doc-date">
            <span className="text-muted">UPDATE:</span>
            <span className="d-inline-block ml-1">
              <Moment format="YYYY年MM月DD日">
                { props.document?.updatedAt}
              </Moment>
            </span>
          </div>
          <div className="ml-auto nav-doc-edit pl-2">
            <div className="dropdown">
              <button
                onClick={designTypeDropdownClick}
                className="btn btn-border dropdown-toggle"
                id="dropdownMenuDesign"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">デザイン変更</button>
              <div 
                className={classNames("dropdown-menu py-0", {
                  show: showDesignType,
                })}
              >
                {
                  designTypes.map(x => (
                    <a 
                      key={x.key}
                      className={classNames("dropdown-item", {
                        active: x.key === designType,
                      })}
                      onClick={e => designTypeSelectClick(e, x.key)}
                      href="!#">{ x.name }</a>
                  ))
                }
              </div>
            </div>
            <div className="btn-group-even row row-narrow-gutter">
              <div className="col">
                <button 
                  className="btn btn-border btn-block mr-1"
                  onClick={finishClick}
                >
                  編集終了
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-primary btn-block">保存</button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default HeaderNav;
