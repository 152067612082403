import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import times from 'lodash.times'
import classNames from 'classnames';

import { useAppContext } from '../../../contexts/app'
import FileUploadField from '../../common/FileUploadField'
import LoadingOverlay from "../../common/LoadingOverlay";
import AlertModal from "../../modals/AlertModal";
import {
  AchivementForm
} from '../../../schema'


const mainVisualProp = 'mainVisual'
const mainVisualThumbnailProp = 'mainVisualThumbnail'
const subVisualsProp = 'subVisuals'
const subVisualsNum = 3

const AchivementFormVisualSection = () => {
  const { uploadAttachment } = useAppContext()

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { register, unregister, watch, setValue, errors } = useFormContext<AchivementForm>()

  useEffect(() => {
    register({ name: mainVisualProp }, {});
    register({ name: mainVisualThumbnailProp }, {});
    times(subVisualsNum).map(index =>
      register({ name: `${subVisualsProp}[${index}]` }, {})
    )

    return () => {
      unregister(mainVisualProp);
      unregister(mainVisualThumbnailProp);
      times(subVisualsNum).map(index =>
        unregister(`${subVisualsProp}[${index}]`)
      )
    }
  }, [register, unregister])

  const uploadFileAndSetValue = async (prop: string, files: any) => {
    setLoading(true)
    setError(false)
    try {
      const response = await uploadAttachment(files[0])
      setValue(prop, response, true)
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const mainVisual = watch(mainVisualProp)
  const mainVisualThumbnail = watch(mainVisualThumbnailProp)
  const subVisuals = watch(subVisualsProp)

  const thumbnailDisabled = !mainVisual || mainVisual.mimeType.startsWith('image/')

  return (
    <>
      <LoadingOverlay active={loading} />
      {
        error && <AlertModal
          message="ネットワークに接続できませんでした。"
        />
      }
      <div className="form-section p-4">
        <div className="row row-wide-gutter">
          <div className="col-6">
            <div className="form-group">
              <label>メインビジュアル</label>

              <FileUploadField
                isLarge
                isInvalid={!!errors.mainVisual}
                attachment={mainVisual}
                options={{
                  accept: ['image/png', 'image/gif', 'image/jpeg', 'video/mp4']
                }}
                onDelete={() => {
                  setValue(mainVisualProp, null, true)
                }}
                onSelect={(files) => {
                  uploadFileAndSetValue(mainVisualProp, files)
                }} />
              {
                errors.mainVisual && 
                <div className="invalid-feedback">
                  ※メインビジュアルを選択してください。
                </div>
              }

              <p className="mt-3">画像、または動画をアップロードしてください。</p>
            </div>
          </div>
          <div className="col-6">
            <div className={classNames("form-group", {
              disabled: thumbnailDisabled,
            })}>
              <label>サムネイル画像</label>
              <FileUploadField
                isLarge
                isInvalid={!!errors.mainVisualThumbnail}
                attachment={mainVisualThumbnail}
                options={{
                  accept: ['image/png', 'image/gif', 'image/jpeg'],
                  disabled: thumbnailDisabled,
                }}
                onDelete={() => {
                  setValue(mainVisualThumbnailProp, null, true)
                }}
                onSelect={(files) => {
                  uploadFileAndSetValue(mainVisualThumbnailProp, files)
                }} />
              {
                errors.mainVisualThumbnail && 
                <div className="invalid-feedback">
                  ※サムネイル画像を選択してください。
                </div>
              }

              <p className="mt-3">
                動画のサムネイル画像をアップロードしてください。
                <br />
                ※メインビジュアルで動画をアップロードしたときのみ
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="form-section p-4">
        <div className="form-group">
          <label>サブビジュアル</label>
          <div className="row row-wide-gutter">
            {
              times(subVisualsNum).map(index =>
                <div key={index} className="col-4">
                  <FileUploadField
                    attachment={subVisuals ? subVisuals[index] : undefined}
                    options={{
                      accept: ['image/png', 'image/gif', 'image/jpeg'],
                    }}
                    onDelete={() => {
                      setValue(`${subVisualsProp}[${index}]`, undefined, true)
                    }}
                    onSelect={(files) => {
                      uploadFileAndSetValue(`${subVisualsProp}[${index}]`, files)
                    }} />
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default AchivementFormVisualSection;
