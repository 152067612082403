import React from 'react';

import icon_no_item from '../../assets/images/icon_no_item.svg';
import icon_no_item_doc from '../../assets/images/icon_no_item_doc.svg';

interface Props {
  message: string;
  type?: string;
}

const SectionNoItem = (props: Props) => {
  return (
    <section className="section-no-item has-nav-search">
      <figure className="icon-no-item">
        {
          props.type === 'document' ?
          <img src={icon_no_item_doc} alt="" />
          :
          <img src={icon_no_item} alt="" />
        }
        <figcaption className="text-secondary">
          { props.message }
        </figcaption>
      </figure>
    </section>
  )
}

export default SectionNoItem;