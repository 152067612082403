import React from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

import { AchivementModel } from '../../api';
import MediaElement from './MediaElement';

interface Props {
  viewIndex: number;
  achivement: AchivementModel;
}

const AchivementMainVisual = (props: Props) => {
  let content = <></>
  if (props.viewIndex === 0)  {
    if (props.achivement.mainVisual) {
      if (props.achivement.mainVisual.mimeType.includes("image")) {
        content = <img src={props.achivement.mainVisual.url} alt="" />
      } else {
        content = <MediaElement
          id="mainVisual"
          options={{}}
          tracks={{}}
          poster={props.achivement.mainVisualThumbnail?.url || ''}
          controls
          preload="none"
          sources={[ {src: props.achivement.mainVisual.url, type: props.achivement.mainVisual.mimeType}]}
        />
      }
    }
  } else {
    content = <img src={props.achivement.subVisuals[props.viewIndex-1].url} alt="" />
  }
  return (
    <ReactCSSTransitionReplace className="works-visual-main" transitionName="cross-fade"
        transitionEnterTimeout={700} transitionLeaveTimeout={700}>
      <figure className="works-visual-main-wrapper" key={props.viewIndex}>
        { content }
      </figure>
    </ReactCSSTransitionReplace>
  )
}

export default AchivementMainVisual;
