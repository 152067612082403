import React, { useRef } from 'react';
import classNames from 'classnames';

import EditableElement from "./EditableElement";
import { DocumentPageProps } from './DocumentPage'
import { AchivementModelUtil } from '../../utils/AchivementModel'

export const DocumentPageLayoutImage = (props: DocumentPageProps) => {
  // MARK: 実績ページなのでbaseAchivementあり
  const img = AchivementModelUtil.mainVisualImgThumbnail(props.documentPage.baseAchivement!)
  const imgUrl = img!.url
  const hasLinkUrl = props.documentPage.baseAchivement!.linkUrls.length > 0
  const showLinkUrlArea = hasLinkUrl && ( props.documentPage.linkUrl || props.editable )

  const keyVisualHeight = showLinkUrlArea ? 580 : 680

  return <>
    <div className="doc-works-body">
      <div className="design-simple-header">
        <div className="design-simple-header-left">
          <EditableElement
            tagName="h1"
            disabled={!props.editable}
            className={classNames("doc-works-title", { editable: props.editable })}
            value={props.documentPage.title}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('title', v)
              }
            }}
          />
        </div>
        <div className="design-simple-header-right">
          <dl className="doc-works-info">
            <EditableElement
              tagName="dt"
              disabled={!props.editable}
              className={classNames({ editable: props.editable })}
              value={props.documentPage.startedMonth}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange('startedMonth', v)
                }
              }}
            />
            <dd>
              <EditableElement
                tagName="p"
                disabled={!props.editable}
                className={classNames({ editable: props.editable })}
                value={props.documentPage.clientName}
                onChange={(v) => {
                  if (props.onChange) {
                    props.onChange('clientName', v)
                  }
                }}
              />
              <EditableElement
                tagName="p"
                disabled={!props.editable}
                className={classNames({ editable: props.editable })}
                value={props.documentPage.productName}
                onChange={(v) => {
                  if (props.onChange) {
                    props.onChange('productName', v)
                  }
                }}
              />
            </dd>
          </dl>
        </div>
      </div>
      <figure className="design-simple-keyvisual visual" style={{
        height: keyVisualHeight,
      }}>
        <img src={imgUrl} alt="" />
      </figure>
      <div className="design-simple-body">
        <EditableElement
          tagName="h2"
          disabled={!props.editable}
          className={classNames('doc-works-catch', { editable: props.editable })}
          value={props.documentPage.catchphrase}
          onChange={(v) => {
            if (props.onChange) {
              props.onChange('catchphrase', v)
            }
          }}
        />
        {
          showLinkUrlArea && 
          <EditableElement
            tagName="p"
            disabled={!props.editable}
            className={classNames('doc-works-URL', { editable: props.editable })}
            value={props.documentPage.linkUrl}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('linkUrl', v)
              }
            }}
          />
        }
      </div>
    </div>
    <div className="nombre">{ props.index + 1 }</div>
  </>
}

export const DocumentPageLayoutImageDesignModern = (props: DocumentPageProps) => {
  const img = AchivementModelUtil.mainVisualImgThumbnail(props.documentPage.baseAchivement!)
  const imgUrl = img!.url

  const hasLinkUrl = props.documentPage.baseAchivement!.linkUrls.length > 0
  const showLinkUrlArea = hasLinkUrl && ( props.documentPage.linkUrl || props.editable )

  const titleScrollTargetRef = useRef(null)
  const catchphraseScrollTargetRef = useRef(null)
  const startedMonthScrollTargetRef = useRef(null)
  const clientNameScrollTargetRef = useRef(null)
  const linkUrlScrollTargetRef = useRef(null)

  return <>
    <div className="doc-works-body">
      <figure className="visual">
        <img src={imgUrl} alt="" />
      </figure>
      <div className="design-modern-body is-top">
        <div className="doc-works-catch" ref={titleScrollTargetRef}>
          <EditableElement
            tagName="div"
            disabled={!props.editable}
            className={classNames('design-modern-headding', { editable: props.editable })}
            value={props.documentPage.catchphrase}
            scrollTargetRef={titleScrollTargetRef}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('catchphrase', v)
              }
            }}
          />
        </div>
      </div>
      <div className="design-modern-body is-bottom">
        <div className="doc-works-title" ref={catchphraseScrollTargetRef}>
          <EditableElement
            tagName="div"
            disabled={!props.editable}
            className={classNames('design-modern-headding', { editable: props.editable })}
            value={props.documentPage.title}
            scrollTargetRef={catchphraseScrollTargetRef}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('title', v)
              }
            }}
          />
        </div>
        <dl className="doc-works-dl is-inline" ref={startedMonthScrollTargetRef}>
          <dt>TIME: </dt>
          <EditableElement
            tagName="dd"
            disabled={!props.editable}
            className={classNames('', { editable: props.editable })}
            value={props.documentPage.startedMonth}
            scrollTargetRef={startedMonthScrollTargetRef}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('startedMonth', v)
              }
            }}
          />
        </dl>
        <dl className="doc-works-dl is-inline" ref={clientNameScrollTargetRef}>
          <dt>CLIENT: </dt>
          <EditableElement
            tagName="dd"
            disabled={!props.editable}
            className={classNames('', { editable: props.editable })}
            value={props.documentPage.clientName}
            scrollTargetRef={clientNameScrollTargetRef}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('clientName', v)
              }
            }}
          />
        </dl>
        {
          showLinkUrlArea && 
          <dl className="doc-works-dl is-inline" ref={linkUrlScrollTargetRef}>
            <dt>URL: </dt>
            <EditableElement
              tagName="dd"
              disabled={!props.editable}
              className={classNames('', { editable: props.editable })}
              value={props.documentPage.linkUrl}
              scrollTargetRef={linkUrlScrollTargetRef}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange('linkUrl', v)
                }
              }}
            />
          </dl>
        }
      </div>
    </div>
    <div className="nombre">{ props.index + 1 }</div>
  </>
}