import React from 'react';
import classNames from 'classnames';

import { AchivementModelUtil } from '../../utils/AchivementModel'
import EditableElement from "../documents/EditableElement";
import { DocumentPageProps } from './DocumentPage'

export const DocumentPageLayoutDetailSecond = (props: DocumentPageProps) => {
  return <>
    <div className="doc-works-body">
      <div className="design-simple-header">
        <div className="design-simple-header-left">
          <EditableElement
            tagName="h1"
            disabled={!props.editable}
            className={classNames("doc-works-title", { editable: props.editable })}
            value={props.documentPage.title}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('title', v)
              }
            }}
          />
        </div>
        <div className="design-simple-header-right">
          <dl className="doc-works-info">
            <EditableElement
              tagName="dt"
              disabled={!props.editable}
              className={classNames({ editable: props.editable })}
              value={props.documentPage.startedMonth}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange('startedMonth', v)
                }
              }}
            />
            <dd>
              <EditableElement
                tagName="p"
                disabled={!props.editable}
                className={classNames({ editable: props.editable })}
                value={props.documentPage.clientName}
                onChange={(v) => {
                  if (props.onChange) {
                    props.onChange('clientName', v)
                  }
                }}
              />
              <EditableElement
                tagName="p"
                disabled={!props.editable}
                className={classNames({ editable: props.editable })}
                value={props.documentPage.productName}
                onChange={(v) => {
                  if (props.onChange) {
                    props.onChange('productName', v)
                  }
                }}
              />
            </dd>
          </dl>
        </div>
      </div>
      <div className="design-simple-body">
        <dl className="doc-works-dl"><dt>OVERVIEW</dt>
          <EditableElement
            tagName="dd"
            disabled={!props.editable}
            className={classNames({ editable: props.editable })}
            value={props.documentPage.overviewSection}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('overviewSection', v)
              }
            }}
          />
        </dl>
        <dl className="doc-works-dl"><dt>POINT</dt>
          <EditableElement
            tagName="dd"
            disabled={!props.editable}
            className={classNames({ editable: props.editable })}
            value={props.documentPage.pointSection}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('pointSection', v)
              }
            }}
          />
        </dl>
        <dl className="design-simple-result">
          <dt><i className="icon icon-result"></i><span className="text">RESULT</span></dt>
          <dd>
            <EditableElement
              tagName="div"
              disabled={!props.editable}
              className={classNames('dd-body mb-0', { editable: props.editable })}
              value={props.documentPage.resultSection}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange('resultSection', v)
                }
              }}
            />
          </dd>
        </dl>
      </div>
    </div>
    <div className="nombre">{ props.index + 1 }</div>
  </>
}

export const DocumentPageLayoutDetailSecondDesignModern = (props: DocumentPageProps) => {
  const achivement = props.documentPage.baseAchivement!
  const img = AchivementModelUtil.mainVisualImgThumbnail(achivement)
  const imgUrl = img!.url
  const subImgUrl = achivement.subVisuals.length > 1 ? achivement.subVisuals[1].url : null

  return <>
    <div className="doc-works-body">
      <figure className="visual">
        <img src={subImgUrl || imgUrl} alt="" />
        <div className="design-simple-header-right"></div>
      </figure>
      <div className="design-modern-body">
        <dl className="doc-works-dl">
          <dt>BACKGROUND</dt>
          <EditableElement
            tagName="dd"
            disabled={!props.editable}
            className={classNames({ editable: props.editable })}
            value={props.documentPage.backgroundSection}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('backgroundSection', v)
              }
            }}
          />
        </dl>
        <dl className="doc-works-dl">
          <dt>POINT</dt>
          <EditableElement
            tagName="dd"
            disabled={!props.editable}
            className={classNames({ editable: props.editable })}
            value={props.documentPage.pointSection}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('pointSection', v)
              }
            }}
          />
        </dl>
        <dl className="doc-works-dl">
          <dt>RESULT</dt>
          <EditableElement
            tagName="dd"
            disabled={!props.editable}
            className={classNames('dd-body mb-0', { editable: props.editable })}
            value={props.documentPage.resultSection}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('resultSection', v)
              }
            }}
          />
        </dl>
      </div>
    </div>
    <div className="nombre">{ props.index + 1 }</div>
  </>
}