/* eslint-disable react-pug/no-interpolation */
import React from 'react';
// import logo from '../../../assets/images/logo.svg';
import icon_folder from '../../../assets/images/icon_folder.svg';
import icon_add_line from '../../../assets/images/icon_add_line.svg';

import {
  NavLink
} from "react-router-dom";

const HeaderNavAdmin = (props) => {
  return pug`
    .navbar-header.navbar.p-0.navbar-admin.bg-white.only-wide
      nav.navbar-nav
        NavLink.nav-item(to="/admin" activeClassName="_active")
          | 管理画面

        NavLink.nav-item(to=props.isCodingPage ? "/coding/admin/edit" : "/admin" exact)
          i.icon
            img(src=icon_folder)

          | 実績一覧

        NavLink.nav-item(to=props.isCodingPage ? "/coding/admin/edit" : "/admin/achivements/new" exact)
          i.icon
            img(src=icon_add_line)

          | 新規登録

        .nav-item.ml-auto
          a.btn.btn-border(href="#" target="_blank")
            | TA KnowledgeSharing

            i.icon.icon-outlink
  `
}

export default HeaderNavAdmin;
