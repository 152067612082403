import React from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import {
  AchivementForm
} from '../../../schema'

const AchivementFormTitleSection = () => {
  const { register, errors } = useFormContext<AchivementForm>()

  return (
    <>
      <div className="form-section p-4">
        <div className="form-group">
          <label>キャッチコピー</label> 

          <input
            ref={register}
            name="catchphrase"
            type="text"
            className={classNames("form-control", { 'is-invalid': errors.catchphrase, })}
          />
          {
            errors.catchphrase &&
            <div className="invalid-feedback">
              ※キャッチコピーを入力してください。
            </div>
          }
        </div>
      </div>
      <div className="form-section p-4">
        <div className="form-group">
          <label>タイトル</label> 

          <input
            ref={register}
            name="title"
            type="text"
            className={classNames("form-control", {
              'is-invalid': errors.title,
            })}
          />
          {
            errors.title &&
            <div className="invalid-feedback">
              ※タイトルを入力してください。
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default AchivementFormTitleSection;
