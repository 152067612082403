import React from "react";

import HeaderNav from "../headers/HeaderNav";
import Sidebar from "../common/Sidebar";

type Props = {
  children?: React.ReactNode;
  pageTitle: string;
  totalCount?: number;
}

const ListPageLayout: React.FC<Props> = ({ children, totalCount, pageTitle }) => {
  return (
    <>
      <Sidebar />
      <main className="main-content">
        <div className="main-container">
          <HeaderNav>
            <ul className="navbar-nav nav-search-result">
              <li className="nav-item">
                {
                  pageTitle
                }
                {
                  totalCount !== undefined && 
                  <>
                    <span className="lead d-inlin-block mx-2 mb-1">
                      { totalCount }
                    </span>
                    &nbsp;件
                  </>
                }
              </li>
            </ul>
          </HeaderNav>
          { children }
        </div>
      </main>
    </>
  );
}

export default ListPageLayout;
