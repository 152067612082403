import React from "react";
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';
import { getMainDefinition } from 'apollo-utilities';

import { onError } from "apollo-link-error";

import { useAppContext } from './contexts/app'

const omitDeep = require("omit-deep-lodash");

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const isDevelopment = process.env.NODE_ENV === "development"

export const GraphqlProvider: React.FC = ({ children }) => {
  const { authToken, logout } = useAppContext()

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : "",
      }
    }
  });

  const cleanTypenameLink = new ApolloLink((operation, forward) => {
    // MARK: fix for this issue.
    // https://github.com/apollographql/apollo-feature-requests/issues/6
    const keysToOmit = ['__typename']

    const def = getMainDefinition(operation.query)
    if (def && def.kind === 'OperationDefinition' && def.operation === 'mutation') {
      operation.variables = omitDeep(operation.variables, keysToOmit)
    }
    return forward ? forward(operation) : null
  })

  const errorLink = onError(({ response, graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);

    const authError = graphQLErrors && graphQLErrors.find(({ message }) => message === 'Unauthorized')
    if (authError) {
      // MARK: 認証エラーが発生したらログアウトしてエラー表示しない
      logout()
      if (response) {
        response.errors = undefined;
      }
    }

  });

  const client = new ApolloClient({
    connectToDevTools: isDevelopment,
    link: ApolloLink.from([
      authLink,
      errorLink,
      cleanTypenameLink,
      httpLink
    ]),
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
}
