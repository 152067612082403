import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { RouteProps, useLocation } from "react-router-dom";
import queryString from 'query-string'

import { useAppContext } from '../../contexts/app'

export const getGenPdfUrl = (path: string, authToken: string) => {
  return `${window.location.origin}#${path}?token=${authToken}`
}

const getAuthToken = (search: string) => {
  const queryParams = queryString.parse(search);
  if (queryParams.token instanceof Array) {
    return queryParams.token[0]
  } else {
    return queryParams.token
  }
}

type Props = RouteProps

const GenPdfRoute: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const { authToken, setAuthToken } = useAppContext()
  const token = getAuthToken(location.search)

  useEffect(() => {
    if (token) {
      setAuthToken(token)
    }
  }, [token, setAuthToken])

  if (!authToken) {
    return <></>
  }

  return <Route {...props} />
}

export default GenPdfRoute;
