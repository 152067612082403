import axios, { AxiosResponse, AxiosInstance } from 'axios';
import { AttachmentInput } from '../api';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getApi = (jwtToken?: string | null) => {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    }
  })
}

// login
export type loginParams = {
  id: string;
}
export type loginResponseData = {
  token: string;
  expires?: number;
}
export type loginResponse = AxiosResponse<loginResponseData>
export const login = async (
  api: AxiosInstance, params: loginParams): Promise<loginResponse> => {
  const url = '/login'
  return await api.post(url, params)
}

// upload
export type uploadResponse = AxiosResponse<AttachmentInput>
export const upload = async (
  api: AxiosInstance, params: FormData): Promise<uploadResponse> => {
  const url = '/upload'
  return await api.post(url, params)
}

// upload_presigned
export type uploadPresignedResponseData = {
  url: string;
  urlBase: string;
  fields: any;
}
export type uploadPresignedResponse = AxiosResponse<uploadPresignedResponseData>
export const uploadPresigned = async (
  api: AxiosInstance, params: Omit<AttachmentInput, 'url'>): Promise<uploadPresignedResponse> => {
  const url = '/upload_presigned'
  return await api.post(url, params)
}

// generate pdf
export type generatePdfParams = {
  url: string;
}
export type generatePdfResponseData = {
  url: string;
}
export type generatePdfResponse = AxiosResponse<generatePdfResponseData>
export const generatePdf = async (
  api: AxiosInstance,
  params: generatePdfParams): Promise<generatePdfResponse> => {
  const url = '/pdf'
  return await api.post(url, params)
}

// generate doc thumbnail
export type generateDocThumbnailParams = {
  url: string;
}
export type generateDocThumbnailResponseData = {
  url: string;
}
export type generateDocThumbnailResponse = AxiosResponse<generateDocThumbnailResponseData>
export const generateDocThumbnail = async (
  api: AxiosInstance,
  params: generateDocThumbnailParams): Promise<generateDocThumbnailResponse> => {
  const url = '/doc_thumbnail'
  return await api.post(url, params)
}
