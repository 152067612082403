import React, { useRef } from 'react';
import classNames from 'classnames';

import { DocumentPageProps } from './DocumentPage'
import EditableElement from "./EditableElement";
import { AchivementModelUtil } from '../../utils/AchivementModel'

export const DocumentPageLayoutDetailFirst = (props: DocumentPageProps) => {
  const achivement = props.documentPage.baseAchivement!
  const img = AchivementModelUtil.mainVisualImgThumbnail(achivement)
  const imgUrl = img!.url
  const subImgUrl = achivement.subVisuals.length > 0 ? achivement.subVisuals[0].url : null
  const hasLinkUrl = props.documentPage.baseAchivement!.linkUrls.length > 0

  return <>
    <div className="doc-works-body">
      <div className="design-simple-header">
        <div className="design-simple-header-left">
          <EditableElement
            tagName="h1"
            disabled={!props.editable}
            className={classNames("doc-works-title", { editable: props.editable })}
            value={props.documentPage.title}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('title', v)
              }
            }}
          />
        </div>
        <div className="design-simple-header-right">
          <dl className="doc-works-info">
            <EditableElement
              tagName="dt"
              disabled={!props.editable}
              className={classNames({ editable: props.editable })}
              value={props.documentPage.startedMonth}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange('startedMonth', v)
                }
              }}
            />
            <dd>
              <EditableElement
                tagName="p"
                disabled={!props.editable}
                className={classNames({ editable: props.editable })}
                value={props.documentPage.clientName}
                onChange={(v) => {
                  if (props.onChange) {
                    props.onChange('clientName', v)
                  }
                }}
              />
              <EditableElement
                tagName="p"
                disabled={!props.editable}
                className={classNames({ editable: props.editable })}
                value={props.documentPage.productName}
                onChange={(v) => {
                  if (props.onChange) {
                    props.onChange('productName', v)
                  }
                }}
              />
            </dd>
          </dl>
        </div>
      </div>
      <div className="design-simple-body">
        <div className="design-simple-visual">
          <figure className="visual">
            <img src={imgUrl} alt="" />
          </figure>
          <figure className="visual">
            {
              subImgUrl && 
              <img src={subImgUrl} alt="" />
            }
          </figure>
        </div>
        <div className="d-flex justify-content-between">
          <div className="layout-detail-1-left design-simple-col">
            <EditableElement
              tagName="h2"
              disabled={!props.editable}
              className={classNames('doc-works-catch', { editable: props.editable })}
              value={props.documentPage.catchphrase}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange('catchphrase', v)
                }
              }}
            />
            <div>
              {
                hasLinkUrl && ( props.documentPage.linkUrl || props.editable ) && 
                <dl className="doc-works-dl"><dt>URL</dt>
                  <EditableElement
                    tagName="dd"
                    disabled={!props.editable}
                    className={classNames({ editable: props.editable })}
                    value={props.documentPage.linkUrl}
                    onChange={(v) => {
                      if (props.onChange) {
                        props.onChange('linkUrl', v)
                      }
                    }}
                  />
                </dl>
              }
              <dl className="doc-works-dl"><dt>TARGET</dt>
                <EditableElement
                  tagName="dd"
                  disabled={!props.editable}
                  className={classNames({ editable: props.editable })}
                  value={props.documentPage.targetSection}
                  onChange={(v) => {
                    if (props.onChange) {
                      props.onChange('targetSection', v)
                    }
                  }}
                />
              </dl>
            </div>
          </div>
          <dl className="layout-detail-1-right doc-works-dl"><dt>OVERVIEW</dt>
            <EditableElement
              tagName="dd"
              disabled={!props.editable}
              className={classNames({ editable: props.editable })}
              value={props.documentPage.overviewSection}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange('overviewSection', v)
                }
              }}
            />
          </dl>
        </div>
      </div>
    </div>
    <div className="nombre">{ props.index + 1 }</div>
  </>
}

export const DocumentPageLayoutDetailFirstDesignModern = (props: DocumentPageProps) => {
  // MARK: モダンデザイン用
  const achivement = props.documentPage.baseAchivement!
  const img = AchivementModelUtil.mainVisualImgThumbnail(achivement)
  const imgUrl = img!.url
  const subImgUrl = achivement.subVisuals.length > 0 ? achivement.subVisuals[0].url : null
  const hasLinkUrl = props.documentPage.baseAchivement!.linkUrls.length > 0

  const titleScrollTargetRef = useRef(null)
  const catchphraseScrollTargetRef = useRef(null)
  const clientNameScrollTargetRef = useRef(null)
  const productNameScrollTargetRef = useRef(null)
  const targetSectionScrollTargetRef = useRef(null)
  const startedMonthScrollTargetRef = useRef(null)
  const linkUrlScrollTargetRef = useRef(null)

  return <>
    <div className="doc-works-body">
      <div className="modern-detail-glid">
        <figure className="design-modern-keyvisual visual">
          <img src={imgUrl} alt="" />
        </figure>
        <div className="layout-detail-1-right">
          <div className="doc-works-catch" ref={titleScrollTargetRef}>
            <EditableElement
              tagName="div"
              disabled={!props.editable}
              className={classNames('design-modern-headding', { editable: props.editable })}
              value={props.documentPage.catchphrase}
              scrollTargetRef={catchphraseScrollTargetRef}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange('catchphrase', v)
                }
              }}
            />
          </div>
          <div className="doc-works-title" ref={catchphraseScrollTargetRef}>
            <EditableElement
              tagName="div"
              disabled={!props.editable}
              className={classNames("design-modern-headding", { editable: props.editable })}
              value={props.documentPage.title}
              scrollTargetRef={titleScrollTargetRef}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange('title', v)
                }
              }}
            />
          </div>
          <dl className="doc-works-dl is-inline" ref={clientNameScrollTargetRef}>
            <dt>CLIENT: </dt>
            <EditableElement
              tagName="dd"
              disabled={!props.editable}
              className={classNames({ editable: props.editable })}
              value={props.documentPage.clientName}
              scrollTargetRef={clientNameScrollTargetRef}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange('clientName', v)
                }
              }}
            />
          </dl>
          <dl className="doc-works-dl is-inline" ref={productNameScrollTargetRef}>
            <dt>ITEM: </dt>
            <EditableElement
              tagName="dd"
              disabled={!props.editable}
              className={classNames({ editable: props.editable })}
              value={props.documentPage.productName}
              scrollTargetRef={productNameScrollTargetRef}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange('productName', v)
                }
              }}
            />

          </dl>
          <dl className="doc-works-dl is-inline" ref={targetSectionScrollTargetRef}>
            <dt>TARGET: </dt>
            <EditableElement
              tagName="dd"
              disabled={!props.editable}
              className={classNames({ editable: props.editable })}
              value={props.documentPage.targetSection}
              scrollTargetRef={targetSectionScrollTargetRef}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange('targetSection', v)
                }
              }}
            />
          </dl>
          <dl className="doc-works-dl is-inline" ref={startedMonthScrollTargetRef}>
            <dt>TIME: </dt>
            <EditableElement
              tagName="dd"
              disabled={!props.editable}
              className={classNames({ editable: props.editable })}
              value={props.documentPage.startedMonth}
              scrollTargetRef={startedMonthScrollTargetRef}
              onChange={(v) => {
                if (props.onChange) {
                  props.onChange('startedMonth', v)
                }
              }}
            />
          </dl>
          {
            hasLinkUrl && ( props.documentPage.linkUrl || props.editable ) && 
            <dl className="doc-works-dl is-inline" ref={linkUrlScrollTargetRef}>
              <dt>URL: </dt>
              <EditableElement
                tagName="dd"
                disabled={!props.editable}
                className={classNames({ editable: props.editable })}
                value={props.documentPage.linkUrl}
                scrollTargetRef={linkUrlScrollTargetRef}
                onChange={(v) => {
                  if (props.onChange) {
                    props.onChange('linkUrl', v)
                  }
                }}
              />
            </dl>
          }
        </div>
        <dl className="layout-detail-1-left doc-works-dl">
          <dt>OVERVIEW</dt>
          <EditableElement
            tagName="dd"
            disabled={!props.editable}
            className={classNames({ editable: props.editable })}
            value={props.documentPage.overviewSection}
            onChange={(v) => {
              if (props.onChange) {
                props.onChange('overviewSection', v)
              }
            }}
          />
        </dl>
        <figure className="design-modern-sub-visual visual">
          {
            subImgUrl && 
            <img src={subImgUrl} alt="" />
          }
        </figure>
      </div>
    </div>
    <div className="nombre">{ props.index + 1 }</div>
  </>
}