import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useParams, Prompt } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form'
import classNames from "classnames";
import { useDebouncedCallback } from 'use-debounce';
import { useWindowResize, } from 'beautiful-react-hooks'; 

import Layout from "../layouts/DocumentEditLayout";
import LoadingOverlay from "../common/LoadingOverlay";
import AlertModal from "../modals/AlertModal";
import ConfirmModal  from '../modals/ConfirmModal'
// import DocumentPageThumbnail from "../documents/DocumentPageThumbnail";
import FavoriteAchivementsModal  from '../modals/FavoriteAchivementsModal'
import SearchAchivementsModal  from '../modals/SearchAchivementsModal'
import SelectItemModal  from '../modals/SelectItemModal'
import SelectAchivementLayoutModal from '../modals/SelectAchivementLayoutModal'
import DocumentPage, { DocumentPageEditableProp } from "../documents/DocumentPage";

import {
  AchivementModel,
  DocumentInput,
  useDocumentByIdQuery,
  useUpdateDocumentMutation,
} from '../../api';

import {
  DocumentSchema,
  DocumentForm,
} from '../../schema';

import { DocumentModelUtil } from '../../utils/DocumentModel'

import icon_document_blank from '../../assets/images/icon_document_blank.svg'
import icon_layout from '../../assets/images/icon_layout.svg';

const pagesProp = 'pages';
const designTypeProp = 'designType'

const normalizeData = (data: DocumentInput): DocumentInput => {
  return {
    ...data,
    pages: (data.pages || []).map(x => ({
      ...x,
      baseAchivement: x.baseAchivement || undefined,
    })),
  }
}

const DocumentEditPage = () => {
  const displayMainRef = useRef<HTMLDivElement>(null)
  const [displayMainWidth, setDisplayMainWidth] = useState(0)

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)

  const { id } = useParams();

  const { data, loading, } = useDocumentByIdQuery({
    variables: { id: id! }
  });

  const [updateMutation, updateMutationState] = useUpdateDocumentMutation();

  const [showSelectAddMethodModal, setShowSelectAddMethodModal] = useState(false);
  const [showFavoriteAchivementsModal, setShowFavoriteAchivementsModal] = useState(false);
  const [showSearchAchivementsModal, setShowSearchAchivementsModal] = useState(false);
  const [showSelectLayoutModal, setShowSelectLayoutModal] = useState(false);
  const [selectedPageIndex, setSelectedPageIndex] = useState<number | null>(null);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [targetPageIndex, setTargetPageIndex] = useState<number | null>(null);

  const pagesDomRef = useRef<(HTMLDivElement | null)[]>([]);

  const formMethods = useForm<DocumentForm>({
    validationSchema: DocumentSchema,
  })
  const { register, unregister, handleSubmit, reset, formState, watch, setValue } = formMethods

  const pages = watch(pagesProp)
  const designType = watch(designTypeProp)

  const onSubmit = async (formData: DocumentForm) => {
    const input = normalizeData(formData as DocumentInput)
    const response = await updateMutation({
      variables: { id: id!, input },
    })

    if (response.data) {
      reset(response.data.updateDocument)
    }
  }

  const addNewPage = (achivement: AchivementModel) => {
    if (!pages || targetPageIndex === null) return

    const newPages = DocumentModelUtil.addNewPage(pages, targetPageIndex, achivement)
    if (!newPages) return

    setValue(pagesProp, newPages)

    // MARK: 追加ページを選択する
    setSelectedPageIndex(targetPageIndex)
  }

  const addProfilePages = () => {
    if (!pages || targetPageIndex === null) return

    const newPages = DocumentModelUtil.addNewProfilePages(pages, targetPageIndex)
    if (!newPages) return

    setValue(pagesProp, newPages)

    // MARK: 追加ページを選択する
    setSelectedPageIndex(targetPageIndex)
  }

  const changeLauout = (index: number, value: string) => {
    if (!pages) return

    const newPages = DocumentModelUtil.changePageLayout(pages, index, value)
    if (!newPages) return

    setValue(pagesProp, newPages)
  }

  const updatePage = (index: number, prop: DocumentPageEditableProp, value: string) => {
    if (!pages) return

    const newPages = DocumentModelUtil.updatePage(pages, index, prop, value)
    setValue(pagesProp, newPages)
  }

  // MARK: サムネイル生成処理が重いのでpagesの更新を間引く
  const [debouncedUpdatePage] = useDebouncedCallback<[number, DocumentPageEditableProp, string]>(
    (index, prop, value) => {
      updatePage(index, prop, value)
    },
    500
  );

  const deletePage = (index: number) => {
    if (!pages) return

    const newPages = DocumentModelUtil.deletePage(pages, index)

    if (!newPages) return

    // MARK: 削除時にページの選択を更新
    if (selectedPageIndex) {
      if (index <= selectedPageIndex) {
        if (newPages.length <= 0) {
          setSelectedPageIndex(null)
        } else if (selectedPageIndex >= newPages.length) {
          setSelectedPageIndex(newPages.length - 1)
        }
      }
    }

    setValue(pagesProp, newPages)
  }

  useWindowResize((evt: any) => {
    setScreenWidth(evt.target.innerWidth)
    setScreenHeight(evt.target.innerHeight)
    if( displayMainRef.current) {
      setDisplayMainWidth(displayMainRef.current.clientWidth)
    }
  });

  useLayoutEffect(() => {
    if( displayMainRef.current) {
      setDisplayMainWidth(displayMainRef.current.clientWidth)
    }
  }, [displayMainRef, data])

  useEffect(() => {
    register(pagesProp);
    return () => {
      unregister(pagesProp);
    }
  }, [register, unregister])

  useEffect(() => {
    if (data && selectedPageIndex === null) {
      reset(data.documentById)
      if (data.documentById.pages.length > 0) {
        setSelectedPageIndex(0)
      }
    }
  }, [reset, data, selectedPageIndex])

  // MARK: 資料編集画面で資料新規作成するとここでerrorになるので一旦外し
  // if (data && data.documentById.id !== id) {
  //   return <Redirect to="/" />
  // }
  // if (!loading && error) {
  //   return <Redirect to="/" />
  // }

  const selectedPage = pages && selectedPageIndex !== null && pages[selectedPageIndex]

  let scale = 1
  if (screenWidth <= 1366) {
    // MARK: スクリーンサイズ1366px未満の場合は806pxで固定
    scale = 806 / 1366;
  } else {
    // MARK: ページ表領域以外の部分の縦方向の長さ
    const verticalMargin = 60 + 48 + 48
    const pageWidth = screenWidth - 1366 + 806
    scale = pageWidth / 1366;
    const pageHeight = 966 * scale
    // MARK: 表示可能な高さを超える場合はscreenHeightでscaleを決める
    if(pageHeight > screenHeight - verticalMargin) {
      scale = ( screenHeight - verticalMargin ) / 966
    }
  }

  const marginBottom = 966 * ( scale - 1 )
  // const sidebarWidth = 88
  // const mainContentPadding = 36
  // const navWidth = 166
  // let displayMainWidth = screenWidth - sidebarWidth - navWidth - mainContentPadding * 2
  // if (displayMainWidth > 1243) {
  //   displayMainWidth = 1243
  // }
  const layoutButtonRight = ( displayMainWidth - 1366 * scale ) / 2

  const thumbnailScale = 112 / 1336

  if (loading) {
    return (
      <FormContext {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Layout></Layout>
        </form>
      </FormContext>
    )
  }

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout document={data!.documentById}>
          <ConfirmModal 
            title="ページを削除しますか？"
            okLabel="削除"
            isOpen={showDeleteModal}
            handleCloseModal={() => setShowDeleteModal(false)}
            handleSubmit={() => {
              if (targetPageIndex !== null) {
                deletePage(targetPageIndex)
              }
              setShowDeleteModal(false)
            } }
          />

          <SelectItemModal
            isOpen={showSelectAddMethodModal}
            items={[
              {
                key: 'favorite',
                name: 'お気に入りから実績を追加'
              },
              {
                key: 'search',
                name: '実績を検索して追加'
              },
              {
                key: 'profile',
                name: '会社案内を追加'
              }
            ]}
            handleCloseModal={() => {
              setShowSelectAddMethodModal(false)
            }}
            handleSubmit={(item) => {
              setShowSelectAddMethodModal(false)
              if (item.key === 'favorite') {
                setShowFavoriteAchivementsModal(true)
              } else if (item.key === 'profile') {
                addProfilePages()
              } else {
                setShowSearchAchivementsModal(true)
              }
            }}
          />
          <FavoriteAchivementsModal
            isOpen={showFavoriteAchivementsModal}
            handleCloseModal={() => {
              setShowFavoriteAchivementsModal(false)
            }}
            handleSubmit={achivement => {
              setShowFavoriteAchivementsModal(false)
              addNewPage(achivement)
            }}
          />
          <SearchAchivementsModal
            isOpen={showSearchAchivementsModal}
            handleCloseModal={() => {
              setShowSearchAchivementsModal(false)
            }}
            handleSubmit={achivement => {
              setShowSearchAchivementsModal(false)
              addNewPage(achivement)
            }}
          />
          <SelectAchivementLayoutModal
            isOpen={showSelectLayoutModal}
            defaultLayout={selectedPage ? selectedPage.layoutType : null}
            handleCloseModal={() => {
              setShowSelectLayoutModal(false)
            }}
            handleSubmit={layout => {
              setShowSelectLayoutModal(false)
              if (selectedPageIndex !== null) {
                changeLauout(selectedPageIndex, layout) 
              }
            }}
          />

          <Prompt
            when={formState.dirty}
            message={location => '資料はまだ保存されていません。\nよろしいですか？'}
          />
          <LoadingOverlay active={updateMutationState.loading} />
          {
            updateMutationState.called && !updateMutationState.loading && (
              updateMutationState.error ? 
                <AlertModal
                  message="ネットワークに接続できませんでした。データを保存するには再度「保存」ボタンを押してください。"
                  visibleSec={5}
                />:
                <AlertModal
                  message="データを保存しました。"
                  visibleSec={3}
                />
            )
          }
          <section className="section section-document-edit">
            <div className="document-display align-items-start justify-content-between">
              {
                selectedPage && selectedPage.layoutPage === 1 && selectedPage.type !== 'profile' &&
                <a
                  style={{
                    right: -15 + layoutButtonRight
                  }}
                  className="icon badge badge-primary"
                  href="#!"
                  onClick={e => {
                    e.preventDefault()
                    setShowSelectLayoutModal(true)
                  }}
                >
                  <img src={icon_layout} alt="" />
                </a>
              }
              <div ref={displayMainRef} className="document-display-main">
                <div className="document-scale-wrapper">
                  <div
                    className={classNames(
                      "document-display-wrapper",
                      {
                        'design-simple': designType === 'simple' || designType === 'dark',
                        'design-simple-light': designType === 'simple',
                        'design-simple-dark': designType === 'dark',
                        'design-modern': designType === 'modern',
                      }
                    )}
                    style={{
                      transform: `scale(${scale})`,
                      marginBottom: marginBottom,
                    }}>
                    {
                      pages && pages.length === 0 &&
                      <div className="doc-works-slide">
                        <div className="doc-works-empty d-flex align-items-center justify-content-center h-100">
                          <a className="d-block text-center" href="#!" onClick={e => {
                            e.preventDefault()
                            setTargetPageIndex(pages && pages.length)
                            setShowSelectAddMethodModal(true)
                          }}>
                            <i className="icon">
                              <img src={icon_document_blank} alt="" />
                            </i>
                            <h4 className="mt-3 font-weight-normal">実績を追加</h4>
                          </a>
                        </div>
                      </div>
                    }

                    {
                      pages && pages.map((x, index) => (
                        <DocumentPage
                          key={index}
                          ref={el => pagesDomRef.current[index] = el}
                          isSelected={selectedPageIndex === index}
                          designType={designType!}
                          documentPage={x}
                          index={index}
                          editable
                          onChange={(k, v) => {
                            debouncedUpdatePage(index, k, v)
                          }}
                        />
                      ))
                    }
                  </div>
                </div>
              </div>
              <div className="document-display-nav mr-3 bg-white">
                <ol className="doc-list mb-0">
                  {
                    pages && pages.map((x, index) => (
                      <li key={index} className={classNames({ active: selectedPageIndex === index })}>
                        <div className="position-relative">
                          <div className="thubmnail">
                            <div className="document-scale-wrapper">
                              <div
                                className={classNames(
                                  "document-display-wrapper",
                                  {
                                    'design-simple': designType === 'simple' || designType === 'dark',
                                    'design-simple-light': designType === 'simple',
                                    'design-simple-dark': designType === 'dark',
                                    'design-modern': designType === 'modern',
                                  }
                                )}
                                style={{
                                  transform: `scale(${thumbnailScale})`,
                                  transformOrigin: 'left top',
                                }}
                              >
                                <DocumentPage
                                  key={index}
                                  designType={designType!}
                                  documentPage={x}
                                  index={index}
                                />
                              </div>
                            </div>
                            <a className="blocklink" href="#!" onClick={e => {
                              e.preventDefault()
                              setSelectedPageIndex(index)
                            }}> </a>
                          </div>
                          {
                            x.layoutPage === 1 &&
                            <a className="icon icon-slide-delete" href="#!" title="削除" onClick={e => {
                              e.preventDefault()
                              setTargetPageIndex(index)
                              setShowDeleteModal(true)
                            }}> </a>
                          }
                          {
                            x.layoutPage === 1 &&
                            <a href="#!" title="追加" className="icon icon-slide-add" onClick={e => {
                              e.preventDefault()
                              setTargetPageIndex(index)
                              setShowSelectAddMethodModal(true)
                            }}> </a>
                          }
                        </div>
                      </li>
                    ))
                  }
                  <li>
                    <div className="position-relative">
                      <div className="thubmnail blank">
                        <img src={icon_document_blank} alt="" />
                        <a className="blocklink" href="#!" onClick={e => {
                          e.preventDefault()
                          setTargetPageIndex(pages && pages.length)
                          setShowSelectAddMethodModal(true)
                        }}> </a>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </section>
        </Layout>
       </form>
    </FormContext>
  );
}

export default DocumentEditPage;
