import React from "react";
import { useHistory } from "react-router-dom";

import HeaderNavAdminComponent from '../coding/common/HeaderNavAdmin'

const HeaderNavAdmin = () => {
  const history = useHistory();
  return (
    <HeaderNavAdminComponent
      isCodingPage={history.location.pathname.startsWith('/coding')} />
  );
}

export default HeaderNavAdmin;
