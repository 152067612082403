/* eslint-disable react-pug/no-interpolation */
import React from 'react';
import logo from '../../../assets/images/logo.svg';

const HeaderNav = (props) => {
  return pug`
    .navbar-header.navbar.p-0(className=${props.navbarClass})
      button.navbar-toggler.d-block.d-lg-none(
        onClick=props.sideNavTogglerClick
        type="button"
        data-target="#sidebar")
        span.bar
        
        span.bar

        span.bar

      a.navbar-brand.d-block.d-lg-none(href="#")
        img(src=logo alt="TAG WORK")

      .navbar-break(className=${props.children ? 'active' : ''})
        =props.children
                    
      =props.searchComponent
  `
}

export default HeaderNav;
