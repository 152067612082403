import Modal from "react-modal"
import React, { useRef, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import text_add_from_favorites from '../../assets/images/text_add_from_favorites.svg';
import icon_no_item from '../../assets/images/icon_no_item.svg';

import {
  useFavoriteAchivementsQuery,
  AchivementModel,
} from '../../api';

import Pagination from '../common/Pagination';
import AchivementCard from '../achivements/AchivementCardForSelect';
import VisibleOnScroll from "../common/VisibleOnScroll";

interface Props {
  isOpen: boolean;
  handleCloseModal(): void;
  handleSubmit(achivement: AchivementModel): void;
}

const perPage = 12

const FavoriteAchivementsModal = (props: Props) => {
  const [page, setPage] = useState(1);
  const { data } = useFavoriteAchivementsQuery({
    fetchPolicy: 'network-only',
    variables: {
      page,
      perPage,
    }
  });

  const achivements = data?.favoriteAchivements;
  const totalCount = achivements?.totalCount
  const totalPage = totalCount ? Math.ceil((totalCount) / perPage) : 0

  const modalContentRef = useRef(null)
 
  return (
    <Modal
      isOpen={props.isOpen}
      overlayClassName="Overlay"
      className="modal-dialog-lg"
      onRequestClose={props.handleCloseModal}
    >
      <div className="modal-content" ref={modalContentRef}>
        <div className="modal-header">
          <h3 className="modal-title-lg">
            <img src={text_add_from_favorites} alt="ADD FROM FAVORITES"/>
          </h3>
          <button type="button" className="close" onClick={props.handleCloseModal}>
            <span aria-hidden="true">
              <i className="icon icon-close-dark-sm"></i>
            </span>
          </button>
        </div>
        <div className="modal-body-gray position-relative">
          {
            totalCount === 0 &&
            <section className="section-no-item">
              <figure className="icon-no-item pl-0">
                <img src={icon_no_item} alt="" />
                <figcaption className="text-secondary">
                  該当する実績はありません
                </figcaption>
              </figure>
            </section>
          }

          <TransitionGroup className="row section-card_transition-group">
            {achivements && achivements.records.map((achivement, i) => 
              <CSSTransition
                key={i}
                timeout={200}
                classNames="section-card_transition-item"
              >
                <div className="col-3">
                  <VisibleOnScroll animateClassName="slideup" scrollableAncestor={modalContentRef.current}>
                    <AchivementCard 
                      className="slideup-before"
                      achivement={achivement}
                      handleSelect={() => {
                        props.handleSubmit(achivement)
                      }}
                    />
                  </VisibleOnScroll>
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>

          {
            totalPage > 0 && <>
              <Pagination 
                totalPage={totalPage}
                page={page}
                onChangePage={(x) => {
                  // TODO: refつかう
                  // TOPにscroll
                  const elem = document.getElementsByClassName('modal-dialog-lg')
                  if (elem.length > 0) {
                    elem[0].scrollTo(0, 0)
                  }

                  setPage(x)
                }}
              />
            </>
          }
        </div>
      </div>
    </Modal>
  );
}

export default FavoriteAchivementsModal