import React from "react";

import PaginationComponent from '../coding/common/Pagination'
import times from "lodash.times";

type Props = {
  page: number;
  totalPage: number;
  onChangePage: (e: number) => void;
}

const Pagination = (props: Props) => {
  // TODO: ページング仕様確認調整
  const pages = times(props.totalPage).map(x => x + 1)

  return (
    <PaginationComponent
      {...props}
      pages={pages}
      onPrevClick={(e: React.MouseEvent) => {
        e.preventDefault()
        if (props.page > 1) {
          props.onChangePage(props.page - 1);
          window.scrollTo(0, 0)
        }
      }}
      onPageClick={(e: React.MouseEvent<HTMLInputElement>, page: number) => {
        e.preventDefault()
        if (props.page !== page) {
          props.onChangePage(page);
          window.scrollTo(0, 0)
        }
      }}
      onNextClick={(e: React.MouseEvent) => {
        e.preventDefault()
        if (props.page < props.totalPage) {
          props.onChangePage(props.page + 1);
          window.scrollTo(0, 0)
        }
      }}
    />
  );
}

export default Pagination;
