import React from "react";
import { useParams } from 'react-router-dom';
import classNames from "classnames";

import DocumentCoverPage from "../documents/DocumentCoverPage";

import {
  useDocumentByIdQuery,
} from '../../api';

const DocumentShowThumbnailPage = () => {
  const { id } = useParams();
  const { data, loading, error } = useDocumentByIdQuery({
    variables: { id: id! }
  });

  if (data && data.documentById.id !== id) {
    return <></>
  }

  if (!loading && error) {
    return <></>
  }

  if (loading) {
    return <></>
  }

  const document = data!.documentById

  return (
    <main className="pdf-content">
      <div className={classNames("document-display")}>
        <div className={classNames("document-display-wrapper")}>
          <DocumentCoverPage document={document} />
        </div>
      </div>
    </main>
  );
}

export default DocumentShowThumbnailPage;
