import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  Link,
  useHistory
} from "react-router-dom";
import Moment from 'react-moment'
import { DocumentModel } from '../../api'

import img_no_item from '../../assets/images/img_no_item.png';

interface Props {
  document: DocumentModel;
  handleRemove?: Function;
  className?: string;
}

const Card = (props: Props) => {
  const { document, handleRemove } = props;
  const history = useHistory();

  return (
    <div className={`card card-list-item ${props.className}`}>
      {
        <div className="card-img-top">
          <LazyLoadImage className="lazy-load" alt={""} src={document.thumbnailImgUrl || img_no_item} width={'100%'} height={'100%'} />

          <button className="btn btn-lined-white" onClick={e => {
            e.preventDefault()
            history.push(`/documents/${document.id}`)
          }}>
            <i className="arrow" />
            詳細を見る
          </button>
        </div>
      }
      { handleRemove &&
        <button
          className="btn btn-primary btn-delete"
          title="削除する"
          onClick={e => {
            e.preventDefault()
            handleRemove();
          }}
        >
          <i className="icon icon-close-delete m-0" />
        </button>
      }
      <div className="card-body">
        <h5 className="card-title">{document.title}</h5>
        <p className="card-text">
          <span className="text-light-gray">UPDATE: </span>
          <Moment format="YYYY年MM月DD日">
            {document.updatedAt}
          </Moment>
        </p>
      </div>
      <Link to={`/documents/${document.id}`} className="blocklink"></Link>
    </div>
  )
}

export default Card;
