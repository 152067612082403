import React, { useState } from "react";
import { useHistory, useParams, Redirect } from "react-router-dom";

import {
  DocumentModel,
  AchivementModel,
  useAchivementByIdQuery,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useSetFavoriteAchivementMutation,
  useUnsetFavoriteAchivementMutation } from '../../api';
import {
  mutationResultHasError
} from '../../schema';

import DefaultLayout from "../layouts/DefaultLayout";
import AchivementDatail from '../achivements/AchivementDetail';
import LoadingOverlay from "../common/LoadingOverlay";
import AlertModal from "../modals/AlertModal";

import SearchDocumentsModal  from '../modals/SearchDocumentsModal'
import { DocumentModelUtil } from '../../utils/DocumentModel'

import icon_fav_add from '../../assets/images/icon_fav_add.svg';
import icon_doc_add from '../../assets/images/icon_doc_add.svg';
import icon_add_black from '../../assets/images/icon_add_black.svg';
import icon_contact from '../../assets/images/icon_contact.svg';

const AchivementShowPage = () => {
  const [showSearchDocumentsModal, setShowSearchDocumentsModal] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [setFavoriteAchivementMutation, setFavoriteState] = useSetFavoriteAchivementMutation({
    variables: { id: id! },
  });
  const [unsetFavoriteAchivementMutation, unsetFavoriteState] = useUnsetFavoriteAchivementMutation({
    variables: { id: id! },
  });
  const [createDocument, createDocumentState] = useCreateDocumentMutation();
  const [updateDocument, updateDocumentState] = useUpdateDocumentMutation();

  const { loading, data, error, refetch } = useAchivementByIdQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: id!,
    }
  });

  const setFavoriteAchivement = async (e: any) => {
    e.preventDefault();
    await setFavoriteAchivementMutation();
    refetch();
  }

  const unsetFavoriteAchivement = async (e: any) => {
    e.preventDefault();
    await unsetFavoriteAchivementMutation();
    refetch();
  }

  const createDocumentProcess = async (e: React.MouseEvent, achivement: AchivementModel) => {
    e.preventDefault()
    const response = await createDocument()
    const document = response.data!.createDocument

    await addDocumentPageProcess(document, achivement)

    const id = document.id;
    const path = `/documents/${id}/edit`
    history.push(path);
  }

  const addDocumentPageProcess = async (document: DocumentModel, achivement: AchivementModel) => {
    const newPage = DocumentModelUtil.createDefaultPage(achivement)
    const newDocument = {
      title: document.title,
      memo: document.memo,
      designType: document.designType,
      pages: [
        ...document.pages,
        newPage,
      ]
    }

    await updateDocument({
      variables: {
        id: document.id,
        input: newDocument,
      }
    })

    const id = document.id
    const path = `/documents/${id}/edit`
    history.push(path);
  }

  const submitting = 
    setFavoriteState.loading ||
    unsetFavoriteState.loading ||
    createDocumentState.loading ||
    updateDocumentState.loading

  const hasError = 
    mutationResultHasError(setFavoriteState) || 
    mutationResultHasError(unsetFavoriteState) || 
    mutationResultHasError(createDocumentState) || 
    mutationResultHasError(updateDocumentState)

  if (!loading && error) {
    return <Redirect to="/" />
  }

  const achivement = data?.achivementById;
  if (!achivement || achivement?.id !== id) {
    return <DefaultLayout></DefaultLayout>
  }

  return (
    <DefaultLayout>
      <LoadingOverlay active={submitting} />
      {
        hasError &&
        <AlertModal
          message="ネットワークに接続できませんでした。"
          visibleSec={5}
        />
      }
      {
        setFavoriteState.called && !setFavoriteState.loading && (
          setFavoriteState.error ? 
            <AlertModal
              message="ネットワークに接続できませんでした。"
              visibleSec={5}
            />:
            <AlertModal
              message="お気に入りに追加しました。"
              visibleSec={3}
            />
        )
      }
      {
        unsetFavoriteState.called && !unsetFavoriteState.loading && (
          unsetFavoriteState.error ? 
            <AlertModal
              message="ネットワークに接続できませんでした。"
              visibleSec={5}
            />:
            <AlertModal
              message="お気に入りを解除しました。"
              visibleSec={3}
            />
        )
      }

      <SearchDocumentsModal
        isOpen={showSearchDocumentsModal}
        handleCloseModal={() => {
          setShowSearchDocumentsModal(false)
        }}
        handleSubmit={document => {
          addDocumentPageProcess(document, achivement)
          setShowSearchDocumentsModal(false)
        }}
      />

      <section className="section section-works-detail">
        <div className="works-detail d-flex align-items-start">
          <article className="works-detail-main mr-lg-3 mb-lg-0">
            <AchivementDatail achivement={achivement} />
            <a href="mailto:xxx@xxx.com" className="btn btn-primary btn-xl has-icon rounded-0 d-none d-lg-flex">
              <i className="icon">
                <img src={icon_contact} alt="" />
              </i>
              <span>お問い合わせ</span>
            </a>

            {!achivement.isFavorite &&
              <a onClick={(e) => setFavoriteAchivement(e)} href="!#" className="btn btn-light btn-icon-left d-lg-none d-flex">
                <i className="icon">
                  <img src={icon_fav_add} alt="" />
                </i>
                <span className="text">お気に入りに追加</span>
              </a>
            }
            {achivement.isFavorite &&
              <a onClick={(e) => unsetFavoriteAchivement(e)} href="!#" className="btn btn-light btn-icon-left d-lg-none d-flex">
                <i className="icon">
                  <img src={icon_fav_add} alt="" />
                </i>
                <span className="text">お気に入りを解除</span>
              </a>
            }
            
            <a href="mailto:xxx@xxx.com" className="btn btn-primary btn-icon-left d-lg-none d-flex">
              <i className="icon">
                <img src={icon_contact} alt="" />
              </i>
              <span>お問い合わせ</span>
            </a>

          </article>
          <nav className="works-detail-nav sticky-top d-none d-lg-block">
            <ul className="sidebar-nav">
              <li className="sidebar-nav-item">
                {!achivement.isFavorite &&
                  <a onClick={(e) => setFavoriteAchivement(e)} href="!#">
                    <i className="icon">
                      <img src={icon_fav_add} alt="" /></i>
                    <span className="text text-black">お気に入りに追加</span>
                  </a>
                }
                {achivement.isFavorite &&
                  <a onClick={(e) => unsetFavoriteAchivement(e)} href="!#">
                    <i className="icon">
                      <img src={icon_fav_add} alt="" /></i>
                    <span className="text text-black">お気に入りを解除</span>
                  </a>
                }
              </li>
              <li className="sidebar-nav-item">
                <a
                  onClick={e => {
                    e.preventDefault()
                    setShowSearchDocumentsModal(true)
                  }}
                  href="!#">
                  <i className="icon">
                    <img src={icon_doc_add} alt="" />
                  </i>
                  <span className="text text-black">資料に追加</span>
                </a>
              </li>
              <li className="sidebar-nav-item">
                <a href="!#" onClick={e => createDocumentProcess(e, achivement)}>
                  <i className="icon">
                    <img src={icon_add_black} alt="" />
                  </i>
                  <span className="text">新規作成</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
    </DefaultLayout>
  );
}

export default AchivementShowPage;
