
import Modal from "react-modal"
import React, { useState } from 'react'
import { useTimeout } from 'beautiful-react-hooks'; 
const nl2br = require('react-nl2br');

interface Props {
  message: string;
  visibleSec?: number;
  onClose?: Function;
}

const AlertModal = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(true);

  useTimeout(() => {
    setShowModal(false)
  }, 1000 * ( props.visibleSec || 3));

  return (
    <div className="modal alert-modal">
      <Modal
        isOpen={showModal}
        className="modal-dialog"
        overlayClassName="Overlay alertOverlay"
        onRequestClose={() => {
          setShowModal(false)
          if (props.onClose) {
            props.onClose()
          }
        }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <p className="m-0">{nl2br(props.message)}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AlertModal