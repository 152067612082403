import React from "react";
import { useHistory } from "react-router-dom";

import HeaderNavSearchComponent from '../coding/common/HeaderNavSearch'
import { useUiContext } from "../../contexts/ui";
import {
  achivementCategories,
  achivementDetailedSearchPeriodTypes,
} from "../../constants"

const HeaderNavSearch = () => {
  const history = useHistory();
  const [uiState, uiDispatch] = useUiContext()
  const searchCondition = uiState.achivementsSearch

  const search = () => {
    uiDispatch({ type: 'closeSearch' })
    uiDispatch({
      type: 'setSearchCondition',
      payload: {
        ...searchCondition,
        submitCount: searchCondition.submitCount + 1,
      }
    })
    const pathname = '/achivements/search'
    if (history.location.pathname !== pathname) {
      history.push(pathname)
    }
  }

  const CustomDateInput =  React.forwardRef((props: any,ref: any) => {
    return (
      <div ref={ref} className={props.className} onClick={props.onClick}>
        {
          props.value ? 
          <span className="react-datepicker__current_text">{ props.value }</span>
          :
          <span className="react-datepicker__placeholder_text">{ props.placeholder }</span>
        }
      </div>
    )
  });

  return (
    <HeaderNavSearchComponent
      searchCondition={uiState.achivementsSearch}

      searchMenuClass={uiState.searchOpen ? 'show' : ''}
      searchMenuClick={(e: React.MouseEvent) => {
        e.preventDefault()

        uiDispatch({
          type: 'setSearchOpen', payload: !uiState.searchOpen
        })
      }}

      searchTextInputClick={(e: React.MouseEvent<HTMLInputElement>) => {
        uiDispatch({
          type: 'setSearchOpen', payload: true 
        })
      }}
      searchTextInputChange={(e: React.MouseEvent<HTMLInputElement>) => {
        const text = e.currentTarget.value
        uiDispatch({
          type: 'setSearchCondition',
          payload: {
            ...searchCondition,
            text,
          }
        })
      }}
      searchTextInputKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          search()
        }
      }}

      searchCategories={achivementCategories}
      isCheckedCategory={(e: string) => searchCondition.categories.includes(e)}
      searchCategoryChange={(e: React.FormEvent<HTMLInputElement>) => {
        const x = e.currentTarget.value
        const categories = searchCondition.categories
        const newCategories = categories.includes(x) ?
          categories.filter(c => c !== x) : [...categories, x]
        uiDispatch({
          type: 'setSearchCondition',
          payload: {
            ...searchCondition,
            categories: newCategories,
          }
        })
      }}

      searchPeriodTypes={achivementDetailedSearchPeriodTypes}
      isCheckedPeriodType={(e: string) => searchCondition.periodType === e}
      searchPeriodTypeChange={(e: React.FormEvent<HTMLInputElement>) => {
        const periodType = e.currentTarget.value

        uiDispatch({
          type: 'setSearchCondition',
          payload: {
            ...searchCondition,
            periodType,
          }
        })
      }}

      startedAt={searchCondition.startedAt ?
        new Date(searchCondition.startedAt) : null }
      changeStartedAt={(e: Date | null) => {
        uiDispatch({
          type: 'setSearchCondition',
          payload: {
            ...searchCondition,
            // MARK: 日時が指定された場合は期間指定にする
            periodType: e ? 'specify' : searchCondition.periodType,
            startedAt: e ? e.toISOString() : null,
          }
        })
      }}

      endedAt={searchCondition.endedAt ?
        new Date(searchCondition.endedAt) : null}
      changeEndedAt={(e: Date | null) => {
        uiDispatch({
          type: 'setSearchCondition',
          payload: {
            ...searchCondition,
            // MARK: 日時が指定された場合は期間指定にする
            periodType: e ? 'specify' : searchCondition.periodType,
            endedAt: e ? e.toISOString() : null,
          }
        })
      }}

      resetSearchClick={(e: React.MouseEvent) => {
        e.preventDefault()
        uiDispatch({ type: 'resetSearchCondition' })
      }}

      submitClick={(e: React.MouseEvent) => {
        e.preventDefault()
        search()
      }}

      customDateInput={<CustomDateInput/>}
    />
  );
}

export default HeaderNavSearch;
