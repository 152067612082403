/* eslint-disable react-pug/no-interpolation */
import React from 'react';
import logo from '../../../assets/images/logo.svg';
import icon_home from '../../../assets/images/icon_home.svg';
import icon_new from '../../../assets/images/icon_new.svg';
import icon_fav from '../../../assets/images/icon_fav.svg';
import icon_doc from '../../../assets/images/icon_doc.svg';
import icon_add from '../../../assets/images/icon_add.svg';
import icon_dashboard from '../../../assets/images/icon_dashboard.svg';
import icon_contact from '../../../assets/images/icon_contact.svg';
import {
  NavLink
} from "react-router-dom";

const Sidebar = (props) => {
  return pug`
    nav.sidebar#sidebar(className=${props.sideNavClass})
      .sidebar-wrapper
        .logo
          NavLink(
            to=props.isCodingPage ? "/coding" : "/" exact)
            img(src=logo alt="TAG WORK")

        button.navbar-toggler-close.btn.p-0(
          onClick=props.sideNavTogglerClick
          type="button"
          data-target="#sidebar")
          i.icon-close

        ul.sidebar-nav
          li.sidebar-nav-item
            NavLink(
              onClick=props.navLinkClick
              to=props.isCodingPage ? "/coding" : "/" exact)
              i.icon
                img(src=icon_home alt="")

              span.text ホーム

          li.sidebar-nav-item
            NavLink(
              onClick=props.navLinkClick
              to=props.isCodingPage ? "/coding/list" : "/achivements/recent" exact)
              i.icon
                img(src=icon_new alt="")
                
              span.text 最新実績

          li.sidebar-nav-item
            NavLink(
              onClick=props.navLinkClick
              to=props.isCodingPage ? "/coding/list" : "/achivements/favorite" exact)
              i.icon
                img(src=icon_fav alt="")
                
              span.text お気に入り

          li.sidebar-nav-item
            NavLink(
              onClick=props.navLinkClick
              to=props.isCodingPage ? "/coding/edit" : "/documents" exact)
              i.icon
                img(src=icon_doc alt="")
                
              span.text 作成した資料

          li.sidebar-nav-item
            a(
              onClick=props.createDocumentClick)
              i.icon
                img(src=icon_add alt="")
                
              span.text 新規作成

        ul.sidebar-nav.sidebar-nav-bottom
          if props.userIsAdmin
            li.sidebar-nav-item
              NavLink(
                onClick=props.navLinkClick
                to=props.isCodingPage ? "/coding/admin/edit" : "/admin")
                i.icon
                  img(src=icon_dashboard alt="")
                  
                span.text 管理画面

          li.sidebar-nav-item
            a(onClick=props.contactClick)
              i.icon
                img(src=icon_contact alt="")

              span.text お問い合わせ
  `
}

export default Sidebar;
