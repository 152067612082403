import React, { useState, useEffect} from "react";
import { RouteProps } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useDetailedSearchAchivementsLazyQuery } from '../../api';
import { useUiState } from "../../contexts/ui";

import SearchResultLayout from "../layouts/SearchResultLayout";
import AchivementLinkCard from '../achivements/AchivementLinkCard';
import Pagination from '../common/Pagination';
import SectionNoItem from "../common/SectionNoItem";
import VisibleOnScroll from "../common/VisibleOnScroll";

const perPage = 12

const AchivementsSearchPage = (props: RouteProps) => {
  const { achivementsSearch }  = useUiState()
  const [page, setPage] = useState(1)

  const [search, { data }] = useDetailedSearchAchivementsLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    search({
      variables: {
        perPage,
        page,
        ...achivementsSearch,
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [achivementsSearch.submitCount, page])

  const achivements = data?.detailedSearchAchivements;
  const totalCount = data?.detailedSearchAchivements.totalCount
  const totalPage = totalCount ? Math.ceil((totalCount) / perPage) : 0

  return (
    <SearchResultLayout totalCount={achivements && achivements.totalCount}>
      {
        totalCount === 0 && <SectionNoItem message='該当する実績はありません' />
      }
      <section className="section section-card-list has-nav-search">
        <TransitionGroup className="row section-card_transition-group">
          {achivements && achivements.records.map((achivement, i) => {
            return <CSSTransition
              key={i}
              timeout={200}
              classNames="section-card_transition-item"
            >
              <div className="col-lg-4">
                <VisibleOnScroll animateClassName="slideup">
                  <AchivementLinkCard achivement={achivement} className="slideup-before" />
                </VisibleOnScroll>
              </div>
            </CSSTransition>
          })}
        </TransitionGroup>
      </section>

      {
        totalPage > 0 && <Pagination 
          totalPage={totalPage}
          page={page}
          onChangePage={(x) => setPage(x)}
        />
      }
          
    </SearchResultLayout>
  );
}

export default AchivementsSearchPage;
