import React from 'react'

import AppService from '../services/app'
import { getGenPdfUrl } from '../components/routes/GenPdfRoute'

export const StateContext = React.createContext<string | null | undefined>(undefined)
export const AppServiceContext = React.createContext<AppService | undefined>(undefined)
export const DispatchContext = React.createContext<((token: string | null) => void) | undefined>(
  undefined
)

export const renderWithAppContext = (props: {
  authToken: string | null;
  setAuthToken: (token: string | null) => void;
  appService: AppService;
}, children: React.ReactNode) => {
  return (
    <StateContext.Provider value={props.authToken}>
      <DispatchContext.Provider value={props.setAuthToken}>
        <AppServiceContext.Provider value={props.appService}>
          { children }
        </AppServiceContext.Provider>
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const AppContextProvider: React.FC = ({ children }) => {
  const appService = new AppService()

  const [authToken, setAuthToken] = React.useState<string|null>(
    appService.getAuthToken()?? null
  )

  return renderWithAppContext({
    authToken,
    setAuthToken,
    appService,
  }, children)
}

export const useAuthToken = () => {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error('useAuthToken must be used within a AppContextProvider')
  }
  return context
}

export const useSetAuthToken = () => {
  const context = React.useContext(DispatchContext)
  if (context === undefined) {
    throw new Error('useSetAuthToken must be used within a AppContextProvider')
  }
  return context
}

export const useAppService = () => {
  const context = React.useContext(AppServiceContext)
  if (context === undefined) {
    throw new Error('useAppService must be used within a AppContextProvider')
  }
  return context
}

export const useAppContext = () => {
  const authToken = useAuthToken()
  const setAuthToken = useSetAuthToken()
  const appService = useAppService()

  const login = async (token: string) => {
    const result = await appService.authenticate(token)
    setAuthToken(result)
  }

  const logout = () => {
    appService.unauthenticate()
    setAuthToken(null)
  }

  const uploadAttachment = async (file: any) => {
    if (!authToken) {
      throw new Error();
    }
    const response = await appService.uploadDirect(authToken, file)
    return response
  }

  const generatePdf = async (path: string) => {
    if (!authToken) {
      throw new Error();
    }
    const url = getGenPdfUrl(path, authToken)
    const response = await appService.generatePdf(authToken, url)
    return response.data.url;
  }

  const downloadPdf = async (path: string, name: string) => {
    let url = await generatePdf(path)

    await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function(e) {
        if (xhr.status === 200) {
          const blob = new Blob([xhr.response], { type: 'application/pdf' });
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob);
          a.download = name;
          a.click();
          resolve()
        } else {
          reject(new Error(xhr.statusText))
        }
      }
      xhr.onerror = function() {
        reject(new Error(xhr.statusText))
      };
      xhr.send();
    })
  }

  return {
    authToken,
    setAuthToken,
    login,
    logout,
    uploadAttachment,
    generatePdf,
    downloadPdf,
  }
}
