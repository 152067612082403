import React, { useState } from "react";
import { Waypoint } from 'react-waypoint';
import { CSSTransition } from 'react-transition-group';

type Props = {
  children: React.ReactNode;
  animateClassName?: string;
  scrollableAncestor?: any;
}

const VisibleOnScroll: React.FC<Props> = ({ children, animateClassName, scrollableAncestor }) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <Waypoint
        topOffset={'-50%'}
        bottomOffset={'25%'}
        scrollableAncestor={scrollableAncestor}
        onEnter={() => {
          setVisible(true)
        }}
      />
      <CSSTransition
        in={visible} 
        timeout={500}
        classNames={animateClassName}
      >
        { children }
      </CSSTransition>
    </>
  );
}

export default VisibleOnScroll;
