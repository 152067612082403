import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AchivementCopyInput = {
  status: Scalars['String'];
  isPickup: Scalars['Boolean'];
  catchphrase?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  categories: Array<Scalars['String']>;
  mainVisual?: Maybe<AttachmentInput>;
  mainVisualThumbnail?: Maybe<AttachmentInput>;
  subVisuals: Array<AttachmentInput>;
  targetSection?: Maybe<Scalars['String']>;
  overviewSection?: Maybe<Scalars['String']>;
  backgroundSection?: Maybe<Scalars['String']>;
  resultSection?: Maybe<Scalars['String']>;
  pointSection?: Maybe<Scalars['String']>;
  staffListSection?: Maybe<Scalars['String']>;
  linkUrls: Array<Scalars['String']>;
  id: Scalars['String'];
  token: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  favoriteCount: Scalars['Int'];
};

export type AchivementCopyModel = {
   __typename?: 'AchivementCopyModel';
  id: Scalars['String'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  isPickup: Scalars['Boolean'];
  token: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  favoriteCount: Scalars['Int'];
  status: Scalars['String'];
  catchphrase?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  categories: Array<Scalars['String']>;
  mainVisual?: Maybe<AttachmentModel>;
  mainVisualThumbnail?: Maybe<AttachmentModel>;
  subVisuals: Array<AttachmentModel>;
  targetSection?: Maybe<Scalars['String']>;
  overviewSection?: Maybe<Scalars['String']>;
  backgroundSection?: Maybe<Scalars['String']>;
  resultSection?: Maybe<Scalars['String']>;
  pointSection?: Maybe<Scalars['String']>;
  staffListSection?: Maybe<Scalars['String']>;
  linkUrls: Array<Scalars['String']>;
};

export type AchivementInput = {
  status: Scalars['String'];
  isPickup: Scalars['Boolean'];
  catchphrase?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  categories: Array<Scalars['String']>;
  mainVisual?: Maybe<AttachmentInput>;
  mainVisualThumbnail?: Maybe<AttachmentInput>;
  subVisuals: Array<AttachmentInput>;
  targetSection?: Maybe<Scalars['String']>;
  overviewSection?: Maybe<Scalars['String']>;
  backgroundSection?: Maybe<Scalars['String']>;
  resultSection?: Maybe<Scalars['String']>;
  pointSection?: Maybe<Scalars['String']>;
  staffListSection?: Maybe<Scalars['String']>;
  linkUrls: Array<Scalars['String']>;
};

export type AchivementModel = {
   __typename?: 'AchivementModel';
  id: Scalars['ID'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  isPickup: Scalars['Boolean'];
  token: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  favoriteCount: Scalars['Int'];
  status: Scalars['String'];
  catchphrase?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  categories: Array<Scalars['String']>;
  mainVisual?: Maybe<AttachmentModel>;
  mainVisualThumbnail?: Maybe<AttachmentModel>;
  subVisuals: Array<AttachmentModel>;
  targetSection?: Maybe<Scalars['String']>;
  overviewSection?: Maybe<Scalars['String']>;
  backgroundSection?: Maybe<Scalars['String']>;
  resultSection?: Maybe<Scalars['String']>;
  pointSection?: Maybe<Scalars['String']>;
  staffListSection?: Maybe<Scalars['String']>;
  linkUrls: Array<Scalars['String']>;
};

export type AttachmentInput = {
  url: Scalars['String'];
  mimeType: Scalars['String'];
  fileName: Scalars['String'];
  fileSize: Scalars['Int'];
};

export type AttachmentModel = {
   __typename?: 'AttachmentModel';
  url: Scalars['String'];
  mimeType: Scalars['String'];
  fileName: Scalars['String'];
  fileSize: Scalars['Int'];
};

export type DocumentInput = {
  title: Scalars['String'];
  memo: Scalars['String'];
  designType: Scalars['String'];
  pages: Array<DocumentPageInput>;
};

export type DocumentModel = {
   __typename?: 'DocumentModel';
  userId: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  title: Scalars['String'];
  memo: Scalars['String'];
  thumbnailImgUrl?: Maybe<Scalars['String']>;
  coverImgUrls?: Maybe<Array<Scalars['String']>>;
  designType: Scalars['String'];
  pages: Array<DocumentPageModel>;
};

export type DocumentPageInput = {
  type: Scalars['String'];
  layoutType: Scalars['String'];
  layoutPage: Scalars['Int'];
  baseAchivement?: Maybe<AchivementCopyInput>;
  title?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  catchphrase?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  targetSection?: Maybe<Scalars['String']>;
  backgroundSection?: Maybe<Scalars['String']>;
  overviewSection?: Maybe<Scalars['String']>;
  pointSection?: Maybe<Scalars['String']>;
  resultSection?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  startedMonth?: Maybe<Scalars['String']>;
};

export type DocumentPageModel = {
   __typename?: 'DocumentPageModel';
  type: Scalars['String'];
  layoutType: Scalars['String'];
  layoutPage: Scalars['Int'];
  baseAchivement?: Maybe<AchivementCopyModel>;
  title?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  catchphrase?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  targetSection?: Maybe<Scalars['String']>;
  backgroundSection?: Maybe<Scalars['String']>;
  overviewSection?: Maybe<Scalars['String']>;
  pointSection?: Maybe<Scalars['String']>;
  resultSection?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  startedMonth?: Maybe<Scalars['String']>;
};

export type Mutation = {
   __typename?: 'Mutation';
  /** 実績の作成 */
  createAchivement: AchivementModel;
  /** idの実績を更新 */
  updateAchivement: AchivementModel;
  /** idsの実績を削除 */
  deleteAchivements: Array<Scalars['ID']>;
  /** idsの実績をPICKUP表示対象に設定/除外、現在のPICKUP表示対象を返す */
  updateAchivementsPickupTarget: Array<AchivementModel>;
  /** idの実績をお気に入り登録 */
  setFavoriteAchivement: Scalars['String'];
  /** idの実績をお気に入り登録解除 */
  unsetFavoriteAchivement: Scalars['String'];
  /** 資料を作成する */
  createDocument: DocumentModel;
  /** idの資料を更新 */
  updateDocument: DocumentModel;
  /** idの資料を削除 */
  deleteDocument: Scalars['String'];
  /** idの資料のメモを更新 */
  updateDocumentMemo: DocumentModel;
};


export type MutationCreateAchivementArgs = {
  input: AchivementInput;
};


export type MutationUpdateAchivementArgs = {
  input: AchivementInput;
  id: Scalars['ID'];
};


export type MutationDeleteAchivementsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateAchivementsPickupTargetArgs = {
  value: Scalars['Boolean'];
  ids: Array<Scalars['ID']>;
};


export type MutationSetFavoriteAchivementArgs = {
  id: Scalars['ID'];
};


export type MutationUnsetFavoriteAchivementArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateDocumentArgs = {
  input: DocumentInput;
  id: Scalars['ID'];
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateDocumentMemoArgs = {
  memo: Scalars['String'];
  id: Scalars['ID'];
};

export type PaginatedAchivementsModel = {
   __typename?: 'PaginatedAchivementsModel';
  records: Array<AchivementModel>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PaginatedDocumentsModel = {
   __typename?: 'PaginatedDocumentsModel';
  records: Array<DocumentModel>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type Query = {
   __typename?: 'Query';
  /** ログインユーザー */
  currentUser: UserModel;
  /** PICKUP表示対象の実績一覧 */
  pickupedAchivements: Array<AchivementModel>;
  /** 実績一覧（管理画面 2-1 用） */
  searchAchivements: PaginatedAchivementsModel;
  /** 実績一覧（表示画面 2-1用） */
  detailedSearchAchivements: PaginatedAchivementsModel;
  /** idの実績を取得 */
  achivementById: AchivementModel;
  /** tokenの実績を取得（共有URLページ用） */
  achivementByToken: AchivementModel;
  /** お気に入り実績一覧 */
  favoriteAchivements: PaginatedAchivementsModel;
  /** ユーザの資料一覧 */
  searchUserDocuments: PaginatedDocumentsModel;
  /** idの資料を取得 */
  documentById: DocumentModel;
};


export type QuerySearchAchivementsArgs = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  filterType?: Maybe<Scalars['String']>;
};


export type QueryDetailedSearchAchivementsArgs = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortProp: Scalars['String'];
  sortOrder: Scalars['String'];
  categories?: Maybe<Array<Scalars['String']>>;
  text?: Maybe<Scalars['String']>;
  periodType: Scalars['String'];
  endedAt?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
};


export type QueryAchivementByIdArgs = {
  id: Scalars['ID'];
};


export type QueryAchivementByTokenArgs = {
  token: Scalars['String'];
};


export type QueryFavoriteAchivementsArgs = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type QuerySearchUserDocumentsArgs = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type QueryDocumentByIdArgs = {
  id: Scalars['ID'];
};

export type UserModel = {
   __typename?: 'UserModel';
  id: Scalars['ID'];
  roles: Array<Scalars['String']>;
};

export type AchivementCopyDetailFragment = (
  { __typename?: 'AchivementCopyModel' }
  & Pick<AchivementCopyModel, 'id' | 'isPickup' | 'token' | 'createdAt' | 'updatedAt' | 'favoriteCount' | 'status' | 'catchphrase' | 'title' | 'startedAt' | 'endedAt' | 'clientName' | 'productName' | 'categories' | 'targetSection' | 'overviewSection' | 'resultSection' | 'pointSection' | 'staffListSection' | 'backgroundSection' | 'linkUrls'>
  & { mainVisualThumbnail?: Maybe<(
    { __typename?: 'AttachmentModel' }
    & AttachmentDetailFragment
  )>, mainVisual?: Maybe<(
    { __typename?: 'AttachmentModel' }
    & AttachmentDetailFragment
  )>, subVisuals: Array<(
    { __typename?: 'AttachmentModel' }
    & AttachmentDetailFragment
  )> }
);

export type AchivementWithFavoriteFragment = (
  { __typename?: 'AchivementModel' }
  & Pick<AchivementModel, 'isFavorite'>
  & AchivementDetailFragment
);

export type AchivementDetailFragment = (
  { __typename?: 'AchivementModel' }
  & Pick<AchivementModel, 'id' | 'isPickup' | 'token' | 'createdAt' | 'updatedAt' | 'favoriteCount' | 'status' | 'catchphrase' | 'title' | 'startedAt' | 'endedAt' | 'clientName' | 'productName' | 'categories' | 'targetSection' | 'overviewSection' | 'resultSection' | 'pointSection' | 'staffListSection' | 'backgroundSection' | 'linkUrls'>
  & { mainVisualThumbnail?: Maybe<(
    { __typename?: 'AttachmentModel' }
    & AttachmentDetailFragment
  )>, mainVisual?: Maybe<(
    { __typename?: 'AttachmentModel' }
    & AttachmentDetailFragment
  )>, subVisuals: Array<(
    { __typename?: 'AttachmentModel' }
    & AttachmentDetailFragment
  )> }
);

export type AttachmentDetailFragment = (
  { __typename?: 'AttachmentModel' }
  & Pick<AttachmentModel, 'url' | 'mimeType' | 'fileName' | 'fileSize'>
);

export type DocumentDetailFragment = (
  { __typename?: 'DocumentModel' }
  & Pick<DocumentModel, 'id' | 'userId' | 'createdAt' | 'updatedAt' | 'title' | 'memo' | 'designType' | 'coverImgUrls' | 'thumbnailImgUrl'>
  & { pages: Array<(
    { __typename?: 'DocumentPageModel' }
    & Pick<DocumentPageModel, 'type' | 'layoutPage' | 'layoutType' | 'title' | 'metadata' | 'catchphrase' | 'linkUrl' | 'targetSection' | 'backgroundSection' | 'overviewSection' | 'pointSection' | 'resultSection' | 'clientName' | 'productName' | 'startedMonth'>
    & { baseAchivement?: Maybe<(
      { __typename?: 'AchivementCopyModel' }
      & AchivementCopyDetailFragment
    )> }
  )> }
);

export type UserDetailFragment = (
  { __typename?: 'UserModel' }
  & Pick<UserModel, 'id' | 'roles'>
);

export type CreateAchivementMutationVariables = {
  input: AchivementInput;
};


export type CreateAchivementMutation = (
  { __typename?: 'Mutation' }
  & { createAchivement: (
    { __typename?: 'AchivementModel' }
    & AchivementDetailFragment
  ) }
);

export type CreateDocumentMutationVariables = {};


export type CreateDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createDocument: (
    { __typename?: 'DocumentModel' }
    & DocumentDetailFragment
  ) }
);

export type DeleteAchivementsMutationVariables = {
  ids: Array<Scalars['ID']>;
};


export type DeleteAchivementsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAchivements'>
);

export type DeleteDocumentMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteDocumentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDocument'>
);

export type SetFavoriteAchivementMutationVariables = {
  id: Scalars['ID'];
};


export type SetFavoriteAchivementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setFavoriteAchivement'>
);

export type UnsetFavoriteAchivementMutationVariables = {
  id: Scalars['ID'];
};


export type UnsetFavoriteAchivementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unsetFavoriteAchivement'>
);

export type UpdateAchivementMutationVariables = {
  id: Scalars['ID'];
  input: AchivementInput;
};


export type UpdateAchivementMutation = (
  { __typename?: 'Mutation' }
  & { updateAchivement: (
    { __typename?: 'AchivementModel' }
    & AchivementDetailFragment
  ) }
);

export type UpdateAchivementsPickupMutationVariables = {
  ids: Array<Scalars['ID']>;
  value: Scalars['Boolean'];
};


export type UpdateAchivementsPickupMutation = (
  { __typename?: 'Mutation' }
  & { updateAchivementsPickupTarget: Array<(
    { __typename?: 'AchivementModel' }
    & Pick<AchivementModel, 'id'>
  )> }
);

export type UpdateDocumentMutationVariables = {
  id: Scalars['ID'];
  input: DocumentInput;
};


export type UpdateDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateDocument: (
    { __typename?: 'DocumentModel' }
    & DocumentDetailFragment
  ) }
);

export type AchivementByTokenQueryVariables = {
  token: Scalars['String'];
};


export type AchivementByTokenQuery = (
  { __typename?: 'Query' }
  & { achivementByToken: (
    { __typename?: 'AchivementModel' }
    & AchivementWithFavoriteFragment
  ) }
);

export type AchivementByIdQueryVariables = {
  id: Scalars['ID'];
};


export type AchivementByIdQuery = (
  { __typename?: 'Query' }
  & { achivementById: (
    { __typename?: 'AchivementModel' }
    & AchivementWithFavoriteFragment
  ) }
);

export type AdminHomeQueryVariables = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  filterType?: Maybe<Scalars['String']>;
};


export type AdminHomeQuery = (
  { __typename?: 'Query' }
  & { allAchivements: (
    { __typename?: 'PaginatedAchivementsModel' }
    & Pick<PaginatedAchivementsModel, 'totalCount'>
  ), pickupAchivements: (
    { __typename?: 'PaginatedAchivementsModel' }
    & Pick<PaginatedAchivementsModel, 'totalCount'>
  ), draftAchivements: (
    { __typename?: 'PaginatedAchivementsModel' }
    & Pick<PaginatedAchivementsModel, 'totalCount'>
  ), openedAchivements: (
    { __typename?: 'PaginatedAchivementsModel' }
    & Pick<PaginatedAchivementsModel, 'totalCount'>
  ), searchAchivements: (
    { __typename?: 'PaginatedAchivementsModel' }
    & Pick<PaginatedAchivementsModel, 'totalCount'>
    & { records: Array<(
      { __typename?: 'AchivementModel' }
      & AchivementDetailFragment
    )> }
  ) }
);

export type CurrentUserQueryVariables = {};


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'UserModel' }
    & UserDetailFragment
  ) }
);

export type DetailedSearchAchivementsQueryVariables = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortOrder: Scalars['String'];
  sortProp: Scalars['String'];
  categories?: Maybe<Array<Scalars['String']>>;
  text?: Maybe<Scalars['String']>;
  periodType: Scalars['String'];
  startedAt?: Maybe<Scalars['String']>;
  endedAt?: Maybe<Scalars['String']>;
};


export type DetailedSearchAchivementsQuery = (
  { __typename?: 'Query' }
  & { detailedSearchAchivements: (
    { __typename?: 'PaginatedAchivementsModel' }
    & Pick<PaginatedAchivementsModel, 'totalCount'>
    & { records: Array<(
      { __typename?: 'AchivementModel' }
      & AchivementDetailFragment
    )> }
  ) }
);

export type DocumentByIdQueryVariables = {
  id: Scalars['ID'];
};


export type DocumentByIdQuery = (
  { __typename?: 'Query' }
  & { documentById: (
    { __typename?: 'DocumentModel' }
    & DocumentDetailFragment
  ) }
);

export type FavoriteAchivementsQueryVariables = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type FavoriteAchivementsQuery = (
  { __typename?: 'Query' }
  & { favoriteAchivements: (
    { __typename?: 'PaginatedAchivementsModel' }
    & Pick<PaginatedAchivementsModel, 'totalCount'>
    & { records: Array<(
      { __typename?: 'AchivementModel' }
      & AchivementWithFavoriteFragment
    )> }
  ) }
);

export type HomeQueryVariables = {};


export type HomeQuery = (
  { __typename?: 'Query' }
  & { pickupedAchivements: Array<(
    { __typename?: 'AchivementModel' }
    & AchivementDetailFragment
  )>, detailedSearchAchivements: (
    { __typename?: 'PaginatedAchivementsModel' }
    & { records: Array<(
      { __typename?: 'AchivementModel' }
      & AchivementDetailFragment
    )> }
  ), favoriteAchivements: (
    { __typename?: 'PaginatedAchivementsModel' }
    & { records: Array<(
      { __typename?: 'AchivementModel' }
      & AchivementDetailFragment
    )> }
  ), searchUserDocuments: (
    { __typename?: 'PaginatedDocumentsModel' }
    & { records: Array<(
      { __typename?: 'DocumentModel' }
      & DocumentDetailFragment
    )> }
  ) }
);

export type SearchUserDocumentsQueryVariables = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};


export type SearchUserDocumentsQuery = (
  { __typename?: 'Query' }
  & { searchUserDocuments: (
    { __typename?: 'PaginatedDocumentsModel' }
    & Pick<PaginatedDocumentsModel, 'totalCount'>
    & { records: Array<(
      { __typename?: 'DocumentModel' }
      & DocumentDetailFragment
    )> }
  ) }
);

export const AttachmentDetailFragmentDoc = gql`
    fragment AttachmentDetail on AttachmentModel {
  url
  mimeType
  fileName
  fileSize
}
    `;
export const AchivementDetailFragmentDoc = gql`
    fragment AchivementDetail on AchivementModel {
  id
  isPickup
  token
  createdAt
  updatedAt
  favoriteCount
  status
  catchphrase
  title
  startedAt
  endedAt
  clientName
  productName
  categories
  mainVisualThumbnail {
    ...AttachmentDetail
  }
  mainVisual {
    ...AttachmentDetail
  }
  subVisuals {
    ...AttachmentDetail
  }
  targetSection
  overviewSection
  resultSection
  pointSection
  staffListSection
  backgroundSection
  linkUrls
}
    ${AttachmentDetailFragmentDoc}`;
export const AchivementWithFavoriteFragmentDoc = gql`
    fragment AchivementWithFavorite on AchivementModel {
  ...AchivementDetail
  isFavorite
}
    ${AchivementDetailFragmentDoc}`;
export const AchivementCopyDetailFragmentDoc = gql`
    fragment AchivementCopyDetail on AchivementCopyModel {
  id
  isPickup
  token
  createdAt
  updatedAt
  favoriteCount
  status
  catchphrase
  title
  startedAt
  endedAt
  clientName
  productName
  categories
  mainVisualThumbnail {
    ...AttachmentDetail
  }
  mainVisual {
    ...AttachmentDetail
  }
  subVisuals {
    ...AttachmentDetail
  }
  targetSection
  overviewSection
  resultSection
  pointSection
  staffListSection
  backgroundSection
  linkUrls
}
    ${AttachmentDetailFragmentDoc}`;
export const DocumentDetailFragmentDoc = gql`
    fragment DocumentDetail on DocumentModel {
  id
  userId
  createdAt
  updatedAt
  title
  memo
  designType
  coverImgUrls
  thumbnailImgUrl
  pages {
    baseAchivement {
      ...AchivementCopyDetail
    }
    type
    layoutPage
    layoutType
    title
    metadata
    catchphrase
    linkUrl
    targetSection
    backgroundSection
    overviewSection
    pointSection
    resultSection
    clientName
    productName
    startedMonth
  }
}
    ${AchivementCopyDetailFragmentDoc}`;
export const UserDetailFragmentDoc = gql`
    fragment UserDetail on UserModel {
  id
  roles
}
    `;
export const CreateAchivementDocument = gql`
    mutation createAchivement($input: AchivementInput!) {
  createAchivement(input: $input) {
    ...AchivementDetail
  }
}
    ${AchivementDetailFragmentDoc}`;
export type CreateAchivementMutationFn = ApolloReactCommon.MutationFunction<CreateAchivementMutation, CreateAchivementMutationVariables>;

/**
 * __useCreateAchivementMutation__
 *
 * To run a mutation, you first call `useCreateAchivementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAchivementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAchivementMutation, { data, loading, error }] = useCreateAchivementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAchivementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAchivementMutation, CreateAchivementMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAchivementMutation, CreateAchivementMutationVariables>(CreateAchivementDocument, baseOptions);
      }
export type CreateAchivementMutationHookResult = ReturnType<typeof useCreateAchivementMutation>;
export type CreateAchivementMutationResult = ApolloReactCommon.MutationResult<CreateAchivementMutation>;
export type CreateAchivementMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAchivementMutation, CreateAchivementMutationVariables>;
export const CreateDocumentDocument = gql`
    mutation createDocument {
  createDocument {
    ...DocumentDetail
  }
}
    ${DocumentDetailFragmentDoc}`;
export type CreateDocumentMutationFn = ApolloReactCommon.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(CreateDocumentDocument, baseOptions);
      }
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = ApolloReactCommon.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const DeleteAchivementsDocument = gql`
    mutation deleteAchivements($ids: [ID!]!) {
  deleteAchivements(ids: $ids)
}
    `;
export type DeleteAchivementsMutationFn = ApolloReactCommon.MutationFunction<DeleteAchivementsMutation, DeleteAchivementsMutationVariables>;

/**
 * __useDeleteAchivementsMutation__
 *
 * To run a mutation, you first call `useDeleteAchivementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAchivementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAchivementsMutation, { data, loading, error }] = useDeleteAchivementsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteAchivementsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAchivementsMutation, DeleteAchivementsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAchivementsMutation, DeleteAchivementsMutationVariables>(DeleteAchivementsDocument, baseOptions);
      }
export type DeleteAchivementsMutationHookResult = ReturnType<typeof useDeleteAchivementsMutation>;
export type DeleteAchivementsMutationResult = ApolloReactCommon.MutationResult<DeleteAchivementsMutation>;
export type DeleteAchivementsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAchivementsMutation, DeleteAchivementsMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation deleteDocument($id: ID!) {
  deleteDocument(id: $id)
}
    `;
export type DeleteDocumentMutationFn = ApolloReactCommon.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, baseOptions);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = ApolloReactCommon.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const SetFavoriteAchivementDocument = gql`
    mutation setFavoriteAchivement($id: ID!) {
  setFavoriteAchivement(id: $id)
}
    `;
export type SetFavoriteAchivementMutationFn = ApolloReactCommon.MutationFunction<SetFavoriteAchivementMutation, SetFavoriteAchivementMutationVariables>;

/**
 * __useSetFavoriteAchivementMutation__
 *
 * To run a mutation, you first call `useSetFavoriteAchivementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFavoriteAchivementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFavoriteAchivementMutation, { data, loading, error }] = useSetFavoriteAchivementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetFavoriteAchivementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetFavoriteAchivementMutation, SetFavoriteAchivementMutationVariables>) {
        return ApolloReactHooks.useMutation<SetFavoriteAchivementMutation, SetFavoriteAchivementMutationVariables>(SetFavoriteAchivementDocument, baseOptions);
      }
export type SetFavoriteAchivementMutationHookResult = ReturnType<typeof useSetFavoriteAchivementMutation>;
export type SetFavoriteAchivementMutationResult = ApolloReactCommon.MutationResult<SetFavoriteAchivementMutation>;
export type SetFavoriteAchivementMutationOptions = ApolloReactCommon.BaseMutationOptions<SetFavoriteAchivementMutation, SetFavoriteAchivementMutationVariables>;
export const UnsetFavoriteAchivementDocument = gql`
    mutation unsetFavoriteAchivement($id: ID!) {
  unsetFavoriteAchivement(id: $id)
}
    `;
export type UnsetFavoriteAchivementMutationFn = ApolloReactCommon.MutationFunction<UnsetFavoriteAchivementMutation, UnsetFavoriteAchivementMutationVariables>;

/**
 * __useUnsetFavoriteAchivementMutation__
 *
 * To run a mutation, you first call `useUnsetFavoriteAchivementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsetFavoriteAchivementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsetFavoriteAchivementMutation, { data, loading, error }] = useUnsetFavoriteAchivementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnsetFavoriteAchivementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnsetFavoriteAchivementMutation, UnsetFavoriteAchivementMutationVariables>) {
        return ApolloReactHooks.useMutation<UnsetFavoriteAchivementMutation, UnsetFavoriteAchivementMutationVariables>(UnsetFavoriteAchivementDocument, baseOptions);
      }
export type UnsetFavoriteAchivementMutationHookResult = ReturnType<typeof useUnsetFavoriteAchivementMutation>;
export type UnsetFavoriteAchivementMutationResult = ApolloReactCommon.MutationResult<UnsetFavoriteAchivementMutation>;
export type UnsetFavoriteAchivementMutationOptions = ApolloReactCommon.BaseMutationOptions<UnsetFavoriteAchivementMutation, UnsetFavoriteAchivementMutationVariables>;
export const UpdateAchivementDocument = gql`
    mutation updateAchivement($id: ID!, $input: AchivementInput!) {
  updateAchivement(id: $id, input: $input) {
    ...AchivementDetail
  }
}
    ${AchivementDetailFragmentDoc}`;
export type UpdateAchivementMutationFn = ApolloReactCommon.MutationFunction<UpdateAchivementMutation, UpdateAchivementMutationVariables>;

/**
 * __useUpdateAchivementMutation__
 *
 * To run a mutation, you first call `useUpdateAchivementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAchivementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAchivementMutation, { data, loading, error }] = useUpdateAchivementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAchivementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAchivementMutation, UpdateAchivementMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAchivementMutation, UpdateAchivementMutationVariables>(UpdateAchivementDocument, baseOptions);
      }
export type UpdateAchivementMutationHookResult = ReturnType<typeof useUpdateAchivementMutation>;
export type UpdateAchivementMutationResult = ApolloReactCommon.MutationResult<UpdateAchivementMutation>;
export type UpdateAchivementMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAchivementMutation, UpdateAchivementMutationVariables>;
export const UpdateAchivementsPickupDocument = gql`
    mutation updateAchivementsPickup($ids: [ID!]!, $value: Boolean!) {
  updateAchivementsPickupTarget(value: $value, ids: $ids) {
    id
  }
}
    `;
export type UpdateAchivementsPickupMutationFn = ApolloReactCommon.MutationFunction<UpdateAchivementsPickupMutation, UpdateAchivementsPickupMutationVariables>;

/**
 * __useUpdateAchivementsPickupMutation__
 *
 * To run a mutation, you first call `useUpdateAchivementsPickupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAchivementsPickupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAchivementsPickupMutation, { data, loading, error }] = useUpdateAchivementsPickupMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateAchivementsPickupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAchivementsPickupMutation, UpdateAchivementsPickupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAchivementsPickupMutation, UpdateAchivementsPickupMutationVariables>(UpdateAchivementsPickupDocument, baseOptions);
      }
export type UpdateAchivementsPickupMutationHookResult = ReturnType<typeof useUpdateAchivementsPickupMutation>;
export type UpdateAchivementsPickupMutationResult = ApolloReactCommon.MutationResult<UpdateAchivementsPickupMutation>;
export type UpdateAchivementsPickupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAchivementsPickupMutation, UpdateAchivementsPickupMutationVariables>;
export const UpdateDocumentDocument = gql`
    mutation updateDocument($id: ID!, $input: DocumentInput!) {
  updateDocument(input: $input, id: $id) {
    ...DocumentDetail
  }
}
    ${DocumentDetailFragmentDoc}`;
export type UpdateDocumentMutationFn = ApolloReactCommon.MutationFunction<UpdateDocumentMutation, UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(UpdateDocumentDocument, baseOptions);
      }
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = ApolloReactCommon.MutationResult<UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>;
export const AchivementByTokenDocument = gql`
    query achivementByToken($token: String!) {
  achivementByToken(token: $token) {
    ...AchivementWithFavorite
  }
}
    ${AchivementWithFavoriteFragmentDoc}`;

/**
 * __useAchivementByTokenQuery__
 *
 * To run a query within a React component, call `useAchivementByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAchivementByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAchivementByTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAchivementByTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AchivementByTokenQuery, AchivementByTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<AchivementByTokenQuery, AchivementByTokenQueryVariables>(AchivementByTokenDocument, baseOptions);
      }
export function useAchivementByTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AchivementByTokenQuery, AchivementByTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AchivementByTokenQuery, AchivementByTokenQueryVariables>(AchivementByTokenDocument, baseOptions);
        }
export type AchivementByTokenQueryHookResult = ReturnType<typeof useAchivementByTokenQuery>;
export type AchivementByTokenLazyQueryHookResult = ReturnType<typeof useAchivementByTokenLazyQuery>;
export type AchivementByTokenQueryResult = ApolloReactCommon.QueryResult<AchivementByTokenQuery, AchivementByTokenQueryVariables>;
export const AchivementByIdDocument = gql`
    query achivementById($id: ID!) {
  achivementById(id: $id) {
    ...AchivementWithFavorite
  }
}
    ${AchivementWithFavoriteFragmentDoc}`;

/**
 * __useAchivementByIdQuery__
 *
 * To run a query within a React component, call `useAchivementByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAchivementByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAchivementByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAchivementByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AchivementByIdQuery, AchivementByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<AchivementByIdQuery, AchivementByIdQueryVariables>(AchivementByIdDocument, baseOptions);
      }
export function useAchivementByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AchivementByIdQuery, AchivementByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AchivementByIdQuery, AchivementByIdQueryVariables>(AchivementByIdDocument, baseOptions);
        }
export type AchivementByIdQueryHookResult = ReturnType<typeof useAchivementByIdQuery>;
export type AchivementByIdLazyQueryHookResult = ReturnType<typeof useAchivementByIdLazyQuery>;
export type AchivementByIdQueryResult = ApolloReactCommon.QueryResult<AchivementByIdQuery, AchivementByIdQueryVariables>;
export const AdminHomeDocument = gql`
    query adminHome($page: Int!, $perPage: Int!, $filterType: String) {
  allAchivements: searchAchivements(page: $page, perPage: $perPage) {
    totalCount
  }
  pickupAchivements: searchAchivements(page: $page, perPage: $perPage, filterType: "pickup") {
    totalCount
  }
  draftAchivements: searchAchivements(page: $page, perPage: $perPage, filterType: "draft") {
    totalCount
  }
  openedAchivements: searchAchivements(page: $page, perPage: $perPage, filterType: "opened") {
    totalCount
  }
  searchAchivements(page: $page, perPage: $perPage, filterType: $filterType) {
    totalCount
    records {
      ...AchivementDetail
    }
  }
}
    ${AchivementDetailFragmentDoc}`;

/**
 * __useAdminHomeQuery__
 *
 * To run a query within a React component, call `useAdminHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminHomeQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filterType: // value for 'filterType'
 *   },
 * });
 */
export function useAdminHomeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminHomeQuery, AdminHomeQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminHomeQuery, AdminHomeQueryVariables>(AdminHomeDocument, baseOptions);
      }
export function useAdminHomeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminHomeQuery, AdminHomeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminHomeQuery, AdminHomeQueryVariables>(AdminHomeDocument, baseOptions);
        }
export type AdminHomeQueryHookResult = ReturnType<typeof useAdminHomeQuery>;
export type AdminHomeLazyQueryHookResult = ReturnType<typeof useAdminHomeLazyQuery>;
export type AdminHomeQueryResult = ApolloReactCommon.QueryResult<AdminHomeQuery, AdminHomeQueryVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    ...UserDetail
  }
}
    ${UserDetailFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const DetailedSearchAchivementsDocument = gql`
    query detailedSearchAchivements($page: Int!, $perPage: Int!, $sortOrder: String!, $sortProp: String!, $categories: [String!], $text: String, $periodType: String!, $startedAt: String, $endedAt: String) {
  detailedSearchAchivements(page: $page, perPage: $perPage, sortProp: $sortProp, sortOrder: $sortOrder, categories: $categories, text: $text, periodType: $periodType, startedAt: $startedAt, endedAt: $endedAt) {
    totalCount
    records {
      ...AchivementDetail
    }
  }
}
    ${AchivementDetailFragmentDoc}`;

/**
 * __useDetailedSearchAchivementsQuery__
 *
 * To run a query within a React component, call `useDetailedSearchAchivementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailedSearchAchivementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailedSearchAchivementsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortOrder: // value for 'sortOrder'
 *      sortProp: // value for 'sortProp'
 *      categories: // value for 'categories'
 *      text: // value for 'text'
 *      periodType: // value for 'periodType'
 *      startedAt: // value for 'startedAt'
 *      endedAt: // value for 'endedAt'
 *   },
 * });
 */
export function useDetailedSearchAchivementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DetailedSearchAchivementsQuery, DetailedSearchAchivementsQueryVariables>) {
        return ApolloReactHooks.useQuery<DetailedSearchAchivementsQuery, DetailedSearchAchivementsQueryVariables>(DetailedSearchAchivementsDocument, baseOptions);
      }
export function useDetailedSearchAchivementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DetailedSearchAchivementsQuery, DetailedSearchAchivementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DetailedSearchAchivementsQuery, DetailedSearchAchivementsQueryVariables>(DetailedSearchAchivementsDocument, baseOptions);
        }
export type DetailedSearchAchivementsQueryHookResult = ReturnType<typeof useDetailedSearchAchivementsQuery>;
export type DetailedSearchAchivementsLazyQueryHookResult = ReturnType<typeof useDetailedSearchAchivementsLazyQuery>;
export type DetailedSearchAchivementsQueryResult = ApolloReactCommon.QueryResult<DetailedSearchAchivementsQuery, DetailedSearchAchivementsQueryVariables>;
export const DocumentByIdDocument = gql`
    query documentById($id: ID!) {
  documentById(id: $id) {
    ...DocumentDetail
  }
}
    ${DocumentDetailFragmentDoc}`;

/**
 * __useDocumentByIdQuery__
 *
 * To run a query within a React component, call `useDocumentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentByIdQuery, DocumentByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentByIdQuery, DocumentByIdQueryVariables>(DocumentByIdDocument, baseOptions);
      }
export function useDocumentByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentByIdQuery, DocumentByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentByIdQuery, DocumentByIdQueryVariables>(DocumentByIdDocument, baseOptions);
        }
export type DocumentByIdQueryHookResult = ReturnType<typeof useDocumentByIdQuery>;
export type DocumentByIdLazyQueryHookResult = ReturnType<typeof useDocumentByIdLazyQuery>;
export type DocumentByIdQueryResult = ApolloReactCommon.QueryResult<DocumentByIdQuery, DocumentByIdQueryVariables>;
export const FavoriteAchivementsDocument = gql`
    query favoriteAchivements($page: Int!, $perPage: Int!) {
  favoriteAchivements(page: $page, perPage: $perPage) {
    totalCount
    records {
      ...AchivementWithFavorite
    }
  }
}
    ${AchivementWithFavoriteFragmentDoc}`;

/**
 * __useFavoriteAchivementsQuery__
 *
 * To run a query within a React component, call `useFavoriteAchivementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoriteAchivementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoriteAchivementsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useFavoriteAchivementsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FavoriteAchivementsQuery, FavoriteAchivementsQueryVariables>) {
        return ApolloReactHooks.useQuery<FavoriteAchivementsQuery, FavoriteAchivementsQueryVariables>(FavoriteAchivementsDocument, baseOptions);
      }
export function useFavoriteAchivementsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FavoriteAchivementsQuery, FavoriteAchivementsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FavoriteAchivementsQuery, FavoriteAchivementsQueryVariables>(FavoriteAchivementsDocument, baseOptions);
        }
export type FavoriteAchivementsQueryHookResult = ReturnType<typeof useFavoriteAchivementsQuery>;
export type FavoriteAchivementsLazyQueryHookResult = ReturnType<typeof useFavoriteAchivementsLazyQuery>;
export type FavoriteAchivementsQueryResult = ApolloReactCommon.QueryResult<FavoriteAchivementsQuery, FavoriteAchivementsQueryVariables>;
export const HomeDocument = gql`
    query home {
  pickupedAchivements {
    ...AchivementDetail
  }
  detailedSearchAchivements(page: 1, perPage: 12, sortProp: "createdAt", sortOrder: "desc", periodType: "all") {
    records {
      ...AchivementDetail
    }
  }
  favoriteAchivements(page: 1, perPage: 12) {
    records {
      ...AchivementDetail
    }
  }
  searchUserDocuments(page: 1, perPage: 12) {
    records {
      ...DocumentDetail
    }
  }
}
    ${AchivementDetailFragmentDoc}
${DocumentDetailFragmentDoc}`;

/**
 * __useHomeQuery__
 *
 * To run a query within a React component, call `useHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        return ApolloReactHooks.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, baseOptions);
      }
export function useHomeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, baseOptions);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = ApolloReactCommon.QueryResult<HomeQuery, HomeQueryVariables>;
export const SearchUserDocumentsDocument = gql`
    query searchUserDocuments($page: Int!, $perPage: Int!) {
  searchUserDocuments(page: $page, perPage: $perPage) {
    totalCount
    records {
      ...DocumentDetail
    }
  }
}
    ${DocumentDetailFragmentDoc}`;

/**
 * __useSearchUserDocumentsQuery__
 *
 * To run a query within a React component, call `useSearchUserDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserDocumentsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useSearchUserDocumentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchUserDocumentsQuery, SearchUserDocumentsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchUserDocumentsQuery, SearchUserDocumentsQueryVariables>(SearchUserDocumentsDocument, baseOptions);
      }
export function useSearchUserDocumentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUserDocumentsQuery, SearchUserDocumentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchUserDocumentsQuery, SearchUserDocumentsQueryVariables>(SearchUserDocumentsDocument, baseOptions);
        }
export type SearchUserDocumentsQueryHookResult = ReturnType<typeof useSearchUserDocumentsQuery>;
export type SearchUserDocumentsLazyQueryHookResult = ReturnType<typeof useSearchUserDocumentsLazyQuery>;
export type SearchUserDocumentsQueryResult = ApolloReactCommon.QueryResult<SearchUserDocumentsQuery, SearchUserDocumentsQueryVariables>;