import React from "react";

import HeaderNav from "../headers/HeaderNav";
import HeaderNavSearchResult from "../headers/HeaderNavSearchResult";
import Sidebar from "../common/Sidebar";

type Props = { children?: React.ReactNode; totalCount?: number; }

const SearchResultLayout: React.FC<Props> = ({ children, totalCount }) => {
  return (
    <>
      <Sidebar />
      <main className="main-content">
        <div className="main-container">
          <HeaderNav>
            <HeaderNavSearchResult totalCount={totalCount} />
          </HeaderNav>
          { children }
        </div>
      </main>
    </>
  );
}

export default SearchResultLayout;
