import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { RouteProps } from 'react-router'
import { UAContext } from '@quentin-sommer/react-useragent'

type Props = RouteProps & { fallbackPath: string; }

const PcOnlyRoute: React.FC<Props> = (props: Props) => {
  const { uaResults } = useContext(UAContext)
  const isMobile = (uaResults as any).mobile;
  if (isMobile) {
    return <Redirect to={props.fallbackPath} />;
  }

  return (
    <Route {...props}>
     { props.children }
    </Route>
  );
}

export default PcOnlyRoute;
