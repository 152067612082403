import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { AchivementModel } from '../../api'
import { AchivementModelUtil } from '../../utils/AchivementModel'

interface Props {
  achivement: AchivementModel;
  handleRemove?: Function;
  className?: string;
}

const AchivementCard = (props: Props) => {
  const history = useHistory();
  const { achivement, handleRemove } = props;

  const mainVisualImgThumbnail = AchivementModelUtil.mainVisualImgThumbnail(achivement)
  
  return (
    <div className={`card card-list-item ${props.className}`}>
      {
        mainVisualImgThumbnail &&
        <div className="card-img-top">
          <LazyLoadImage className="lazy-load" alt={""} src={mainVisualImgThumbnail.url} width={'100%'} height={'100%'} />

          <button className="btn btn-lined-white" onClick={e => {
            e.preventDefault()
            history.push(`/achivements/${achivement.id}`)
          }}>
            <i className="arrow" />
            詳細を見る
          </button>
        </div>
      }
      { handleRemove &&
        <button
          className="btn btn-primary btn-delete"
          title="削除する"
          onClick={e => {
            e.preventDefault()
            handleRemove();
          }}
        >
          <i className="icon icon-close-delete m-0" />
        </button>
      }
      <div className="card-body">
        <h5 className="card-title">{achivement.catchphrase}</h5>
        <p className="card-text">
          <span className="text-light-gray">CLIENT: </span>{achivement.clientName}
        </p>
      </div>
      <div className="card-footer bg-pattern">
        {achivement.categories.map((category, i) => 
          <span key={i} className="btn btn-border tag mr-1 mb-1 is-no-hover">{ category }</span>
        )}
      </div>
      <Link to={`/achivements/${achivement.id}`} className="blocklink"></Link>
    </div>
  )
}

export default AchivementCard;
