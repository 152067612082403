import React, { Component } from 'react';
import 'mediaelement';

import 'mediaelement/build/mediaelementplayer.min.css';
import 'mediaelement/build/mediaelement-flash-video.swf';

interface Props {
  options: any
  id: string
  sources: any
  tracks: any
  poster: string
  controls: boolean
  preload: string
}

interface State {
  player: any
}

export default class MediaElement extends Component<Props, State> {
	success(media: any, node: any, instance: any) {
    
	}

	error(media: any) {
    
	}

	render() {
		const
			props = this.props,
			sources = props.sources,
			tracks = props.tracks,
			sourceTags = [],
			tracksTags = []
		;

		for (let i = 0, total = sources.length; i < total; i++) {
			const source = sources[i];
			sourceTags.push(`<source src="${source.src}" type="${source.type}">`);
		}

		for (let i = 0, total = tracks.length; i < total; i++) {
			const track = tracks[i];
			tracksTags.push(`<track src="${track.src}" kind="${track.kind}" srclang="${track.lang}"${(track.label ? ` label=${track.label}` : '')}>`);
		}

		const
			mediaBody = `${sourceTags.join("\n")}
				${tracksTags.join("\n")}`,
			mediaHtml = 
				`<video id="${props.id}" ${(props.poster ? ` poster=${props.poster}` : '')}
					${(props.controls ? ' controls' : '')}${(props.preload ? ` preload="${props.preload}"` : '')}>
					${mediaBody}
				</video>`
		;

		return (<div dangerouslySetInnerHTML={{__html: mediaHtml}}></div>);

	}

	componentDidMount() {

		const {MediaElementPlayer} = global as any;
		
		if (!MediaElementPlayer) {
			return;
		}

		const options = Object.assign({}, this.props.options, {
			pluginPath: './static/media/',
			stretching: 'responsive',
			success: (media: any, node: any, instance: any) => this.success(media, node, instance),
			error: (media: any, node: any) => this.error(media)
		});
		
		this.setState({player: new MediaElementPlayer(this.props.id, options)});
	}

	componentWillUnmount() {
		if (this.state.player) {
			this.state.player.remove();
			this.setState({player: null});
		}
	}
}