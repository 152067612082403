import React from "react";

import HeaderNav from "../headers/HeaderNavForDocumentEdit";
import Sidebar from "../common/Sidebar";

import {
  DocumentModel,
} from '../../api';

type Props = {
  children?: React.ReactNode;
  document?: DocumentModel;
}

const DocumentEditLayout: React.FC<Props> = ({ children, document }) => {
  return (
    <>
      <Sidebar pcOnly />
      <main className="main-content only-wide">
        <div className="main-container">
          <HeaderNav document={document} />
          { children }
        </div>
      </main>
    </>
  );
}

export default DocumentEditLayout;
