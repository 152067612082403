import Modal from "react-modal"
import React from 'react'

type Item = {
  key: string;
  name: string;
}

interface Props {
  isOpen: boolean;
  items: Item[];
  handleCloseModal(): void;
  handleSubmit(item: Item): void;
}

const SelectItemModal = (props: Props) => {
  return (
    <div className="modal confirm-modal">
      <Modal
        isOpen={props.isOpen}
        className="modal-dialog"
        overlayClassName="Overlay"
        onRequestClose={props.handleCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={props.handleCloseModal}>
              <span aria-hidden="true">
                <i className="icon icon-close-dark-sm"></i>
              </span>
            </button>
          </div>
          <div className="modal-body">
            <ul className="btn-list">
              {
                props.items.map((item, index) => (
                  <li key={index}>
                    <button className="btn btn-lg btn-border has-icon icon-fix" onClick={(e) => {
                      e.preventDefault()
                      props.handleSubmit(item)
                      props.handleCloseModal()
                    }}>
                      <i className="icon icon-works-add"></i>
                      { item.name }
                    </button>
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="modal-footer">
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SelectItemModal