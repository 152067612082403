import 'react-app-polyfill/ie11';
// TODO: core-jsに置き換えたほうが良さそう?
// http://var.blog.jp/archives/79457227.html
import 'babel-polyfill';
import 'array-flat-polyfill';
import 'element-scroll-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import Modal from "react-modal"

import { UserAgentProvider } from '@quentin-sommer/react-useragent'
import { registerLocale } from 'react-datepicker'

import ja from 'date-fns/locale/ja'

import './assets/styles/index.scss';

import * as serviceWorker from './serviceWorker';

import { AppContextProvider } from './contexts/app'
import { UiContextProvider } from './contexts/ui'

import App from './App';

registerLocale('ja', ja)

Modal.setAppElement("#root")

ReactDOM.render(
  <AppContextProvider>
    <UiContextProvider>
      <UserAgentProvider ua={window.navigator.userAgent}>
        <App />
      </UserAgentProvider>
    </UiContextProvider>
  </AppContextProvider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
