/* eslint-disable react-pug/no-interpolation */
import React from 'react';
import icon_filter from '../../../assets/images/icon_filter.svg';
import DatePicker from "react-datepicker";

const HeaderNavSearch = (props) => {
  return pug`
    .nav-search.dropdown.ml-lg-auto(className=${props.searchMenuClass})
      .dropdown-body
        .form-group.form-group-search.mb-0.d-none.d-lg-block.w-100
          span.p-0.icon.icon-search.form-control-feedback

          input.form-control.has-icon(
            value=props.searchCondition.text
            onClick=props.searchTextInputClick
            onChange=props.searchTextInputChange
            onKeyPress=props.searchTextInputKeyPress
            type="text"
            placeholder="実績を検索")

        button.btn.single-caret.dropdown-toggle.d-none.d-lg-block(
          onClick=props.searchMenuClick
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false")
        
        button.btn.p-0.dropdown-toggle.d-lg-none(
          onClick=props.searchMenuClick
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false")
          .icon-search

          .icon-close

      .dropdown-menu(className=${props.searchMenuClass})
        .form-section.d-block.d-lg-none
          .form-group.form-group-search.mb-0.w-100
            span.p-0.icon.icon-search.form-control-feedback

            input.form-control.has-icon(
              value=props.searchCondition.text
              onClick=props.searchTextInputClick
              onChange=props.searchTextInputChange
              onKeyPress=props.searchTextInputKeyPress
              type="text"
              placeholder="実績を検索")
        
        .form-section.bg-pattern.d-flex.justify-content-between.align-items-center
          h6.title.has-icon.mb-0
            i.icon
              img(src=icon_filter alt="" height="13")

            | 検索フィルタ
          
          a.pointer.d-block(
            onClick=props.resetSearchClick
          ) フィルタをリセット

        .form-section.pb-3
          h6.title.has-bullet カテゴリ
          .form-group.mb-0
            each category, index in props.searchCategories
              .form-check.form-check-inline(key=index)
                input.form-check-input(
                  checked=props.isCheckedCategory(category)
                  onChange=props.searchCategoryChange
                  id="category" + index
                  type="checkbox"
                  value=category)
                label.form-check-label(for="category" + index)=category
                                  
        .form-section
          h6.title.has-bullet 実施時期

          .form-group.mb-0
            each type, index in props.searchPeriodTypes
              .form-check.form-check-inline(key=type.key)
                input.form-check-input(
                  id="period" + type.key
                  type="radio"
                  name="period"
                  checked=props.isCheckedPeriodType(type.key)
                  onChange=props.searchPeriodTypeChange
                  value=type.key)
                label.form-check-label(
                  for="period" + type.key
                  )=type.val

          .form-row.mt-3
            .col
              DatePicker(
                locale="ja"
                isClearable
                dateFormat="yyyy/MM/dd"
                popperPlacement="top-start"
                className="form-control"
                placeholderText="開始日"
                maxDate=props.endedAt
                selected=props.startedAt
                onChange=props.changeStartedAt
                customInput=props.customDateInput
              )

            .col
              DatePicker(
                locale="ja"
                isClearable
                dateFormat="yyyy/MM/dd"
                popperPlacement="top-end"
                className="form-control"
                placeholderText="終了日"
                minDate=props.startedAt
                selected=props.endedAt
                onChange=props.changeEndedAt
                customInput=props.customDateInput
              )

        .form-section
          button.btn.btn-lg.btn-primary.w-100(
            onClick=props.submitClick
            type="submit"
          ) 検 索
  `
}

export default HeaderNavSearch;
