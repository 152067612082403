import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { GraphqlProvider } from "./graphql";

import PrivateRoute from './components/routes/PrivateRoute'
import PcOnlyRoute from './components/routes/PcOnlyRoute'
import AdminRoute from './components/routes/AdminRoute'
import GenPdfRoute from './components/routes/GenPdfRoute'
import RouteChangeConfirmation from './components/common/RouteChangeConfirmation'
import Login from './components/pages/Login'

import Home from './components/pages/Home'
import AchivementPreview from './components/pages/AchivementPreview'
import AchivementsRecent from './components/pages/AchivementsRecent'
import AchivementsFavorite from './components/pages/AchivementsFavorite'
import AchivementsSearch from './components/pages/AchivementsSearch'
import AchivementShow from './components/pages/AchivementShow'
import Documents from './components/pages/Documents'
import DocumentShow from './components/pages/DocumentShow'
import DocumentShowPdf from './components/pages/DocumentShowPdf'
import DocumentShowThumbnail from './components/pages/DocumentShowThumbnail'
import DocumentEdit from './components/pages/DocumentEdit'

import AdminHome from './components/admin/pages/Home'
import AdminAchivementNew from './components/admin/pages/AchivementNew'
import AdminAchivementEdit from './components/admin/pages/AchivementEdit'

import AppCoding from './AppCoding';

const App = () => {
  return (
    <GraphqlProvider>
      <Router getUserConfirmation={RouteChangeConfirmation}>
        <Switch>
          <Route exact path="/login" component={Login} />

          {
            process.env.NODE_ENV === 'development' &&
            <Route path="/coding">
              <AppCoding />
            </Route>
          }

          <GenPdfRoute exact path="/documents/:id/pdf" component={DocumentShowPdf} />
          <GenPdfRoute exact path="/documents/:id/thumb" component={DocumentShowThumbnail} />
          <PrivateRoute fallbackPath="/login">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/achivements/recent" component={AchivementsRecent} />
              <Route exact path="/achivements/favorite" component={AchivementsFavorite} />
              <Route exact path="/achivements/search" component={AchivementsSearch} />
              <Route exact path="/achivements/:id" component={AchivementShow} />
              <Route exact path="/documents" component={Documents} />
              <Route exact path="/documents/:id" component={DocumentShow} />
              <PcOnlyRoute
                exact path="/documents/:id/edit" component={DocumentEdit} fallbackPath="/" />
              <Route exact path="/draft/:token" component={AchivementPreview} />

              <AdminRoute path="/admin" fallbackPath="/">
                <Switch>
                  <Route exact path="/admin" component={AdminHome} />
                  <Route exact path="/admin/achivements/new" component={AdminAchivementNew} />
                  <Route exact path='/admin/achivements/:id/edit' component={AdminAchivementEdit} />
                </Switch>
              </AdminRoute>
            </Switch>
          </PrivateRoute>
        </Switch>
      </Router>
    </GraphqlProvider>
  );
}

export default App;
