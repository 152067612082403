import {
  HomeQuery,
  AdminHomeQuery,
  AchivementByTokenQuery,
  AchivementByIdQuery,
  DetailedSearchAchivementsQuery,
  FavoriteAchivementsQuery,
  SearchUserDocumentsQuery,
  DocumentByIdQuery,
  AchivementModel,
  AchivementCopyModel,
  PaginatedAchivementsModel,
  AttachmentModel,
  DocumentModel,
  CurrentUserQuery,
} from './api';
import times from 'lodash.times'

export const DummyAttachment: AttachmentModel = {
  __typename: "AttachmentModel",
  url: "https://via.placeholder.com/350x150",
  fileName: "ico_error.png",
  fileSize: 1242,
  mimeType: "image/png"
}

export const DummyAchivement: AchivementModel = {
  __typename: "AchivementModel",
  id: '1',
  isPickup: false,
  status: 'draft',
  catchphrase: 'hoge',
  title: 'hoge',
  startedAt: '2000-01-01',
  endedAt: '2000-01-02',
  clientName: 'hoge',
  productName: 'hoge',
  targetSection: 'target',
  overviewSection: 'overview',
  resultSection: 'result',
  backgroundSection: 'background\nhoge',
  pointSection: 'point',
  staffListSection: 'staffList',
  mainVisual: DummyAttachment,
  mainVisualThumbnail: DummyAttachment,
  subVisuals: [
    DummyAttachment,
  ],
  linkUrls: [
    'http://google.com',
    'http://google.com',
    'http://google.com',
  ],
  favoriteCount: 10,
  token: 'token',
  categories: ['category1', 'category2'],
  updatedAt: '2000-01-01',
  createdAt: '2000-01-02',
}

export const DummyDocument: DocumentModel = {
  __typename: "DocumentModel",
  userId: '1',
  id: '1',
  title: 'hoge',
  memo: 'memo',
  designType: 'simple',
  // designType: 'modern',
  pages: [
    // MARK: 会社案内
    {
      __typename: "DocumentPageModel",
      type: 'profile',
      layoutType: 'image',
      layoutPage: 1,
      baseAchivement: null,
      title: '',
      metadata: '',
      catchphrase: '',
      targetSection: '',
      backgroundSection: '',
      overviewSection: '',
      pointSection: '', 
      resultSection: '', 
      clientName: '', 
      productName: '',
      startedMonth: '',
      linkUrl: '',
    },
    {
      __typename: "DocumentPageModel",
      type: 'profile',
      layoutType: 'image',
      layoutPage: 2,
      baseAchivement: null,
      title: '',
      metadata: '',
      catchphrase: '',
      targetSection: '',
      backgroundSection: '',
      overviewSection: '',
      pointSection: '', 
      resultSection: '', 
      clientName: '', 
      productName: '',
      startedMonth: '',
      linkUrl: '',
    },
    {
      __typename: "DocumentPageModel",
      type: 'profile',
      layoutType: 'image',
      layoutPage: 3,
      baseAchivement: null,
      title: '',
      metadata: '',
      catchphrase: '',
      targetSection: '',
      backgroundSection: '',
      overviewSection: '',
      pointSection: '', 
      resultSection: '', 
      clientName: '', 
      productName: '',
      startedMonth: '',
      linkUrl: '',
    },
    {
      __typename: "DocumentPageModel",
      type: 'achivement',
      layoutType: 'image',
      layoutPage: 1,
      baseAchivement: DummyAchivement as AchivementCopyModel,
      title: 'title',
      metadata: 'metadata',
      catchphrase: 'catchphrase',
      linkUrl: 'hoge',
      targetSection: 'target',
      backgroundSection: 'background',
      overviewSection: 'overview',
      pointSection: 'point',
      resultSection: 'result',
      clientName: 'client',
      productName: 'product',
      startedMonth: '2000-01',
    },
    {
      __typename: "DocumentPageModel",
      type: 'achivement',
      layoutType: 'overview',
      layoutPage: 1,
      baseAchivement: DummyAchivement as AchivementCopyModel,
      title: 'title',
      metadata: 'metadata',
      catchphrase: 'catchphrase',
      linkUrl: 'hoge',
      targetSection: 'target',
      backgroundSection: 'background',
      overviewSection: 'overview',
      pointSection: 'point',
      resultSection: 'result',
      clientName: 'client',
      productName: 'product',
      startedMonth: '2000-01',
    },
    {
      __typename: "DocumentPageModel",
      type: 'achivement',
      layoutType: 'detail',
      layoutPage: 1,
      baseAchivement: DummyAchivement as AchivementCopyModel,
      title: 'title',
      metadata: 'metadata',
      catchphrase: 'catchphrase',
      linkUrl: 'hoge',
      targetSection: 'target',
      backgroundSection: 'background',
      overviewSection: 'overview',
      pointSection: 'point',
      resultSection: 'result',
      clientName: 'client',
      productName: 'product',
      startedMonth: '2000-01',
    },
    {
      __typename: "DocumentPageModel",
      type: 'achivement',
      layoutType: 'detail',
      layoutPage: 2,
      baseAchivement: DummyAchivement as AchivementCopyModel,
      title: 'title',
      metadata: 'metadata',
      catchphrase: 'catchphrase',
      linkUrl: 'hoge',
      targetSection: 'target',
      backgroundSection: 'background',
      overviewSection: 'overview',
      pointSection: 'point',
      resultSection: 'result',
      clientName: 'client',
      productName: 'product',
      startedMonth: '2000-01',
    },
  ],
  updatedAt: '2000-01-01',
  createdAt: '2000-01-02',
  coverImgUrls: [
    "https://via.placeholder.com/350x150",
    "https://via.placeholder.com/350x150",
    "https://via.placeholder.com/350x150",
    "https://via.placeholder.com/350x150",
    "https://via.placeholder.com/350x150",
    "https://via.placeholder.com/350x150",
    "https://via.placeholder.com/350x150",
    "https://via.placeholder.com/350x150",
    "https://via.placeholder.com/350x150",
    "https://via.placeholder.com/350x150",

  ],
  thumbnailImgUrl: "https://via.placeholder.com/350x150",
}

export const DummyPaginatedAchivements: PaginatedAchivementsModel = {
  records: times(3).map(x => ({ ...DummyAchivement, id: x.toString() })),
  page: 0,
  perPage: 12,
  totalCount: 3,
}

export const AdminHomeQueryData: AdminHomeQuery = {
  allAchivements: {
    totalCount: 3,
  },
  pickupAchivements: {
    totalCount: 3,
  },
  draftAchivements: {
    totalCount: 3,
  },
  openedAchivements: {
    totalCount: 3,
  },
  searchAchivements: {
    totalCount: 3,
    records: times(3).map(x => ({ ...DummyAchivement, id: x.toString() }))
  },
}

export const AchivementByTokenQueryData: AchivementByTokenQuery = {
  achivementByToken: DummyAchivement
}

export const AchivementByIdQueryData: AchivementByIdQuery = {
  achivementById: DummyAchivement
}

export const DetailedSearchAchivementsQueryData: DetailedSearchAchivementsQuery = {
  detailedSearchAchivements: {
    totalCount: 3,
    records: times(3).map(x => ({ ...DummyAchivement, id: x.toString() }))
  }
}

export const FavoriteAchivementsQueryData: FavoriteAchivementsQuery = {
  favoriteAchivements: {
    totalCount: 3,
    records: times(3).map(x => ({ ...DummyAchivement, id: x.toString() }))
  }
}

export const SearchUserDocumentsQueryData: SearchUserDocumentsQuery = {
  searchUserDocuments: {
    totalCount: 3,
    records: times(3).map(x => ({ ...DummyDocument, id: x.toString() }))
  }
}

export const DocumentByIdQueryData: DocumentByIdQuery = {
  documentById: DummyDocument
}

export const createAttachment = (update?: Partial<AttachmentModel>): AttachmentModel => ({
  ...DummyAttachment,
  ...update,
})
  
export const createAchivement = (update?: Partial<AchivementModel>): AchivementModel => ({
  ...DummyAchivement,
  ...update,
})

export const createHomeQuery = (): HomeQuery => ({
  pickupedAchivements: times(3).map(x => ({ ...DummyAchivement, id: x.toString() })),
  detailedSearchAchivements: {
    __typename: 'PaginatedAchivementsModel',
    records: times(3).map(x => ({ ...DummyAchivement, id: x.toString() }))
  },
  favoriteAchivements: {
    __typename: 'PaginatedAchivementsModel',
    records: times(3).map(x => ({ ...DummyAchivement, id: x.toString() }))
  },
  searchUserDocuments: {
    __typename: 'PaginatedDocumentsModel',
    records: times(3).map(x => ({ ...DummyDocument, id: x.toString() }))
  }
})

export const createCurrentUserQuery = (): CurrentUserQuery => ({
  currentUser: {
    __typename: 'UserModel',
    id: '1',
    roles: ['admin'],
  },
})

export const createFavoriteAchivementsQuery = (): FavoriteAchivementsQuery => ({
  favoriteAchivements: {
    __typename: 'PaginatedAchivementsModel',
    totalCount: 3,
    records: times(3).map(x => ({ ...DummyAchivement, id: x.toString(), isFavorite: true }))
  }
})

export const createDetailedSearchAchivementsQuery = (): DetailedSearchAchivementsQuery => ({
  detailedSearchAchivements: {
    __typename: 'PaginatedAchivementsModel',
    totalCount: 3,
    records: times(3).map(x => ({ ...DummyAchivement, id: x.toString(), isFavorite: true }))
  }
})

export const createSearchUserDocumentsQuery = (): SearchUserDocumentsQuery => ({
  searchUserDocuments: {
    __typename: 'PaginatedDocumentsModel',
    totalCount: 3,
    records: times(3).map(x => ({ ...DummyDocument, id: x.toString() }))
  }
})

export const createDocumentByIdQuery = (): DocumentByIdQuery => ({
  documentById: DummyDocument
})