import React from 'react';
import { DocumentPageProps } from './DocumentPage'

import company_pic1 from '../../assets/images/img_company_pic1.svg';
import company_pic1_w from '../../assets/images/img_company_pic1_white.svg';
import company_pic2 from '../../assets/images/img_company_pic2.svg';
import company_pic2_w from '../../assets/images/img_company_pic2_white.svg';

export const DocumentPageCompanyProfileFirst = (props: DocumentPageProps) => {
  return <>
    <div className="doc-works-body">
      <div className="company-header">会社案内：宣言</div>
      <div className="company-body">
        <figure className="company-picture text-center">
          <img src={company_pic1} alt="実験、実践、実現。FOR AMAZING EXPERIENCES" className="company-picture-light" />
          <img src={company_pic1_w} alt="実験、実践、実現。FOR AMAZING EXPERIENCES" className="company-picture-dark" />
        </figure>
        <div className="company-text">
          <p>私たちは、お客さま及びその顧客に、Amazing Experience＜驚くべき体験＞をもたらすことをお約束します。</p>
          <p>私たちは、生活者とブランドとの良好で永続的な関係を築き、お客さまの継続的な事業発展のためにあらゆる接触機会において、満足や感動を引き出すような体験をデザインしていきます。</p>
          <p>私たちは、常に、本質を問い、新しいやり方に挑戦＝実験し、生活者の中において実践し、Amazing Experienceを実現していきます。</p>
        </div>
      </div>
    </div>
    <div className="nombre">{ props.index + 1 }</div>
  </>
}

export const DocumentPageCompanyProfileSecond = (props: DocumentPageProps) => {
  return <>
    <div className="doc-works-body">
      <div className="company-header">会社案内：メソッド</div>
      <div className="company-body">
        <div className="company-subtitle">私たちは、次のようなステップで、ビッグデータ時代に、Amazing Experienceを生み出します。</div>
        <dl className="company-ol">
          <div className="company-ol-wrapper">
            <dt>1.</dt>
            <dd>データドリブンな生活者理解に基づき、インサイトを発見します。</dd>
          </div>
          <div className="company-ol-wrapper">
            <dt>2.</dt>
            <dd>統合されたブランド体験のための、シナリオを設計し、実行します。
              <figure className="company-picture mb-0">
                <img src={company_pic2} alt="体験シナリオ - 製品／サービス | 接点 | コンテンツ" className="company-picture-light" />
                <img src={company_pic2_w} alt="体験シナリオ - 製品／サービス | 接点 | コンテンツ" className="company-picture-dark" />
              </figure>
            </dd>
          </div>
          <div className="company-ol-wrapper">
            <dt>3.</dt>
            <dd>Amazing Experienceの感動を、生活者が共有し、話題が拡散する仕掛けを展開します。</dd>
          </div>
          <div className="company-ol-wrapper">
            <dt>4.</dt>
            <dd>ブランドの価値に共鳴する多くの顧客との永続的な関係を構築します。
              <br />そして、お客さまのビジネスの成功に貢献いたします。 
            </dd>
          </div>
        </dl>
        <p className="small">
          ※1  マスメディアから街、移動空間、商空間まで、あらゆる生活の「場」をコミュニケーションの接点にします。
          <br/>
          ※2  生活者を熱狂させるコンテンツを生み出してきた実績を活かして、ブランドの魅力を最大限に引き出す出来事をクリエイトします。
        </p>
      </div>
    </div>
    <div className="nombre">{ props.index + 1 }</div>
  </>
}

export const DocumentPageCompanyProfileThird = (props: DocumentPageProps) => {
  return <>
    <div className="doc-works-body">
      <div className="company-header">会社案内：私たち独自の体験づくり</div>
      <div className="company-body">
        <p>
          多くの生活者接点を持つ東急グループの一員だからこそ、
          <br />私たちは、リアルに設計された実効性の高いマーケティングソリューション、体験づくりを実現します。
          <br />それが、Amazing Experienceを生み出すための、東急エージェンシーの独自性／ユニークネスです。
        </p>

        <hr />

        <dl className="company-ol">
          <div className="company-ol-wrapper">
            <dt>1.</dt>
            <dd>東急線沿線や東急グループの商業施設「 TOKYU OOH 」等や渋谷をはじめとする街を舞台とした体験づくり。</dd>
          </div>
          <div className="company-ol-wrapper">
            <dt>2.</dt>
            <dd>お客さまのPDCAに併走し、ブランドマネジメントと連動する体験づくり。</dd>
          </div>
          <div className="company-ol-wrapper">
            <dt>3.</dt>
            <dd>データ分析に基づいた、リアルなセールスのトリガーとなる体験づくり。</dd>
          </div>
          <div className="company-ol-wrapper">
            <dt>4.</dt>
            <dd>生活者の話題となるイベント、企業とお取引先様との直接の絆を構築するカンファレンスなどでの体験づくり。 </dd>
          </div>
          <div className="company-ol-wrapper">
            <dt>5.</dt>
            <dd>ショッピングセンターからショールームまで、企業活動の多様な接点における体験づくり。</dd>
          </div>
          <div className="company-ol-wrapper">
            <dt>6.</dt>
            <dd>国内空港OOHトップクラスの実績を持つなど、OOHにおける体験づくり。 </dd>
          </div>
        </dl>
      </div>
    </div>
    <div className="nombre">{ props.index + 1 }</div>
  </>
}