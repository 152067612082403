export const achivementCategories = [
  'ブランディング',
  '販促',
  'ビジネス創造',
  'SDGs',
  'TV',
  'ラジオ',
  '新聞',
  '雑誌',
  'Webメディア',
  '交通・OOH',
  'TV CM',
  'Web Movie',
  'SNS',
  'PR',
  'Real Promotion',
  'Digital Promotion',
  '流通施策',
  'タレント・コンテンツタイアップ',
  '東急グループ'
]

export const achivementDetailedSearchPeriodTypes = [
  {
    key: 'all',
    val: 'すべて',
  },
  {
    key: 'before30days',
    val: '30日以内',
  },
  {
    key: 'beforeHalfYear',
    val: '半年以内',
  },
  {
    key: 'beforeYear',
    val: '1年以内',
  },
  {
    key: 'specify',
    val: '期間指定',
  },
]

export const achivementDetailedSearchSortTypes = [
  {
    key: 'createdAt_desc',
    val: '登録が新しい',
  },
  {
    key: 'createdAt_asc',
    val: '登録が古い',
  },
  {
    key: 'favoriteCount_desc',
    val: '保存数が多い',
  },
  {
    key: 'favoriteCount_asc',
    val: '保存数が少ない',
  },
]

