import React, { useState, useEffect } from 'react'
import Modal from "react-modal"
import text_choose_a_layout from '../../assets/images/text_choose_a_layout.svg';
import img_layout1 from '../../assets/images/img_layout1.png';
import img_layout2 from '../../assets/images/img_layout2.png';
import img_layout3_1 from '../../assets/images/img_layout3_1.png';
import img_layout3_2 from '../../assets/images/img_layout3_2.png';

interface Props {
  isOpen: boolean;
  defaultLayout: string | null;
  handleCloseModal(): void;
  handleSubmit(key: string): void;
}

const layouts = [
  {
    key: 'image',
    name: 'IMAGE',
    image: img_layout1,
    image2: null,
  },
  {
    key: 'overview',
    name: 'OVERVIEW',
    image: img_layout2,
    image2: null,
  },
  {
    key: 'detail',
    name: 'DETAIL',
    image: img_layout3_1,
    image2: img_layout3_2,
  },
]

const SelectItemModal = (props: Props) => {
  const [layout, setLayout] = useState<string | null>(null)

  useEffect(() => {
    setLayout(props.defaultLayout)
  }, [props.defaultLayout, props.isOpen])

  return (
    <div className="modal confirm-modal">
      <Modal
        isOpen={props.isOpen}
        className="modal-dialog-lg"
        overlayClassName="Overlay"
        onRequestClose={props.handleCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title-lg">
              <img src={text_choose_a_layout} alt="CHOOSE A LAYOUT"/>
            </h3>
            <button type="button" className="close ml-4 pl-0" onClick={props.handleCloseModal}>
              <span aria-hidden="true">
                <i className="icon icon-close-dark-sm"></i>
              </span>
            </button>

            <button
              onClick={() => {
                if (layout) {
                  props.handleSubmit(layout)
                }
              }}
              disabled={layout === null}
              type='button'
              className="btn btn-primary btn-submit"
              style={{width: '100px'}}>決定</button>

          </div>
          <div className="modal-body-gray">
            <div className="row row-wide-gutter text-center">
              {
                layouts.map((item, index) => (
                  <div className="col-4" key={index}>
                    <div className="form-check form-check-inline">
                      <input
                        type="radio"
                        name="layout"
                        className="form-check-input"
                        checked={layout === item.key}
                        onChange={e => setLayout(item.key)}
                        value={item.key} />
                      <label
                        onClick={() => {
                          setLayout(item.key)
                        }}
                        className="form-check-label">
                        { item.name }</label>
                    </div>
                    <div className="form-lavel-thumbnail">
                      <input
                        type="radio"
                        name="layout2"
                        className="form-check-input"
                        checked={layout === item.key}
                        onChange={e => setLayout(item.key)}
                        value={item.key} />
                      <label
                        className="lavel-thumbnail"
                        onClick={() => { setLayout(item.key) }}
                      >
                        <img src={item.image} alt=""/>
                      </label>
                      {
                        item.image2 && 
                        <label
                          className="lavel-thumbnail"
                          onClick={() => { setLayout(item.key) }}
                        >
                          <img src={item.image2} alt=""/>
                        </label>
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SelectItemModal