import React, { useState, } from "react";
import { useHistory } from "react-router-dom";

import {
  DocumentModel,
} from '../../api';

import HeaderNavComponent from '../coding/common/HeaderNavForDocumentShow'

type Props = {
  children?: React.ReactNode;
  document?: DocumentModel;
  createPdf?: Function;
  startPresentation?: Function;
}

const HeaderNav = (props: Props) => {
  const history = useHistory();
  const [showMemo, setShowMemo] = useState(false)

  return (
    <HeaderNavComponent
      document={props.document}
      memoPopoverClass={showMemo ? 'show' : ''}
      memoPopoverClick={(e: React.MouseEvent) => {
        e.preventDefault()
        setShowMemo(!showMemo)
      }}
      closeMemoPopoverClick={(e: React.MouseEvent) => {
        e.preventDefault()
        setShowMemo(false)
      }}
      saveMemoPopoverClick={(e: React.MouseEvent) => {
        setShowMemo(false)
      }}
      pdfDownloadClick={(e: React.MouseEvent) => {
        e.preventDefault()
        if (props.createPdf) {
          props.createPdf()
        }
      }}
      startPresentationClick={(e: React.MouseEvent) => {
        e.preventDefault()
        if (props.startPresentation) {
          props.startPresentation()
        }
      }}
      editClick={(e: React.MouseEvent) => {
        e.preventDefault()
        if (props.document) {
          history.push(`/documents/${props.document.id}/edit`)
        }
      }}
    >
      { props.children }
    </HeaderNavComponent>
  );
}

export default HeaderNav;
