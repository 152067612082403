import React, { useEffect, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form'
import { useParams, Redirect, useHistory, Prompt } from 'react-router-dom';

import {
  useAchivementByIdQuery,
  useUpdateAchivementMutation,
  useDeleteAchivementsMutation,
  AchivementModel,
  AchivementInput,
} from '../../../api'

import {
  AchivementSchema,
  AchivementForm,
} from '../../../schema'

import DefaultLayout from "../../layouts/DefaultAdminLayout";

import LoadingOverlay from "../../common/LoadingOverlay";

import FormTitleSection  from '../forms/AchivementFormTitleSection'
import FormVisualSection  from '../forms/AchivementFormVisualSection'
import FormMetadataSection  from '../forms/AchivementFormMetadataSection'
import AlertModal from "../../modals/AlertModal";
import ConfirmModal  from '../../modals/ConfirmModal'
// import { DefaultQuery as adminHomeDefaultQuery } from './Home'

import { copyInputElement } from '../../../utils/ui';

import text_edit_works from '../../../assets/images/text_edit_works.svg';

const statusProp = 'status'

const normalizeData = (data: AchivementInput): AchivementInput => {
  return {
    ...data,
    categories: data.categories || [],
    linkUrls: data.linkUrls.filter(x => x),
    subVisuals: data.subVisuals.filter(x => x),
  }
}

const getPublicUrl = (data:AchivementModel): string => {
  const origin = window.location.origin
  return `${origin}/#/draft/${data.token}`;
}

const AchivementEditPage = () => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showValidationErrorModal, setShowValidationErrorModal] = useState<boolean>(false);
  const history = useHistory();

  const { data, loading, error } = useAchivementByIdQuery({
    variables: { id: id! },
  });

  const [updateMutation, updateMutationState] = useUpdateAchivementMutation();
  const [deleteMutation, deleteMutationState] = useDeleteAchivementsMutation();

  const formMethods = useForm<AchivementForm>({
    validationSchema: AchivementSchema,
    // MARK: trueにするとref={register}した要素がvalidation errorの場合にauthFocusする
    // ref={register}してない要素にfocusできないっぽいのでfalseに設定した
    submitFocusError: false,
  })
  const { register, unregister, handleSubmit, setValue, reset, formState, errors } = formMethods

  useEffect(() => {
    register({ name: statusProp }, {});
    return () => {
      unregister(statusProp);
    }
  }, [register, unregister])

  useEffect(() => {
    if (data) {
     reset(data.achivementById)
    }
  }, [reset, data])

  useEffect(() => {
    const hasValidationError = Object.keys(errors).length > 0
    setShowValidationErrorModal(hasValidationError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.submitCount])

  const onSubmit = async (formData: AchivementForm) => {
    const input = normalizeData(formData as AchivementInput)
    await updateMutation({
      variables: { id: id!, input },
      // refetchQueries: [adminHomeDefaultQuery],
      // awaitRefetchQueries: true,
    })
  }

  const deleteAchivement = async () => {
    await deleteMutation({
      variables: { ids: [id!] },
      // refetchQueries: [adminHomeDefaultQuery],
      // awaitRefetchQueries: true,
    });
    setShowModal(false);
    history.push('/admin')
  }

  if (loading) {
    return <DefaultLayout></DefaultLayout>
  }

  if (data && data.achivementById.id !== id) {
    return <DefaultLayout></DefaultLayout>
  }

  if (!loading && error) {
    return <Redirect to="/admin" />
  }

  const publicUrl = getPublicUrl(data!.achivementById);
  const achivement = data!.achivementById

  return (
    <DefaultLayout>
      <Prompt
        when={formState.dirty}
        message={location => '実績はまだ保存されていません。よろしいですか？'}
      />
      <LoadingOverlay active={updateMutationState.loading || deleteMutationState.loading} />
      {
        showValidationErrorModal &&
        <AlertModal
          message="入力項目に誤りがあります。"
          visibleSec={5}
          onClose={() => {
            setShowValidationErrorModal(false)
          }}
        />
      }
      {
        updateMutationState.called && !updateMutationState.loading && (
          updateMutationState.error ? 
            <AlertModal
              message="ネットワークに接続できませんでした。データを保存するには再度「保存」ボタンを押してください。"
              visibleSec={5}
            />:
            <AlertModal
              message="データを保存しました。"
              visibleSec={3}
            />
        )
      }
      {
        deleteMutationState.called && !deleteMutationState.loading && (
          deleteMutationState.error ? 
            <AlertModal
              message="ネットワークに接続できませんでした。データを削除するには再度「削除」ボタンを押してください。"
              visibleSec={5}
            />:
            <></>
        )
      }
      <ConfirmModal 
        title="実績を削除します。よろしいですか？"
        okLabel="削除"
        isOpen={showModal}
        handleCloseModal={() => setShowModal(false)}
        handleSubmit={() => deleteAchivement()}
      />
      <FormContext {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="section section-works-detail">
            <h3 className="section-title">
              <img src={text_edit_works} alt="EDIT WORKS" />
            </h3>

            <div className="works-edit d-flex align-items-start">
              <div className="works-edit-main bg-white">
                <FormTitleSection />
                <FormVisualSection />
                <FormMetadataSection />
              </div>

              <div className="works-edit-nav">
                <div className="works-edit-nav-block mb-3 bg-white">
                  <div className="form-section py-4">
                    <label className="font-weight-bold mb-0">公開設定</label>
                    <span className="d-inlin-block text-secondary">
                      &nbsp;
                      -
                      &nbsp;
                      <span>
                        { achivement.status === 'draft' ? '下書き' : '公開済' }
                      </span> 
                    </span>
                  </div>
                  <div className="form-section">
                    <div className="form-check form-check-inline mb-0">
                      <input
                        ref={register}
                        id="isPickup"
                        name="isPickup"
                        type="checkbox"
                        className="form-check-input" />
                      <label className="form-check-label" htmlFor="isPickup">
                        PICKUPに表示
                      </label>
                    </div>
                  </div>
                  <div className="form-section">
                    <input
                      onClick={e => setValue(statusProp, 'draft')}
                      type="submit"
                      className="btn btn-border w-100"
                      value={ achivement.status === 'draft' ? "下書き保存" : '下書きに戻す' }  />

                    <input
                      onClick={e => setValue(statusProp, 'opened')}
                      type="submit"
                      className="btn btn-primary w-100 mt-2"
                      value={ achivement.status === 'draft' ? "公開" : '更新' }  />
                  </div>
                  <div className="form-section">
                    <div className="form-gruop">
                      <label>共有用URL</label>

                      <input
                        id="public_url"
                        type="url"
                        className="form-control form-control-md mb-2"
                        placeholder="保存 or プレビュー後に発行"
                        defaultValue={publicUrl} />

                      <input
                        onClick={(e) => {
                          e.preventDefault()
                          copyInputElement("public_url")
                        }}
                        type="submit"
                        className="btn btn-border w-100 mb-2"
                        value="URLをコピー" />

                      <input
                        onClick={e => {
                          e.preventDefault()
                          window.open(publicUrl)
                        }}
                        type="submit"
                        className="btn btn-border w-100"
                        value="プレビュー" />
                    </div>
                  </div>
                </div>

                <div className="works-edit-nav-block bg-white">
                  <button
                    className="btn btn-icon-left"
                    onClick={e => {
                      e.preventDefault()
                      setShowModal(true)
                    }}>
                    <i className="icon icon-trash" /> 実績を削除
                  </button>
                </div>

              </div>
            </div>
          </section>
        </form>
      </FormContext>
    </DefaultLayout>
  )
}

export default AchivementEditPage;
