import React from "react";
import { useHistory } from "react-router-dom";

import SidebarComponent from '../coding/common/Sidebar'
import { useUiContext } from "../../contexts/ui";
import {
  useCurrentUserQuery,
  useCreateDocumentMutation,
} from '../../api';
import {
  mutationResultHasError
} from '../../schema';

import { UserModelUtil } from '../../utils/UserModel'
import LoadingOverlay from "./LoadingOverlay";
import AlertModal from "../modals/AlertModal";

type Props = {
  pcOnly?: boolean
}

const Sidebar = (props: Props) => {
  const [state, dispatch] = useUiContext()
  const userQuery = useCurrentUserQuery();
  const [createDocument, createDocumentState] = useCreateDocumentMutation();
  const history = useHistory();

  let sideNavClass = state.sideNavOpen ? 'show' : ''
  if (props.pcOnly) {
    sideNavClass += ' only-wide'
  }

  const userIsAdmin = userQuery.data && UserModelUtil.isAdmin(userQuery.data.currentUser)

  const isCodingPage = history.location.pathname.startsWith('/coding')

  const createDocumentProcess = async () => {
    const response = await createDocument()
    const id = response.data!.createDocument.id;
    // HACK: 資料編集画面で新規作成するともとの資料編集画面が表示されたままになる問題の対処
    history.push('/');
    const path = `/documents/${id}/edit`
    history.push(path);
  }
  const hasError = mutationResultHasError(createDocumentState)

  return (
    <>
      <LoadingOverlay active={createDocumentState.loading} />
      {
        hasError &&
        <AlertModal
          message="ネットワークに接続できませんでした。"
          visibleSec={5}
        />
      }

      <SidebarComponent
        {...props}
        isCodingPage={isCodingPage}
        userIsAdmin={userIsAdmin}
        sideNavClass={sideNavClass}
        sideNavTogglerClick={(e: React.MouseEvent) => {
          e.preventDefault()
          dispatch({
            type: 'setSideNavOpen',
            payload: !state.sideNavOpen
          })
        }}
        navLinkClick={(e: React.MouseEvent) => {
          dispatch({
            type: 'setSideNavOpen',
            payload: false,
          })
        }}
        createDocumentClick={(e: React.MouseEvent) => {
          e.preventDefault()
          createDocumentProcess();
        }}
        contactClick={async (e: React.MouseEvent) => {
          e.preventDefault()
        }}
      />
    </>
  );
}

export default Sidebar;
