import React, { useState } from "react";
import {
  RouteProps
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useDetailedSearchAchivementsQuery } from '../../api';

import ListPageLayout from "../layouts/ListPageLayout";
import Pagination from '../common/Pagination';
import AchivementLinkCard from '../achivements/AchivementLinkCard';
import SectionNoItem from "../common/SectionNoItem";
import VisibleOnScroll from "../common/VisibleOnScroll";

const perPage = 12

const AchivementsRecentPage = (props: RouteProps) => {
  const [page, setPage] = useState(1);
  const { data } = useDetailedSearchAchivementsQuery({
    fetchPolicy: 'network-only',
    variables: {
      page,
      perPage,
      sortOrder: "desc",
      sortProp: "createdAt",
      periodType: "all",
    }
  });
  const achivements = data?.detailedSearchAchivements;
  const totalCount = data?.detailedSearchAchivements.totalCount
  const totalPage = totalCount ? Math.ceil((totalCount) / perPage) : 0

  return (
    <ListPageLayout pageTitle="最新の実績" totalCount={totalCount}>
      {
        totalCount === 0 && <SectionNoItem message='該当する実績はありません' />
      }
      <section className="section section-card-list has-nav-search">
        <TransitionGroup className="row section-card_transition-group">
          {achivements && achivements.records.map((achivement, i) => 
            <CSSTransition
              key={i}
              timeout={200}
              classNames="section-card_transition-item"
            >
              <div className="col-lg-4">
                <VisibleOnScroll animateClassName="slideup">
                  <AchivementLinkCard achivement={achivement} className="slideup-before" />
                </VisibleOnScroll>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </section>

      {
        totalPage > 0 && <Pagination 
          totalPage={totalPage}
          page={page}
          onChangePage={(x) => setPage(x)}
        />
      }
    </ListPageLayout>
  );
}

export default AchivementsRecentPage;
