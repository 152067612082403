import React from "react";
import { Redirect } from "react-router-dom";
import { RouteProps } from 'react-router'

import { useCurrentUserQuery } from '../../api';

import { UserModelUtil } from '../../utils/UserModel'
import PcOnlyRoute from './PcOnlyRoute'

type Props = RouteProps & { fallbackPath: string; }

const AdminRoute: React.FC<Props> = (props: Props) => {
  const { data, loading } = useCurrentUserQuery();
  const userIsAdmin = data && UserModelUtil.isAdmin(data.currentUser)

  if (!loading && !userIsAdmin) {
    return <Redirect to={props.fallbackPath} />;
  }

  return (
    <PcOnlyRoute {...props}>
     { !loading && props.children }
    </PcOnlyRoute>
  );
}

export default AdminRoute;
