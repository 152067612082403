import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { AchivementModel } from '../../api'
import { AchivementModelUtil } from '../../utils/AchivementModel'

interface Props {
  achivement: AchivementModel;
  handleSelect: Function;
  className?: string;
}

const AchivementCard = (props: Props) => {
  const { achivement, handleSelect } = props;
  const mainVisualImgThumbnail = AchivementModelUtil.mainVisualImgThumbnail(achivement)

  return (
    <div className={`card card-list-item is-no-link is-small ${props.className}`}>
      <div className="card-img-top">
        {
          mainVisualImgThumbnail &&
          <LazyLoadImage className="lazy-load" alt={""} src={mainVisualImgThumbnail.url} width={'100%'} height={'100%'} />
        }
        {/* <button
          className="btn btn-lined-white">
          <i className="arrow"></i>
          詳細を見る
        </button>
        */}
      </div>
      <div className="card-body">
        <h5 className="card-title">{achivement.catchphrase}</h5>
        <p className="card-text">
          <span className="text-light-gray">CLIENT: </span>{achivement.clientName}
        </p>
      </div>
      <div className="card-footer bg-pattern">
        {achivement.categories.map((category, i) => 
          <span key={i} className="btn btn-border tag mr-1 mb-1 is-no-hover">{ category }</span>
        )}
      </div>
      {/* <a href="!#" className="blocklink" onClick={e => {
        e.preventDefault()
        handleSelect()
      }}> </a>
      */}
      <button
        onClick={e => {
          e.preventDefault()
          handleSelect()
        }}      
        className="btn btn-primary btn-block rounded-0 position-relative border-0">
        <i className="icon icon-plus icon-left m-0"></i>
        資料に追加
      </button>
    </div>
  )
}

export default AchivementCard;
