/* eslint-disable react-pug/no-interpolation */
import React from 'react';
import Moment from 'react-moment'

const HeaderNav = (props) => {
  return pug`
    .navbar-header.navbar.navbar-document.bg-white
      nav.navbar-nav
        if props.document
          button.nav-item.btn-primary.only-icon.has-popover.border-0.d-none.d-lg-block(
            onClick=props.memoPopoverClick
          )
            i.icon.icon-edit

          .popover-nav-left.popover.fade.bs-popover-bottom(
            className=${props.memoPopoverClass}
            role="tooltip"
            x-placement="bottom"
          )
            .arrow

            h3.popover-header

            .popover-body
              textarea.form-control.mb-3.input-memo(
                disabled
                name="memo"
                placeholder="メモ"
              )

              .row.row-narrow-gutter
                .col
                  button.btn.btn-border.btn-block(
                    onClick=props.closeMemoPopoverClick
                  ) 閉じる

          .nav-document
            .doc-title.mr-2-5
              h6.mb-lg-0.edi=props.document.title
            
            .doc-date
              span.text-muted UPDATE:
              span.d-inline-block.ml-1 
                Moment(format="YYYY年MM月DD日")=props.document.updatedAt

            .ml-auto.nav-doc-edit.pl-2.d-none.d-lg-flex
              button.btn.btn-border.mr-1(
                onClick=props.editClick
                href="#"
              )
                | 資料の編集

              button.btn.btn-border.mr-1(
                href="#"
                onClick=props.pdfDownloadClick
              )
                | PDFでダウンロード

              button.btn.btn-border.mr-1(
                href="#"
                onClick=props.startPresentationClick
              )
                | プレゼンテーションを開始する
  `
}

export default HeaderNav;
