import React, { useEffect } from 'react';
import { useFormContext, } from 'react-hook-form';
import classNames from 'classnames';
import times from 'lodash.times';
import DatePicker from "react-datepicker";
import TextareaAutosize from 'react-textarea-autosize';

import {
  achivementCategories
} from '../../../constants'
import {
  AchivementForm
} from '../../../schema'

const linkUrlsNum = 3

const categoriesProp = 'categories'
const linkUrlsProp = 'linkUrls'
const startedAtProp = 'startedAt'
const endedAtProp = 'endedAt'
const overviewSectionProp = 'overviewSection'
const backgroundSectionProp = 'backgroundSection'
const resultSectionProp = 'resultSection'
const pointSectionProp = 'pointSection'
const staffListSectionProp = 'staffListSection'

const availableProps = [
  categoriesProp,
  startedAtProp,
  endedAtProp,
  overviewSectionProp,
  backgroundSectionProp,
  resultSectionProp,
  pointSectionProp,
  staffListSectionProp,
]

const AchivementFormMetadataSection = () => {
  const { register, unregister, watch, setValue, errors } = useFormContext<AchivementForm>()

  useEffect(() => {
    availableProps.map(x => register(x))
    return () => {
      availableProps.map(x => unregister(x))
    }
  }, [register, unregister])

  const categories = watch(categoriesProp) || []
  const startedAt = watch(startedAtProp)
  const endedAt = watch(endedAtProp)
  const startedAtDt = startedAt ? new Date(startedAt) : null
  const endedAtDt = endedAt ? new Date(endedAt) : null

  const overviewSection = watch(overviewSectionProp)
  const backgroundSection = watch(backgroundSectionProp)
  const resultSection = watch(resultSectionProp)
  const pointSection = watch(pointSectionProp)
  const staffListSection = watch(staffListSectionProp)

  return (
    <>
      <div className="form-section p-4">
        <div className="form-group">
          <label>クライアント名</label>
          <input
            ref={register}
            name="clientName"
            type="text"
            className={classNames("form-control", { 'is-invalid': errors.clientName })}
            placeholder="" />
          {
            errors.clientName &&
            <div className="invalid-feedback">
              ※クライアント名を入力してください。
            </div>
          }
        </div>
      </div>

      <div className="form-section p-4">
        <div className="form-group">
          <label>商材名</label>
          <input
            ref={register}
            name="productName"
            type="text"
            className={classNames("form-control", { 'is-invalid': errors.productName })}
            placeholder="" />
          {
            errors.productName &&
            <div className="invalid-feedback">
              ※商材名を入力してください。
            </div>
          }
        </div>
      </div>

      <div className="form-section p-4">
        <div className="form-group">
          <label>カテゴリ</label>

          <div className={classNames({ 'is-invalid': errors.categories })}>
            {
              achivementCategories.map((x, index) => (

                <div className="form-check form-check-inline" key={index}>
                  <input
                    type="checkbox"
                    id={`category${index}`}
                    className="form-check-input"
                    checked={categories.includes(x)}
                    value={x}
                    onChange={e => {
                      if (categories.includes(x)) {
                        setValue(categoriesProp, categories.filter(c => c !== x), true)
                      } else {
                        setValue(categoriesProp, [...categories, x], true)
                      }
                    }} />
                  <label className="form-check-label" htmlFor={`category${index}`}>{ x }</label>
                </div>
              ))
            }
          </div>

          {
            errors.categories &&
            <div className="invalid-feedback">
              ※カテゴリを選択してください。
            </div>
          }
        </div>
      </div>

      <div className="form-section p-4">
        <div className="form-group">
          <label>実施時期</label>

          <div className={classNames('form-row', { 'is-invalid': errors.startedAt || errors.endedAt })}>
            <div className="col-3">
              <DatePicker
                locale="ja"
                dateFormat="yyyy/MM/dd"
                isClearable
                popperPlacement="top-start"
                className={classNames("form-control", { 'is-invalid': errors.startedAt })}
                placeholderText="開始日"
                maxDate={endedAtDt}
                selected={startedAtDt}
                onChange={(e) => {
                  setValue(startedAtProp, e ? e.toISOString() : null, true)
                }}
              />
            </div>
            <div className="col-3">
              <DatePicker
                locale="ja"
                dateFormat="yyyy/MM/dd"
                isClearable
                popperPlacement="top-end"
                className={classNames("form-control", { 'is-invalid': errors.endedAt })}
                placeholderText="終了日"
                minDate={startedAtDt}
                selected={endedAtDt}
                onChange={(e) => {
                  setValue(endedAtProp, e ? e.toISOString() : null, true)
                }}
              />
            </div>
          </div>

          {
            ( errors.startedAt || errors.endedAt ) &&
            <div className="invalid-feedback">
              ※実施日時を入力してください。
            </div>
          }

        </div>
      </div>

      <div className="form-section p-4">
        <div className="form-group">
          <label>BACKGROUND</label>

          <TextareaAutosize
            value={backgroundSection || ''}
            onChange={e => setValue(backgroundSectionProp, e.target.value, true)}
            className={classNames("form-control textarea-large", { 'is-invalid': errors.backgroundSection })}
            minRows={3}
          />
          {
            errors.backgroundSection &&
            <div className="invalid-feedback">
              ※BACKGROUNDを入力してください。
            </div>
          }
        </div>
      </div>

      <div className="form-section p-4">
        <div className="form-group">
          <label>TARGET</label>
          <input
            ref={register}
            name="targetSection"
            type="text"
            className={classNames("form-control", { 'is-invalid': errors.targetSection })}
            placeholder="" />
          {
            errors.targetSection &&
            <div className="invalid-feedback">
              ※TARGETを入力してください。
            </div>
          }
        </div>
      </div>
      <div className="form-section p-4">
        <div className="form-group">
          <label>OVERVIEW</label>
          <TextareaAutosize
            value={overviewSection || ''}
            onChange={e => setValue(overviewSectionProp, e.target.value, true)}
            className={classNames("form-control textarea-large", { 'is-invalid': errors.overviewSection })}
            minRows={3}
          />
          {
            errors.overviewSection &&
            <div className="invalid-feedback">
              ※OVERVIEWを入力してください。
            </div>
          }
        </div>
      </div>
      <div className="form-section p-4">
        <div className="form-group">
          <label>RESULT</label>
          <TextareaAutosize
            value={resultSection || ''}
            onChange={e => setValue(resultSectionProp, e.target.value, true)}
            className={classNames("form-control textarea-large", { 'is-invalid': errors.resultSection })}
            minRows={3}
          />
          {
            errors.resultSection &&
            <div className="invalid-feedback">
              ※RESULTを入力してください。
            </div>
          }
        </div>
      </div>
      <div className="form-section p-4">
        <div className="form-group">
          <label>POINT</label>
          <TextareaAutosize
            value={pointSection || ''}
            onChange={e => setValue(pointSectionProp, e.target.value, true)}
            className={classNames("form-control textarea-large", { 'is-invalid': errors.pointSection })}
            minRows={3}
          />
          {
            errors.pointSection &&
            <div className="invalid-feedback">
              ※POINTを入力してください。
            </div>
          }
        </div>
      </div>
      <div className="form-section p-4">
        <div className="form-group">
          <label>STAFF LIST</label>
          <TextareaAutosize
            value={staffListSection || ''}
            onChange={e => setValue(staffListSectionProp, e.target.value, true)}
            className={classNames("form-control textarea-large", { 'is-invalid': errors.staffListSection })}
            minRows={3}
          />
          {
            errors.staffListSection &&
            <div className="invalid-feedback">
              ※STAFF LISTを入力してください。
            </div>
          }
        </div>
      </div>
      <div className="form-section p-4">
        {
          times(linkUrlsNum).map(x => (
            <div className="form-group" key={x}>
              {
                x === 0 && <label>LINK</label>
              }
              <input
                key={x}
                ref={register}
                name={`${linkUrlsProp}[${x}]`}
                type="text"
                className={classNames("form-control", {
                  'is-invalid': errors.linkUrls && (errors.linkUrls as any)[x] })}
                placeholder="" />

              {
                errors.linkUrls && (errors.linkUrls as any)[x] &&
                <div className="invalid-feedback">
                  ※LINK URLを入力してください。
                </div>
              }
            </div>
          ))
        }
      </div>
    </>
  )
}

export default AchivementFormMetadataSection;
