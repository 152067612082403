import React from 'react'
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'
import classNames from 'classnames'

// MARK: https://github.com/lovasoa/react-contenteditable
// functional componentに対応してないぽかった

// MARK: onBlur時にスクロール位置を先頭に戻す
const resetScroll = (el: HTMLElement) => {
  el.scrollTo(0, 0)
}

interface Props {
  tagName: string;
  className: string;
  value?: string | null;
  onChange: (value: string) => void;
  scrollTargetRef?: React.RefObject<HTMLElement>;
  disabled?: boolean;
}
interface State {
}

class EditableElement extends React.Component<Props, State> {
  contentEditable: React.RefObject<HTMLElement>;

  constructor(props: Props) {
    super(props)

    this.contentEditable = React.createRef();
  };
 
  handleChange = (evt: ContentEditableEvent) => {
    const value = evt.target.value
    this.props.onChange(value)
  };

  render = () => {
    const empty =
      this.props.value === '' ||
      this.props.value === '<br>' ||
      this.props.value === '<br/>' ||
      this.props.value === '<br />' ||
      this.props.value === '<div><br></div>' ||
      this.props.value === '<div><br/></div>' ||
      this.props.value === '<div><br /></div>'

    return <ContentEditable
      innerRef={this.contentEditable}
      html={this.props.value || ''}
      disabled={this.props.disabled}
      onChange={this.handleChange}
      onBlur={() => {
        if (this.contentEditable.current) {
          resetScroll(this.contentEditable.current)
        }
        if (this.props.scrollTargetRef?.current) {
          resetScroll(this.props.scrollTargetRef?.current)
        }
      }}
      tagName={this.props.tagName}
      className={classNames(this.props.className, {
        empty: empty,
      })}
    />
  };
};

export default EditableElement