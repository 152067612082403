import React from 'react';
import classNames from 'classnames';
import Moment from 'react-moment';
import {
  Link
} from "react-router-dom";

import {
  AdminHomeDocument,
  useAdminHomeQuery,
  useUpdateAchivementsPickupMutation,
  useDeleteAchivementsMutation, 
  AdminHomeQuery} from '../../../api';

import DefaultLayout from "../../layouts/DefaultAdminLayout";

import LoadingOverlay from "../../common/LoadingOverlay";
import ConfirmModal  from '../../modals/ConfirmModal'
import AlertModal from "../../modals/AlertModal";
import Pagination from '../../common/Pagination'
import text_works from '../../../assets/images/text_works.svg';

const perPage = 10
const defaultPage = 1
const defaultFilterType = null

export const DefaultQuery = {
  query: AdminHomeDocument,
  variables: {
    page: defaultPage,
    perPage,
    filterType: defaultFilterType,
  },
}

const getNavs = (data: AdminHomeQuery) => {
  return [
    {
      filterType: null,
      label: 'すべて',
      count: data.allAchivements.totalCount,
    },
    {
      filterType: "opened",
      label: '公開済',
      count: data.openedAchivements.totalCount,
    },
    {
      filterType: "draft",
      label: '下書き',
      count: data.draftAchivements.totalCount,
    },
    {
      filterType: "pickup",
      label: 'PICKUP表示',
      count: data.pickupAchivements.totalCount,
    },
  ]
}

const HomePage = () => {
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(defaultPage)
  const [filterType, setFilterType] = React.useState<string | null>(defaultFilterType)
  const [selected, setSelected] = React.useState<string[]>([])
  const [allSelected, setAllSelected] = React.useState<boolean>(false)

  const { data, refetch } = useAdminHomeQuery({
    // MARK: 更新時のcache削除が大変なのでcacheしない方針で
    fetchPolicy: 'network-only',
    variables: {
      page,
      perPage,
      filterType
    },
  });
  const [updatePickupMutation, updatePickupState] = useUpdateAchivementsPickupMutation();
  const [deleteMutation, deleteState] = useDeleteAchivementsMutation();

  if (!data) {
    return <DefaultLayout></DefaultLayout>
  }

  const recordIds = data.searchAchivements.records.map(x => x.id)
  const totalCount = data.searchAchivements.totalCount
  const totalPage = totalCount ? Math.ceil((data.searchAchivements.totalCount) / perPage) : 1
  const navs = getNavs(data);

  const resetSelected = () => {
    setAllSelected(false)
    setSelected([])
  }

  const toggleSelected = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter(x => x !== id))
    } else {
      setSelected([...selected, id])
    }
  }
  const toggleAllSelected = () => {
    if (allSelected) {
      resetSelected()
    } else {
      setAllSelected(true)
      setSelected(recordIds)
    }
  }
  const changePage = (nextPage: number) => {
    if (1 > nextPage || nextPage > totalPage) return
    resetSelected()
    setPage(nextPage)
  }

  const changeFilterType = (filterType: string | null) => {
    resetSelected()
    setFilterType(filterType)
  }

  const updateSelectedPickup = async (value: boolean) => {
    if (selected.length <= 0) return
    await updatePickupMutation({
      variables: { ids: selected, value },
    })
    resetSelected()
    await refetch()
  }

  const deleteSelected = async () => {
    if (selected.length <= 0) return
    await deleteMutation({
      variables: { ids: selected },
    })
    resetSelected()
    setShowDeleteModal(false)
    await refetch()
  }

  return (
    <DefaultLayout>
      <LoadingOverlay active={updatePickupState.loading || deleteState.loading} />
      {
        updatePickupState.called && !updatePickupState.loading && (
          updatePickupState.error ? 
            <AlertModal
              message="ネットワークに接続できませんでした。"
              visibleSec={5}
            />:
            <AlertModal
              message="データを保存しました。"
              visibleSec={3}
            />
        )
      }
      {
        deleteState.called && !deleteState.loading && (
          deleteState.error ? 
            <AlertModal
              message="ネットワークに接続できませんでした。"
              visibleSec={5}
            />:
            <AlertModal
              message="データを削除しました。"
              visibleSec={3}
            />
        )
      }

      <section className="section">
        <h3 className="section-title">
          <img alt="WORKS" src={text_works} />
        </h3>

        <nav className="nav nav-filter bg-white">
          {
            navs.map(nav => 
              <a
                key={nav.label}
                className={classNames("nav-link", { active: filterType === nav.filterType })}
                href='!#'
                onClick={e => {
                  e.preventDefault()
                  changeFilterType(nav.filterType)
                }}>
                { nav.label }
                <small className="text-counter">({ nav.count })</small>
              </a>
            )
          }
        </nav>
        <table className="table bg-white has-checkbox has-blocklink">
          <thead className="bg-pattern">
            <tr>
              <td>
                <div className="form-check form-check-inline m-0 checkbox-all">
                  <input
                    type="checkbox"
                    className="form-check-input input-textless is-border"
                    onChange={toggleAllSelected}
                    checked={allSelected} />
                  <label></label>
                </div>
              </td>
              <td className="no-border-x" colSpan={4}>
                <nav className="d-flex nav-table">
                  <a
                    className="d-flex align-items-center mr-4" href="!#"
                    onClick={e => {
                      e.preventDefault()
                      setShowDeleteModal(true)
                    }}>
                    <i className="icon icon-trash mr-2"></i>実績を削除
                  </a>
                  <a
                    className="d-flex align-items-center mr-4" href="!#"
                    onClick={e => {
                      e.preventDefault()
                      updateSelectedPickup(true)
                    }}>
                    <i className="icon icon-checked-circle mr-2"></i>PICKUPに表示
                  </a>
                  <a
                    className="d-flex align-items-center mr-4" href="!#"
                    onClick={e => {
                      e.preventDefault()
                      updateSelectedPickup(false)
                    }}>
                    <i className="icon icon-remove-circle mr-2"></i>PICKUPから除外
                  </a>
                </nav>
              </td>
            </tr>
          </thead>
          <tbody>
            {
              data?.searchAchivements.records.map(record => 
                <tr key={record.id}>
                  <td>
                    <div className="form-check form-check-inline in-blocklink m-0">
                      <input
                        type="checkbox"
                        className="form-check-input input-textless is-border"
                        onChange={() => toggleSelected(record.id)}
                        checked={selected.includes(record.id)} />
                      <label></label>
                    </div>
                  </td>
                  <td>
                    <Link to={`/admin/achivements/${record.id}/edit`} className="blocklink">
                      { record.title }
                    </Link>
                  </td>
                  <td className="td-publish">
                    { record.status === 'draft' ? '下書き' : '公開済' }
                  </td>
                  <td className="td-pickup">
                    { record.isPickup && 'PICKUP表示' }
                  </td>
                  <td className="td-date">
                    <Moment format="YYYY.MM.DD">{ record.updatedAt }</Moment>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </section>

      <Pagination
        page={page}
        totalPage={totalPage}
        onChangePage={(x) => changePage(x)}
       />

      <ConfirmModal 
        title="実績を削除します。よろしいですか？"
        okLabel="削除"
        isOpen={showDeleteModal}
        handleCloseModal={() => setShowDeleteModal(false)}
        handleSubmit={() => deleteSelected()}
      />

    </DefaultLayout>
  );
}

export default HomePage;
