import Cookies from 'universal-cookie';

const COOKIE_API_KEY = 'tokyu'

export const getAuthCookie = (): string | undefined => {
  const cookies = new Cookies()
  return cookies.get(COOKIE_API_KEY)
}

export const setAuthCookie = (token: string, expires?: number): void => {
  const cookies = new Cookies()
  cookies.set(
    COOKIE_API_KEY,
    token,
    { path: '/', expires: expires ? new Date(expires * 1000) : undefined })
}

export const removeAuthCookie = (): void => {
  const cookies = new Cookies()
  cookies.remove(COOKIE_API_KEY)
}
