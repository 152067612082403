import React from "react";

import HeaderNavComponent from '../coding/common/HeaderNav'
import { useUiDispatch } from "../../contexts/ui";
import HeaderNavSearch from './HeaderNavSearch'

type Props = {
  pcOnly? : boolean
  children?: React.ReactNode;
}

const HeaderNav = (props: Props) => {
  const uiDispatch = useUiDispatch()

  const navbarClass = props.pcOnly ? 'only-wide' : ''

  return (
    <HeaderNavComponent
      navbarClass={navbarClass}
      sideNavTogglerClick={(e: React.MouseEvent) => {
        e.preventDefault()
        uiDispatch({
          type: 'setSideNavOpen',
          payload: true
        })
      }}
      searchComponent={<HeaderNavSearch />}
    >
      { props.children }
    </HeaderNavComponent>
  );
}

export default HeaderNav;
