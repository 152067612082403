import React from 'react';
import Moment from 'react-moment'

import { DocumentModel } from '../../api'

import logo from '../../assets/images/logo_tokyu_agency.svg';
import logo_w from '../../assets/images/logo_tokyu_agency_w.svg';

interface Props {
  document: DocumentModel;
}

const CoverImageTypeImage10 = (props: Props) => {
  return (
    <div className="doc-works-slide doc-cover cover-a">
      <div className="doc-works-body">
        <div className="cover-grid-a">
          {
            props.document.coverImgUrls!.slice(0, 5).map((x, index) => (
              <figure className="visual cover-thumbnail" key={index}>
                <img src={x} alt="" />
              </figure>
            ))
          }

          <div className="cover-content-wrapper">
            <div className="cover-content">
              <figure className="logo">
                <img src={logo} alt="Tokyu Agency" />
              </figure>
              <p className="date text-center">
                <Moment format="YYYY.MM.DD">{props.document.updatedAt}</Moment>
              </p>
            </div>
          </div>
          {
            props.document.coverImgUrls!.slice(5).map((x, index) => (
              <figure className="visual cover-thumbnail" key={index}>
                <img src={x} alt="" />
              </figure>
            ))
          }
        </div>
      </div>
    </div>
  )
}

const CoverImageTypeImage6 = (props: Props) => {
  return (
    <div className="doc-works-slide doc-cover cover-b">
      <div className="doc-works-body">
        <div className="cover-grid-b">
          {
            props.document.coverImgUrls!.map((x, index) => (
              <figure className="visual cover-thumbnail" key={index}>
                <img src={x} alt="" />
              </figure>
            ))
          }
          <div className="cover-content-wrapper">
            <div className="cover-content">
              <figure className="logo">
                <img src={logo} alt="Tokyu Agency" />
              </figure>
              <p className="date text-center">
                <Moment format="YYYY.MM.DD">{props.document.updatedAt}</Moment>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CoverImageTypeImage4 = (props: Props) => {
  return (
    <div className="doc-works-slide doc-cover cover-c">
      <div className="doc-works-body">
        <div className="cover-grid-c">
          <div className="cover-content-wrapper">
            <div className="cover-content">
              <figure className="logo">
                <img src={logo} alt="Tokyu Agency" />
              </figure>
              <p className="date text-center">
                <Moment format="YYYY.MM.DD">{props.document.updatedAt}</Moment>
              </p>
            </div>
          </div>
          {
            props.document.coverImgUrls!.map((x, index) => (
              <figure className="visual cover-thumbnail" key={index}>
                <img src={x} alt="" />
              </figure>
            ))
          }
        </div>
      </div>
    </div>
  )
}

const CoverImageTypeImage1 = (props: Props) => {
  return (
    <div className="doc-works-slide doc-cover cover-d">
      <div className="doc-works-body">
        <figure className="visual">
          {
            props.document.coverImgUrls && props.document.coverImgUrls.length > 0 &&
            <img src={props.document.coverImgUrls[0]} alt="" />
          }
        </figure>
        <div className="cover-content">
          <figure className="logo">
            <img src={logo_w} alt="Tokyu Agency" />
          </figure>
          <p className="date text-center text-white">
            <Moment format="YYYY.MM.DD">{props.document.updatedAt}</Moment>
          </p>
        </div>
      </div>
    </div>
  )
}

const CoverPage = (props: Props) => {
  console.log(props.document.coverImgUrls)
  if (!props.document.coverImgUrls || props.document.coverImgUrls.length <= 1) {
    return <CoverImageTypeImage1 {...props} />
  } else if (props.document.coverImgUrls.length === 4) {
    return <CoverImageTypeImage4 {...props} />
  } else if (props.document.coverImgUrls.length === 6) {
    return <CoverImageTypeImage6 {...props} />
  } else {
    return <CoverImageTypeImage10 {...props} />
  }
}

export default CoverPage;