import React, { useRef } from "react";
import {
  useHistory,
} from "react-router-dom";
import Slider from "react-slick";
import times from 'lodash.times'

import { useHomeQuery } from '../../api';

import DefaultLayout from "../layouts/DefaultLayout";
import VisibleOnScroll from "../common/VisibleOnScroll";

import DocumentLinkCard from '../documents/DocumentLinkCard';
import AchivementLinkCard from '../achivements/AchivementLinkCard';
import AchivementTopLinkCard from '../achivements/AchivementTopLinkCard';

import { achivementCategories } from '../../constants'

import { useUiContext } from "../../contexts/ui";

import text_pickup from '../../assets/images/text_pickup.svg';
import text_category from '../../assets/images/text_category.svg';
import text_resentry_works from '../../assets/images/text_resentry_works.svg';
import text_favorite_works from '../../assets/images/text_favorite_works.svg';
import text_my_documents from '../../assets/images/text_my_documents.svg';

const settings = {
  dots: true,
  fade: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 6000,
  pauseOnFocus: true,
  pauseOnDotsHover: true,
};

const settings2 = {
  dots: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  speed: 1000,
  infinite: true,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
  ]
};

const HomePage = () => {
  const currentPickupIndex = useRef<number | null>(null)
  const history = useHistory()
  const { data } = useHomeQuery({
    fetchPolicy: 'network-only',
  });
  const [uiState, uiDispatch] = useUiContext()

  const searchCondition = uiState.achivementsSearch

  const searchByCategory = (category: string) => {
    uiDispatch({
      type: 'setSearchCondition',
      payload: {
        ...searchCondition,
        categories: [category],
      }
    })
    history.push('/achivements/search')
  }

  if (!data) {
    return <DefaultLayout></DefaultLayout>
  }

  if (data.pickupedAchivements.length > 0) {
    currentPickupIndex.current = 0
  }

  return (
    <DefaultLayout>
      {
        data.pickupedAchivements.length > 0 &&
        <section className="section mb-4 mb-lg-5">
          <div className="pickup position-relative">
            <h3 className="pickup-label mb-0 position-absolute">
              <img src={text_pickup} alt="PICKUP" />
            </h3>

            <Slider {...settings} afterChange={index => {
              currentPickupIndex.current = index
            }}>
              {
                data.pickupedAchivements.map(x => (
                  <AchivementTopLinkCard
                    key={x.id}
                    achivement={x}
                    onClickCategory={searchByCategory}
                    onClickCard={() => {
                      if (currentPickupIndex.current !== null) {
                        const x = data.pickupedAchivements[currentPickupIndex.current]
                        history.push(`/achivements/${x.id}`)
                      }
                    }}
                  />
                ))
              }
            </Slider>
          </div>
        </section>
      }

      <section className="section mb-4 mb-lg-5">
        <div className="nav-category d-lg-flex">
          <h3 className="nav-category-title">
            <img src={text_category} alt="CATEGORY" />
          </h3>

          <ul className="nav-category-list">
            {
              achivementCategories.map(x => (
                <li key={x}>
                  <a href="!#" className="tag" onClick={e => {
                    e.preventDefault()
                    searchByCategory(x)
                  }}>{ x }</a>
                </li>
              ))
            }
          </ul>
        </div>
      </section>

      <section className="section mb-4 mb-lg-5">
        <div className="section-title-has-link">
          <h2 className="section-title-text mb-0">
            <img src={text_resentry_works} alt="RECENTRY WORKS" />
          </h2>
          <a className="section-title-link" href="!#" onClick={e => {
            e.preventDefault()
            history.push('/achivements/recent')
          }}>
            <div className="box-icon"><i className="arrow"></i>
                <p className="m-0">一覧を見る</p>
            </div>
          </a>
        </div>

        <Slider className="slide-list-item" {...settings2}>
          {
            data.detailedSearchAchivements.records.map(x => (
              <div className="slide-item" key={x.id}>
                <VisibleOnScroll animateClassName="slideup">
                  <AchivementLinkCard achivement={x} className="slideup-before" />
                </VisibleOnScroll>
              </div>
            ))
          }
          {
            // MARK: 項目が3つ以下の場合にレイアウトが崩れるので追加
            data.detailedSearchAchivements.records.length < 3 &&
            times(3 - data?.detailedSearchAchivements.records.length).map(x => (
              <div className="slide-item" key={x}>
              </div>
            ))
          }
        </Slider>
      </section>

      <section className="section mb-4 mb-lg-5">
        <div className="section-title-has-link">
          <h2 className="section-title-text mb-0">
            <img src={text_favorite_works} alt="FAVORITE WORKS" />
          </h2>
          <a className="section-title-link" href="!#" onClick={e => {
            e.preventDefault()
            history.push('/achivements/favorite')
          }}>
            <div className="box-icon"><i className="arrow"></i>
                <p className="m-0">一覧を見る</p>
            </div>
          </a>
        </div>

        <Slider className="slide-list-item" {...settings2}>
          {
            data.favoriteAchivements.records.map(x => (
              <div className="slide-item" key={x.id}>
                <VisibleOnScroll animateClassName="slideup">
                  <AchivementLinkCard achivement={x} className="slideup-before" />
                </VisibleOnScroll>
              </div>
            ))
          }
          {
            data.favoriteAchivements.records.length < 3 &&
            times(3 - data?.favoriteAchivements.records.length).map(x => (
              <div className="slide-item" key={x}>
              </div>
            ))
          }
        </Slider>
      </section>

      <section className="section mb-4 mb-lg-5">
        <div className="section-title-has-link">
          <h2 className="section-title-text mb-0">
            <img src={text_my_documents} alt="MY DOCUMENTS" />
          </h2>
          <a className="section-title-link" href="!#" onClick={e => {
            e.preventDefault()
            history.push('/documents')
          }}>
            <div className="box-icon"><i className="arrow"></i>
                <p className="m-0">一覧を見る</p>
            </div>
          </a>
        </div>

        <Slider className="slide-list-item" {...settings2}>
          {
            data?.searchUserDocuments.records.map(x => (
              <div className="slide-item" key={x.id}>
                <VisibleOnScroll animateClassName="slideup">
                  <DocumentLinkCard document={x} className="slideup-before" />
                </VisibleOnScroll>
              </div>
            ))
          }
          {
            data.searchUserDocuments.records.length < 3 &&
            times(3 - data?.searchUserDocuments.records.length).map(x => (
              <div className="slide-item" key={x}>
              </div>
            ))
          }
        </Slider>
      </section>
    </DefaultLayout>
  );
}

export default HomePage;
