/* eslint-disable react-pug/no-interpolation */
import React from 'react';
import text_background from '../../../assets/images/text_background.svg';
import text_link from '../../../assets/images/text_link.svg';
import text_overview from '../../../assets/images/text_overview.svg';
import text_point from '../../../assets/images/text_point.svg';
import text_result from '../../../assets/images/text_result.svg';
import text_staff_list from '../../../assets/images/text_staff_list.svg';
import text_target from '../../../assets/images/text_target.svg';
import Moment from 'react-moment'

// import MediaElement from '../../common/MediaElement';
import AchivementMainVisual from '../../common/AchivementMainVisual';
import VisibleOnScroll from '../../common/VisibleOnScroll';

const nl2br = require('react-nl2br');

const WorksDetail = (props) => {
  const achivement = props.achivement

  return pug`
    .works-detail-body.bg-white
      header.works-detail-header
        .works-detail-title.p-4.px-sp
          h2.title
            =achivement.catchphrase

          h5.subtitle 
            =achivement.title

        .works-detail-info.p-4.px-sp.py-sp
          p.d-flex
            span.d-block.text-muted DATE:
            span.d-block
              Moment(format="YYYY.MM")
                =achivement.startedAt

              if achivement.endedAt
                |-

                Moment(format="YYYY.MM")
                  =achivement.endedAt

          p.d-flex
            span.d-block.text-muted CLIENT:
            span.d-block
              =achivement.clientName

          p.d-flex.mb-0
            span.d-block.text-muted ITEM:
            span.d-block
              =achivement.productName
        
      .works-detail-images.bg-pattern.p-4.px-sp.py-sp.border-bottom
        AchivementMainVisual(viewIndex=props.viewIndex achivement=achivement)
                  
        .works-detail-thumbnails.mx-auto
          .row.row-wide-gutter.justify-content-center
            if achivement.mainVisual
              if achivement.mainVisualThumbnail
                .col
                  a.thumbnail.video(
                    onClick=(e) => props.onClickVisualThumbnail(e, 0)
                    className=${props.viewIndex === 0 ? 'active' : ''}
                    href="#",
                  )
                    img.w-100(src=achivement.mainVisualThumbnail.url)

              else
                .col
                  a.thumbnail(
                    onClick=(e) => props.onClickVisualThumbnail(e, 0)
                    className=${props.viewIndex === 0 ? 'active' : ''}
                    href="#",
                  )
                    img.w-100(src=achivement.mainVisual.url)

            each subVisual, index in achivement.subVisuals
              .col(key=index)
                if props.viewIndex === index + 1
                  a.thumbnail.active(
                    onClick=(e) => props.onClickVisualThumbnail(e, index +1)
                    href="#"
                  )
                    img.w-100(src=subVisual.url)

                else
                  a.thumbnail(
                    onClick=(e) => props.onClickVisualThumbnail(e, index +1)
                    href="#"
                  )
                    img.w-100(src=subVisual.url)

      .p-4.px-sp.py-sp.border-bottom
        each category in achivement.categories
          span.btn.btn-border.tag.mr-1.mb-1(key=category onClick=(e) => props.onClickCategory(e, category))
            =category
        
      .works-detail-items
        .col.p-0
          VisibleOnScroll(animateClassName="slideup")
            .works-detail-item.slideup-before
              h3.works-detail-item-title
                img(src=text_background alt="BACKGROUND")
            
              .works-detail-item-body
                =nl2br(achivement.backgroundSection)

          VisibleOnScroll(animateClassName="slideup")
            .works-detail-item.slideup-before
              h3.works-detail-item-title
                img(src=text_target alt="TARGET")
              
              .works-detail-item-body
                =nl2br(achivement.targetSection)

          VisibleOnScroll(animateClassName="slideup")
            .works-detail-item.slideup-before
              h3.works-detail-item-title
                img(src=text_overview alt="OVERVIEW")
              
              .works-detail-item-body
                =nl2br(achivement.overviewSection)

        .col.p-0
          VisibleOnScroll(animateClassName="slideup")
            .works-detail-item.slideup-before
              h3.works-detail-item-title
                img(src=text_result alt="RESULT")
              
              .works-detail-item-body
                =nl2br(achivement.resultSection)

          VisibleOnScroll(animateClassName="slideup")
            .works-detail-item.slideup-before
              h3.works-detail-item-title
                img(src=text_point alt="POINT")
              
              .works-detail-item-body
                =nl2br(achivement.pointSection)

          VisibleOnScroll(animateClassName="slideup")
            .works-detail-item.slideup-before
              h3.works-detail-item-title
                img(src=text_staff_list alt="STAFF LIST")
              
              .works-detail-item-body
                =nl2br(achivement.staffListSection)

          VisibleOnScroll(animateClassName="slideup")
            .works-detail-item.slideup-before
              h3.works-detail-item-title
                img(src=text_link alt="LINK")
              
              .works-detail-item-body
                ul
                  each linkUrl in achivement.linkUrls
                    li.mb-2(key=linkUrl)
                      a.link-underline(
                        href=linkUrl target="_blank" rel="noopener")
                        =linkUrl
  `
}

export default WorksDetail;
