import * as yup from 'yup'
import * as ApolloReactCommon from '@apollo/react-common';

import {
  DocumentInput,
  DocumentPageInput,
  AttachmentInput,
  AchivementCopyInput,
  AchivementInput
} from './api'

type Nullable<T> = { [P in keyof T]: T[P] | null }

export const mutationResultHasError = (result: ApolloReactCommon.MutationResult) => {
  return result.called && !result.loading && result.error
}

export const mutationResultSuccess = (result: ApolloReactCommon.MutationResult) => {
  return result.called && !result.loading && result.data
}

export const AttachmentSchema = yup.object().shape<AttachmentInput>({
  url: yup.string().url().required(),
  fileName: yup.string().required(),
  mimeType: yup.string().required(),
  fileSize: yup.number().required(),
})

export type AchivementForm = Nullable<AchivementInput>

export const requiredStringWhenStatusIsOpened = yup.mixed().when('status', {
  is: 'opened', then: yup.string().required(),
})

export const AchivementSchema = yup.object().shape<AchivementInput>({
  status: yup.string().required(),
  isPickup: yup.bool().required(),
  title: requiredStringWhenStatusIsOpened,
  catchphrase: requiredStringWhenStatusIsOpened,
  productName: requiredStringWhenStatusIsOpened,
  clientName: requiredStringWhenStatusIsOpened,
  targetSection: requiredStringWhenStatusIsOpened,
  overviewSection: requiredStringWhenStatusIsOpened,
  resultSection: requiredStringWhenStatusIsOpened,
  pointSection: requiredStringWhenStatusIsOpened,
  staffListSection: requiredStringWhenStatusIsOpened,
  backgroundSection: requiredStringWhenStatusIsOpened,
  startedAt: requiredStringWhenStatusIsOpened,
  endedAt: yup.string().nullable(),
  categories: yup.array(yup.string()).when('status', {
    is: 'opened',
    then: yup.array(yup.string()).required(),
  }),
  linkUrls: yup.array(
    yup.string().url(),
  ),
  subVisuals: yup.array(),
  mainVisual: yup.mixed().when('status', {
    is: 'opened',
    then: AttachmentSchema.required(),
  }),
  mainVisualThumbnail: yup.mixed().when('status', {
    is: 'opened',
    then: yup.mixed().when(
    'mainVisual', {
      is: val => val && val.mimeType && !val.mimeType.startsWith('image/'),
      then: AttachmentSchema.required(),
    })
  })
}).test({
  name: 'check_dt',
  test: (value: AchivementInput) => {
    if (!value.startedAt || !value.endedAt) return true
    const x = new Date(value.startedAt)
    const y = new Date(value.endedAt)
    return x <= y
  }
});

export const AchivementCopySchema = yup.object().shape<AchivementCopyInput>({
  id: yup.string().required(),
  token: yup.string().required(),
  createdAt: yup.string().required(),
  updatedAt: yup.string().required(),
  favoriteCount: yup.number().required(),
  status: yup.string().required(),
  isPickup: yup.bool().required(),
  title: yup.string().required(),
  catchphrase: yup.string().required(),
  productName: yup.string().required(),
  clientName: yup.string().required(),
  targetSection: yup.string().required(),
  overviewSection: yup.string().required(),
  resultSection: yup.string().required(),
  pointSection: yup.string().required(),
  staffListSection: yup.string().required(),
  backgroundSection: yup.string().required(),
  startedAt: yup.string().required(),
  endedAt: yup.string().nullable(),
  categories: yup.array(yup.string()),
  linkUrls: yup.array(yup.string().url()),
  subVisuals: yup.array(),
  mainVisual: AttachmentSchema.required(),
  mainVisualThumbnail: yup.mixed().when('mainVisual', {
    is: val => val && val.mimeType && !val.mimeType.startsWith('image/'),
    then: AttachmentSchema.required(),
  })
});

export const DocumentPageSchema = yup.object().shape<DocumentPageInput>({
  type: yup.string().required(),
  layoutType: yup.string().required(),
  layoutPage: yup.number().required(),
  baseAchivement: AchivementCopySchema.nullable(),
})

export const DocumentSchema = yup.object().shape<DocumentInput>({
  title: yup.string().required(),
  designType: yup.string().required(),
  memo: yup.string(),
  pages: yup.array(DocumentPageSchema),
});

export type DocumentForm = Nullable<DocumentInput>