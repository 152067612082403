import React from 'react'
import { $PropertyType } from 'utility-types';

type SetSideNavOpenAction = {
  type: 'setSideNavOpen';
  payload: boolean;
}

type SetSearchOpenAction = {
  type: 'setSearchOpen';
  payload: boolean;
}

type SetSearchSortOpenAction = {
  type: 'setSearchSortOpen';
  payload: boolean;
}

type CloseSearch = {
  type: 'closeSearch';
}

type ResetSearchCondition = {
  type: 'resetSearchCondition';
}

type SetSearchCondition = {
  type: 'setSearchCondition';
  payload: $PropertyType<State, 'achivementsSearch'>;
}

type Action =
  SetSideNavOpenAction | ResetSearchCondition | SetSearchCondition |
  SetSearchOpenAction | SetSearchSortOpenAction | CloseSearch

type Dispatch = (action: Action) => void

type State = {
  sideNavOpen: boolean;
  searchOpen: boolean;
  searchSortOpen: boolean;
  achivementsSearch: {
    categories: string[];
    text: string;
    sortOrder: string;
    sortProp: string;
    periodType: string;
    startedAt: string | null;
    endedAt: string | null;
    submitCount: number;
  };
}

const StateContext = React.createContext<State | undefined>(undefined)

const DispatchContext = React.createContext<Dispatch | undefined>(undefined)

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setSideNavOpen': {
      return {
        ...state,
        sideNavOpen: action.payload,
      }
    }
    case 'setSearchOpen': {
      return {
        ...state,
        searchOpen: action.payload,
      }
    }
    case 'setSearchSortOpen': {
      return {
        ...state,
        searchSortOpen: action.payload,
      }
    }
    case 'closeSearch': {
      return {
        ...state,
        searchSortOpen: false,
        searchOpen: false,
      }
    }
    case 'resetSearchCondition': {
      return {
        ...state,
        achivementsSearch: {
          ...initialState.achivementsSearch,
          submitCount: state.achivementsSearch.submitCount
        }
      }
    }
    case 'setSearchCondition': {
      return {
        ...state,
        achivementsSearch: action.payload,
      }
    }
  }
}

const initialState: State = {
  sideNavOpen: false,
  searchOpen: false,
  searchSortOpen: false,
  achivementsSearch: {
    categories: [],
    text: '',
    sortOrder: 'desc',
    sortProp: 'createdAt',
    periodType:  'all',
    startedAt: null,
    endedAt: null,
    submitCount: 0,
  }
}

export const UiContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useUiState = () => {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error('useUiState must be used within a UiContextProvider')
  }
  return context
}

export const useUiDispatch = () => {
  const context = React.useContext(DispatchContext)
  if (context === undefined) {
    throw new Error('useUiDispatch must be used within a UiContextProvider')
  }
  return context
}

export const useUiContext = (): [State, Dispatch] => {
  return [
    useUiState(),
    useUiDispatch()
  ]
}
