import React from "react";

import HeaderNav from "../headers/HeaderNav";
import Sidebar from "../common/Sidebar";

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <>
      <Sidebar />
      <main className="main-content">
        <div className="main-container">
          <HeaderNav />
          { children }
        </div>
      </main>
    </>
  );
}

export default DefaultLayout;
