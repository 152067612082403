import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { AchivementModel } from '../../api'
import { AchivementModelUtil } from '../../utils/AchivementModel'

interface Props {
  achivement: AchivementModel;
  onClickCategory: (category: string) => void;
  onClickCard?: (achivement: AchivementModel) => void;
}

const AchivementCard = (props: Props) => {
  const history = useHistory();
  const { achivement } = props;

  const mainVisualImgThumbnail = AchivementModelUtil.mainVisualImgThumbnail(achivement)
  
  return (
    <div className="card card-list-item" onClick={(e) => {
      e.preventDefault()
      props.onClickCard && props.onClickCard(achivement)
    }}>
      <div className="d-flex flex-column flex-lg-row h-100">
        <div className="pickup-image card-img card-img-top">
          {
            mainVisualImgThumbnail && 
            <img src={mainVisualImgThumbnail.url} alt="" />
          }
          <div className="btn btn-lined-white" onClick={e => {
            e.preventDefault()
            history.push(`/achivements/${achivement.id}`)
          }}>
            <i className="arrow"></i>
            詳細を見る
          </div>
        </div>
        <div className="pickup-info">
          <div className="card-body">
            <h5 className="card-title">{achivement.catchphrase}</h5>
            <p className="card-text small">
              <span className="text-light-gray">CLIENT: </span>{achivement.clientName}
            </p>
          </div>
          <div className="card-footer bg-pattern">
            {achivement.categories.map((category, i) => 
              <span key={i} className="btn btn-border tag mr-1 mb-1 is-no-hover" onClick={e => {
                e.preventDefault()
                props.onClickCategory(category)
              }}>{ category }</span>
            )}
          </div>
        </div>
      </div>
      {/* スライダー内のhover表示調整に必要 */}
      <Link to={`/achivements/${achivement.id}`} className="blocklink"></Link>
    </div>
  )
}

export default AchivementCard;
