import React from 'react';
import { useParams, } from 'react-router-dom';

import {
  useAchivementByTokenQuery,
  AchivementModel,
} from '../../api'

import AchivementDetail from '../achivements/AchivementDetail'

import { copyInputElement } from '../../utils/ui';

const getPublicUrl = (data:AchivementModel): string => {
  const origin = window.location.origin
  return `${origin}/#/draft/${data.token}`;
}

const AchivementPreviewPage = () => {
  const { token } = useParams();

  const { data, loading, error } = useAchivementByTokenQuery({
    variables: { token: token! },
  });

  if (loading) {
    return <></>
  }

  if (!data || (data && data.achivementByToken.token !== token)) {
    return <></>
  }

  if (!loading && error) {
    return <></>
  }

  const achivement = data!.achivementByToken;
  const publicUrl = getPublicUrl(data!.achivementByToken);

  return (
    <main className="main-content no-sidebar only-wide">
      <div className="main-container">
        <div className="navbar-header navbar p-0 navbar-admin bg-white only-wide">
          <nav className="navbar-nav">
            <div className="nav-item pr-0 border-right-0">
              <label className="mb-0 font-weight-bold">
                共有用URL
              </label>
            </div>
            <div className="nav-item py-0 col border-right-0">
              <input
                id="public_url"
                type="text"
                className="form-control form-control-md bg-gray"
                defaultValue={publicUrl} />
            </div>
            <div className="nav-item pl-0">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  copyInputElement("public_url")
                }}
                className="btn btn-primary">
                コピー
              </button>
            </div>
          </nav>
        </div>
        <section className="section-works-detail">
          <article className="works-detail-main">
            <AchivementDetail achivement={achivement} />
          </article>
        </section>
      </div>
    </main>
  )
}

export default AchivementPreviewPage;

