import React from "react";

import HeaderNav from "../headers/HeaderNavForDocumentShow";
import HeaderNavSp from '../headers/HeaderNav'
import Sidebar from "../common/Sidebar";

import {
  DocumentModel,
} from '../../api';

type Props = {
  children?: React.ReactNode;
  document?: DocumentModel;
  createPdf?: Function;
  startPresentation?: Function;
}

const DocumentShowLayout: React.FC<Props> = ({ children, document, createPdf, startPresentation }) => {
  return (
    <>
      <Sidebar />
      <main className="main-content">
        <div className="main-container">
          <HeaderNav document={document} createPdf={createPdf} startPresentation={startPresentation} />
          <HeaderNavSp />

          { children }
        </div>
      </main>
    </>
  );
}

export default DocumentShowLayout;
