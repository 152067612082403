import React, { useState } from "react";
import {
  RouteProps
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useFavoriteAchivementsQuery, useUnsetFavoriteAchivementMutation } from '../../api';
import {
  mutationResultHasError
} from '../../schema';

import ListPageLayout from "../layouts/ListPageLayout";
import Pagination from '../common/Pagination';
import AchivementLinkCard from '../achivements/AchivementLinkCard';
import ConfirmModal from "../modals/ConfirmModal";
import SectionNoItem from "../common/SectionNoItem";
import LoadingOverlay from "../common/LoadingOverlay";
import AlertModal from "../modals/AlertModal";
import VisibleOnScroll from "../common/VisibleOnScroll";

const perPage = 12

const AchivementsFavoritePage = (props: RouteProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<null|string>(null);
  const [unsetFavoriteAchivementMutation, unsetFavoriteAchivementState] = useUnsetFavoriteAchivementMutation();
  const [page, setPage] = useState(1);
  const { data, refetch } = useFavoriteAchivementsQuery({
    fetchPolicy: 'network-only',
    variables: {
      page,
      perPage,
    }
  });
  const achivements = data?.favoriteAchivements;
  const totalCount = achivements?.totalCount
  const totalPage = totalCount ? Math.ceil((totalCount) / perPage) : 0
 
  const unsetFavoriteAchivement = async () => {
    if (!deleteId) { return }
    await unsetFavoriteAchivementMutation({
      variables: { id: deleteId }
    });
    setShowModal(false);
    setDeleteId(null)
    refetch();
  }

  const loading = unsetFavoriteAchivementState.loading
  const hasError = mutationResultHasError(unsetFavoriteAchivementState)

  return (
    <ListPageLayout pageTitle="お気に入り" totalCount={totalCount}>
      <LoadingOverlay active={loading} />
      {
        hasError &&
        <AlertModal
          message="ネットワークに接続できませんでした。"
          visibleSec={5}
        />
      }
      <ConfirmModal 
        title="お気に入りから削除しますか？"
        okLabel="削除"
        isOpen={showModal}
        handleCloseModal={() => setShowModal(false)}
        handleSubmit={() => unsetFavoriteAchivement()}
      />

      {
        totalCount === 0 && <SectionNoItem message='該当する実績はありません' />
      }

      <section className="section section-card-list has-nav-search">
        <TransitionGroup className="row section-card_transition-group">
          {achivements && achivements.records.map((achivement, i) => 
            <CSSTransition
              key={i}
              timeout={200}
              classNames="section-card_transition-item"
            >
              <div className="col-lg-4">
                <VisibleOnScroll animateClassName="slideup">
                  <AchivementLinkCard className="slideup-before" achivement={achivement} handleRemove={() => {
                    setShowModal(true)
                    setDeleteId(achivement.id)
                  }} />
                </VisibleOnScroll>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </section>

      {
        totalPage > 0 && <Pagination 
          totalPage={totalPage}
          page={page}
          onChangePage={(x) => setPage(x)}
        />
      }
    </ListPageLayout>
  );
}

export default AchivementsFavoritePage;
