import React from "react";
import { useParams } from 'react-router-dom';
import classNames from "classnames";

import DocumentPage from "../documents/DocumentPage";
import DocumentCoverPage from "../documents/DocumentCoverPage";

import {
  useDocumentByIdQuery,
} from '../../api';

const DocumentShowPdfPage = () => {
  const { id } = useParams();
  const { data, loading, error } = useDocumentByIdQuery({
    variables: { id: id! }
  });

  if (data && data.documentById.id !== id) {
    return <></>
  }

  if (!loading && error) {
    return <></>
  }

  if (loading) {
    return <></>
  }

  const document = data!.documentById

  return (
    <main className="pdf-content">
        <div className={classNames("document-display", {
        })}>
          <div
            className={classNames(
              "document-display-wrapper",
              {
                'design-simple': document.designType === 'simple' || document.designType === 'dark',
                'design-simple-light': document.designType === 'simple',
                'design-simple-dark': document.designType === 'dark',
                'design-modern': document.designType === 'modern',
              }
            )}
          >
            <DocumentCoverPage document={document} />
            {
              document.pages.map((x, index) => (
                <DocumentPage
                  key={index}
                  designType={document.designType}
                  documentPage={x}
                  index={index}
                />
              ))
            }
          </div>
        </div>
    </main>
  );
}

export default DocumentShowPdfPage;
