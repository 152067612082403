/* eslint-disable react-pug/no-interpolation */
import React from 'react';

const Pagination = (props) => {
  return pug`
    nav.mb-5
      ul.pagination.justify-content-center
        li.page-item(className=${props.page > 1 ? 'visible' : 'invisible'})
          a.page-link.page-nav(
            onClick=props.onPrevClick
            href="!#"
            aria-label="Previous")
            span.arrow.arrow-prev

            span.sr-only Previous

        each page, index in props.pages
          if page == props.page
            li.page-item.active(key=index)
              a.page-link(
                onClick=(e) => props.onPageClick(e, page)
                href="!#"
              )=page

          else
            li.page-item(key=index)
              a.page-link(
                onClick=(e) => props.onPageClick(e, page)
                href="!#"
              )=page

        li.page-item(className=${props.page < props.totalPage ? 'visible' : 'invisible'})
          a.page-link.page-nav(
            onClick=props.onNextClick
            href="!#"
            aria-label="Next")
            span.arrow.arrow-next

            span.sr-only Next
  `
}

export default Pagination;
