import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
  useSearchUserDocumentsQuery,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
} from '../../api';

import {
  mutationResultHasError,
  mutationResultSuccess,
} from '../../schema';

import ListPageLayout from "../layouts/ListPageLayout";
import SectionNoItem from "../common/SectionNoItem";
import Pagination from '../common/Pagination';
import LoadingOverlay from "../common/LoadingOverlay";
import ConfirmModal from "../modals/ConfirmModal";
import AlertModal from "../modals/AlertModal";
import VisibleOnScroll from "../common/VisibleOnScroll";

import DocumentLinkCard from '../documents/DocumentLinkCard';

import icon_add_black from '../../assets/images/icon_add_black.svg';

const perPage = 12

const DocumentsPage = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<null|string>(null);
  const [page, setPage] = useState(1)
  const { data, refetch } = useSearchUserDocumentsQuery({
    fetchPolicy: 'network-only',
    variables: {
      page,
      perPage,
    }
  });

  const [deleteMutation, deleteState] = useDeleteDocumentMutation();
  const [createMutation, createState] = useCreateDocumentMutation();

  const deleteDocument = async () => {
    if (!deleteId) { return }
    await deleteMutation({ variables: { id: deleteId } })
    setShowModal(false);
    setDeleteId(null)
    await refetch()
  }

  const createDocument = async () => {
    const response = await createMutation()
    const id = response.data!.createDocument.id;
    const path = `/documents/${id}/edit`
    history.push(path);
  }

  const documents = data?.searchUserDocuments
  const totalCount = documents?.totalCount
  const totalPage = totalCount ? Math.ceil((totalCount) / perPage) : 0

  const mutationHasError = mutationResultHasError(deleteState) || mutationResultHasError(createState)
  const deleteSuccess = mutationResultSuccess(deleteState)

  return (
    <ListPageLayout pageTitle="作成した資料" totalCount={totalCount}>
      <LoadingOverlay active={deleteState.loading || createState.loading} />
      {
        mutationHasError && <AlertModal message="ネットワークに接続できませんでした。" visibleSec={5} />
      }
      {
        deleteSuccess && <AlertModal message="データを削除しました。" visibleSec={3} />
      }

      {
        totalCount === 0 && <SectionNoItem type="document" message='作成済みの資料はありません' />
      }

      <section className="section section-card-list has-nav-search">
        <TransitionGroup className="row section-card_transition-group">
          {documents && documents.records.map((document, i) => 
            <CSSTransition
              key={i}
              timeout={200}
              classNames="section-card_transition-item"
            >
              <div className="col-lg-4">
                <VisibleOnScroll animateClassName="slideup">
                  <DocumentLinkCard className="slideup-before" document={document} handleRemove={() => {
                    setShowModal(true)
                    setDeleteId(document.id)
                  }} />
                </VisibleOnScroll>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </section>

      <button
        onClick={e => {
          e.preventDefault()
          createDocument()
        }}
        className="btn btn-light floating">
        <i className="icon">
          <img src={icon_add_black} alt="" />
        </i>
        <p className="m-0">新規作成</p>
      </button>

      {
        totalPage > 0 && <>
          <Pagination 
            totalPage={totalPage}
            page={page}
            onChangePage={(x) => setPage(x)}
          />

          <ConfirmModal 
            title="削除しますか？"
            okLabel="削除"
            isOpen={showModal}
            handleCloseModal={() => setShowModal(false)}
            handleSubmit={() => deleteDocument() }
          />
        </>
      }
    </ListPageLayout>
  );
}

export default DocumentsPage;
