import Modal from "react-modal"
import React, { useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import { useDebounce } from 'use-debounce';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import text_search_and_add from '../../assets/images/text_search_and_add.svg';
import icon_no_item from '../../assets/images/icon_no_item.svg';

import {
  AchivementModel,
  useDetailedSearchAchivementsQuery
} from '../../api';

import {
  achivementCategories,
  achivementDetailedSearchPeriodTypes,
} from "../../constants"

import Pagination from '../common/Pagination';
import AchivementCard from '../achivements/AchivementCardForSelect';
import VisibleOnScroll from "../common/VisibleOnScroll";

interface Props {
  isOpen: boolean;
  handleCloseModal(): void;
  handleSubmit(achivement: AchivementModel): void;
}

const perPage = 12

const SearchAchivementsModal = (props: Props) => {
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState<string[]>([])
  const [periodType, setPeriodType] = useState<string>('all')
  const [endedAt, setEndedAt] = useState<null | string>(null)
  const [startedAt, setStartedAt] = useState<null | string>(null)
  const [text, setText] = useState<string>('')
  const [debouncedText] = useDebounce(text, 1000, { leading: true });

  const endedAtDt = endedAt ? new Date(endedAt) : null
  const startedAtDt = startedAt ? new Date(startedAt) : null

  const { data } = useDetailedSearchAchivementsQuery({
    fetchPolicy: 'network-only',
    variables: {
      page,
      perPage,
      text: debouncedText,
      sortOrder: 'desc',
      sortProp: 'createdAt',
      categories,
      periodType,
      startedAt,
      endedAt,
    }
  });

  const achivements = data?.detailedSearchAchivements;
  const totalCount = achivements?.totalCount
  const totalPage = totalCount ? Math.ceil((totalCount) / perPage) : 0

  const modalContentRef = useRef(null)

  return (
    <Modal
      isOpen={props.isOpen}
      overlayClassName="Overlay"
      className="modal-dialog-lg"
      onRequestClose={props.handleCloseModal}
    >
      <div className="modal-content" ref={modalContentRef}>
        <div className="modal-header">
          <h3 className="modal-title-lg">
            <img src={text_search_and_add} alt="SEARCH AND ADD"/>
          </h3>
          <div className="form-group form-group-search form-group-search mb-0 ml-auto">
            <i className="p-0 icon icon-search form-control-feedback" />
            <input
              type="text"
              placeholder="実績を検索"
              className="form-control has-icon"
              value={text}
              onChange={e => setText(e.currentTarget.value)}
            />
          </div>
          <button type="button" className="close ml-4 pl-0" onClick={props.handleCloseModal}>
            <span aria-hidden="true">
              <i className="icon icon-close-dark-sm"></i>
            </span>
          </button>
        </div>

        <div className="nav-search w-100">
          <div className="form-section pb-1 border-top">
            <h6 className="title has-bullet">カテゴリ</h6>
            <div className="form-group mb-0">
              { achivementCategories.map((x, index) => (
                <div className="form-check form-check-inline" key={x}>
                  <input
                    id={`category${index}`}
                    checked={categories.includes(x)}
                    onChange={e => {
                      const newCategories = categories.includes(x) ?
                        categories.filter(c => c !== x) : [...categories, x]
                      setCategories(newCategories)
                    }}
                    type="checkbox"
                    className="form-check-input" value={x} />
                  <label className="form-check-label" htmlFor={`category${index}`}>{ x }</label>
                </div>
              ))}
            </div>
          </div>

          <div className="form-section pt-4">
            <h6 className="title has-bullet mb-2">実施時期</h6>
              <div className="form-group d-flex align-item-center mb-0">
                {
                  achivementDetailedSearchPeriodTypes.map((x, index) => (
                    <div key={index} className="form-check form-check-inline">
                      <input
                        id={`period${x.key}`}
                        type="radio"
                        name="period"
                        className="form-check-input"
                        value={x.key}
                        onChange={e => setPeriodType(e.currentTarget.value)}
                        checked={periodType === x.key}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`period${x.key}`}
                      >{x.val}</label>
                    </div>
                  ))
                }

                <div className="form-row" style={{width : '310px'}}>
                  <div className="col">
                    <div className="react-datepicker-wrapper">
                      <div className="react-datepicker__input-container">
                        <DatePicker
                          locale="ja"
                          dateFormat="yyyy/MM/dd"
                          isClearable
                          popperPlacement="top-start"
                          className="form-control is-small"
                          placeholderText="開始日"
                          maxDate={endedAtDt}
                          selected={startedAtDt}
                          onChange={e => {
                            setPeriodType(e ? 'specify' : periodType)
                            setStartedAt(e ? e.toISOString() : null)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="react-datepicker-wrapper">
                      <div className="react-datepicker__input-container">
                        <DatePicker
                          locale="ja"
                          dateFormat="yyyy/MM/dd"
                          isClearable
                          popperPlacement="top-start"
                          className="form-control is-small"
                          placeholderText="終了日"
                          minDate={startedAtDt}
                          selected={endedAtDt}
                          onChange={e => {
                            setPeriodType(e ? 'specify' : periodType)
                            setEndedAt(e ? e.toISOString() : null)
                          }}
                        />
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-body-gray position-relative">
          {
            totalCount === 0 &&
            <section className="section-no-item">
              <figure className="icon-no-item pl-0">
                <img src={icon_no_item} alt="" />
                <figcaption className="text-secondary">
                  該当する実績はありません
                </figcaption>
              </figure>
            </section>
          }

          <TransitionGroup className="row section-card_transition-group">
            {achivements && achivements.records.map((achivement, i) => 
              <CSSTransition
                key={i}
                timeout={200}
                classNames="section-card_transition-item"
              >
                <div className="col-3">
                  <VisibleOnScroll animateClassName="slideup" scrollableAncestor={modalContentRef.current}>
                    <AchivementCard 
                      className="slideup-before"
                      achivement={achivement}
                      handleSelect={() => {
                        props.handleSubmit(achivement)
                      }}
                    />
                  </VisibleOnScroll>
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>

          {
            totalPage > 0 && <>
              <Pagination 
                totalPage={totalPage}
                page={page}
                onChangePage={(x) => {
                  // TODO: refつかう
                  // TOPにscroll
                  const elem = document.getElementsByClassName('modal-dialog-lg')
                  if (elem.length > 0) {
                    elem[0].scrollTo(0, 0)
                  }

                  setPage(x)
                }}
              />
            </>
          }
        </div>
      </div>
    </Modal>
  );
}

export default SearchAchivementsModal