import React from 'react';

import spinner from '../../assets/images/spinner.svg';

interface Props {
  active: boolean;
}

const LoadingOverlay = (props: Props) => {
  if (!props.active) {
    return <></>
  }
  return (
    <div className="loading-overlay">
      <div className="loading-icon">
        <img src={spinner} alt=""/>
      </div>
    </div>
  )
}

export default LoadingOverlay;
