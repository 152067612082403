/* eslint-disable react-pug/no-interpolation */
import React from 'react';

const HeaderNavSearchResult = (props) => {
  return pug`
    ul.navbar-nav.nav-search-result
      li.nav-item
        | 検索結果

        if props.totalCount != undefined
          span.lead.d-inlin-block.mx-2.mb-1=props.totalCount

          | 件

      li.nav-item.dropdown.active(className=${props.dropdownClass})
        a#navbarDropdown.nav-link.dropdown-toggle(
          onClick=props.dropdownClick
          href="",
          role="button",
          data-toggle="dropdown",
          aria-haspopup="true",
          aria-expanded="false")
          span=props.currentItem ? props.currentItem.val : ''

        .dropdown-menu(
          className=${props.dropdownClass}
          aria-labelledby="navbarDropdown" )
          each sortType in props.sortTypes
            .dropdown-form-item(key=sortType.key)
              .form-check
                input.form-check-input(
                  id=sortType.key
                  type="radio"
                  name="sort"
                  checked=props.itemChecked(sortType.key)
                  onChange=props.itemChange
                  value=sortType.key)
                label.form-check-label(for=sortType.key)=sortType.val
  `
}

export default HeaderNavSearchResult;
