import React, { useState, useRef, useEffect } from "react";
import { useParams, Redirect, } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce'
import classNames from "classnames";
import { useWindowResize, useInterval } from 'beautiful-react-hooks'; 

import Layout from "../layouts/DocumentShowLayout";
import LoadingOverlay from "../common/LoadingOverlay";
// import DocumentPageThumbnail from "../documents/DocumentPageThumbnail";

import DocumentPage from "../documents/DocumentPage";

import { useAppContext } from "../../contexts/app";

import {
  useDocumentByIdQuery,
} from '../../api';

const DocumentShowPage = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)

  const { id } = useParams();
  const { downloadPdf, } = useAppContext()
  const [pdfCreating, setPdfCreating] = useState(false)
  const { data, loading, error } = useDocumentByIdQuery({
    variables: { id: id! }
  });
  const [selectedPageIndex, setSelectedPageIndex] = useState<number | null>(null);

  const [presentationMode, setPresentationMode] = useState(false);
  const [showPresentationPager, setPresentationPager] = useState(false);

  const presentationPagerTimer = useRef<number>(0);

  const pagesDomRef = useRef<(HTMLDivElement | null)[]>([]);

  const createPdf = async () => {
    if (!data) return
    try {
      const targetUrl = `/documents/${id!}/pdf`
      setPdfCreating(true)
      const title = data.documentById.title
      await downloadPdf(targetUrl, `${title}.pdf`);
    } finally {
      setPdfCreating(false)
    }
  }

  useEffect(() => {
    if (data && selectedPageIndex === null) {
      if (data.documentById.pages.length > 0) {
        setSelectedPageIndex(0)
      }
    }
  }, [data, selectedPageIndex])

  useInterval(() => {
    if (!presentationPagerTimer) return
    if (presentationPagerTimer.current <= 0) {
      setPresentationPager(false)
    }
    if (presentationPagerTimer.current > 0) {
      presentationPagerTimer.current -= 1
    }
  }, 1000);

  const [showAndHidePresentationPagerAfter5Secs] = useDebouncedCallback(
    () => {
      setPresentationPager(true)
      // MARK: 5秒後に閉じる
      presentationPagerTimer.current = 5
    },
    100, {
      leading: true
    }
  );

  const startPresentation = () => {
    setPresentationMode(true)
    showAndHidePresentationPagerAfter5Secs()
  }

  const handleKeepPagerShowEvent = () => {
    if (presentationMode) {
      showAndHidePresentationPagerAfter5Secs()
    }
  }

  useWindowResize((evt: any) => {
    setScreenWidth(evt.target.innerWidth)
    setScreenHeight(evt.target.innerHeight)
  });

  if (data && data.documentById.id !== id) {
    return <Redirect to="/" />
  }

  if (!loading && error) {
    return <Redirect to="/" />
  }

  if (loading) {
    return (
      <Layout></Layout>
    )
  }

  const document = data!.documentById
  const isSp = screenWidth <= 960

  const changePage = (index: number) => {
    setSelectedPageIndex(index)
    if (isSp && pagesDomRef.current[index]) {
      pagesDomRef.current[index]!.scrollIntoView(true);
      // MARK: header分?微調整
      window.scrollBy(0, -60);
    }
  }

  let scale = 1
  let marginBottom = 0
  if (presentationMode) {
    scale = screenHeight / 966;
    if (screenHeight <= 587) {
      scale = 587 / 966;
    }
  } else {
    if (isSp) {
      // MARK: 左右の余白
      const padding = 16
      const pageWidth = screenWidth - padding * 2
      const pagePaddingBottom = 96
      scale = pageWidth / 1366;
      // MARK: プレゼンテーションモード以外ではscaleした分だけmarginBottomを設定
      // MARK: モバイル表示ではページ枚数の高さになる
      marginBottom = ( 966 + pagePaddingBottom ) * document.pages.length * ( scale - 1 )
    } else {
      if (screenWidth <= 1366) {
        // MARK: スクリーンサイズ1366px未満の場合は806pxで固定
        scale = 806 / 1366;
      } else {
        // MARK: ページ表領域以外の部分の縦方向の長さ
        const verticalMargin = 60 + 48 + 48
        const pageWidth = screenWidth - 1366 + 806
        scale = pageWidth / 1366;
        const pageHeight = 966 * scale
        // MARK: 表示可能な高さを超える場合はscreenHeightでscaleを決める
        if(pageHeight > screenHeight - verticalMargin) {
          scale = ( screenHeight - verticalMargin ) / 966
        }
      }
      // MARK: プレゼンテーションモード以外ではscaleした分だけmarginBottomを設定
      marginBottom = 966 * ( scale - 1 )
    }
  }

  const thumbnailScale = isSp ? 63 / 1336 : 112 / 1336

  return (
    <Layout document={data!.documentById} createPdf={createPdf} startPresentation={startPresentation}>
      <LoadingOverlay active={pdfCreating} />
      <section className="section section-document-edit"
        onClick={handleKeepPagerShowEvent}
        onMouseMove={handleKeepPagerShowEvent}
      >
        <div
          className="document-display-overlay"
          style={{
            opacity: presentationMode ? 1 : 0,
            position: presentationMode ? 'fixed' : 'static',
          }}
        ></div>

        <div className={classNames("document-display align-items-start justify-content-between", {
          presentation: presentationMode,
        })}>
          <div className="document-display-main">
            <div className="document-scale-wrapper">
              <div 
                className={classNames(
                  "document-display-wrapper",
                  {
                    'design-simple': document.designType === 'simple' || document.designType === 'dark',
                    'design-simple-light': document.designType === 'simple',
                    'design-simple-dark': document.designType === 'dark',
                    'design-modern': document.designType === 'modern',
                  }
                )}
                style={{
                  transform: `scale(${scale})`,
                  marginBottom: marginBottom,
                }}
              >
                {
                  document.pages.map((x, index) => (
                    <DocumentPage
                      key={index}
                      ref={el => pagesDomRef.current[index] = el}
                      isSelected={selectedPageIndex === index}
                      designType={document.designType}
                      documentPage={x}
                      index={index}
                    />
                  ))
                }
              </div>
            </div>
          </div>

          <div className="document-display-nav mr-3 bg-white" style={{
            display: presentationMode ? 'none' : 'block',
          }}>
            <ol className="doc-list mb-0">
              {
                document.pages.map((x, index) => (
                  <li key={index} className={classNames({ active: selectedPageIndex === index })}>
                    <div className="position-relative">
                      <div className="thubmnail">
                        <div className="document-scale-wrapper">
                          <div
                            className={classNames(
                              "document-display-wrapper",
                              {
                                'design-simple': document.designType === 'simple' || document.designType === 'dark',
                                'design-simple-light': document.designType === 'simple',
                                'design-simple-dark': document.designType === 'dark',
                                'design-modern': document.designType === 'modern',
                              }
                            )}
                            style={{
                              transform: `scale(${thumbnailScale})`,
                              transformOrigin: 'left top',
                            }}
                          >
                            <DocumentPage
                              key={index}
                              designType={document.designType}
                              documentPage={x}
                              index={index}
                            />
                          </div>
                        </div>
                        <a
                          onClick={e => {
                            e.preventDefault()
                            changePage(index)
                          }}
                          className="blocklink"
                          href="#!"> </a>
                      </div>
                    </div>
                  </li>
                ))
              }
            </ol>
          </div>
        </div>

        {
          document && selectedPageIndex !== null  &&
          <div
            className="document-page-nav"
            style={{
              transition: 'opacity 400ms',
              opacity: !presentationMode || showPresentationPager ? 1 : 0,
            }}
          >
            <a
              onClick={e => {
                e.preventDefault()
                if (selectedPageIndex > 0) {
                  changePage(selectedPageIndex - 1)
                }
              }}
              className="arrow arrow-prev"
              href="#!"> </a>
            <div className="page-number">
              { selectedPageIndex + 1 }
              <div className="d-inline-block mx-2">/</div>
              { document.pages.length }
            </div>
            <a
              onClick={e => {
                e.preventDefault()
                if (selectedPageIndex < document.pages.length - 1) {
                  changePage(selectedPageIndex + 1)
                }
              }}
              className="arrow arrow-next"
              href="#!"> </a>
            {
              presentationMode && 
              <a
                onClick={e => {
                  e.preventDefault()
                  setPresentationMode(false)
                }}
                className="ml-3 nav-cancel"
                href="#!">終了</a>
            }
          </div>
        }

      </section>
    </Layout>
  );
}

export default DocumentShowPage;
