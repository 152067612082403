import React from "react";
import { useHistory } from "react-router-dom";

import { useUiContext } from "../../contexts/ui";
import {
  achivementDetailedSearchSortTypes,
} from "../../constants"
import HeaderNavSearchResultComponent from "../coding/common/HeaderNavSearchResult";

type Props = {
  totalCount?: number;
}

const HeaderNavSearchResult: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const [uiState, uiDispatch] = useUiContext()

  const searchCondition = uiState.achivementsSearch

  const search = (sortProp: string, sortOrder: string) => {
    uiDispatch({ type: 'closeSearch' })
    uiDispatch({
      type: 'setSearchCondition',
      payload: {
        ...searchCondition,
        sortProp,
        sortOrder,
        submitCount: searchCondition.submitCount + 1,
      }
    })
    const pathname = '/achivements/search'
    if (history.location.pathname !== pathname) {
      history.push(pathname)
    }
  }

  const currentItem = achivementDetailedSearchSortTypes.find(
    x => x.key === `${searchCondition.sortProp}_${searchCondition.sortOrder}`)

  return (
    <HeaderNavSearchResultComponent
      totalCount={props.totalCount}
      sortTypes={achivementDetailedSearchSortTypes}
      dropdownClass={uiState.searchSortOpen ? 'show' : ''}
      dropdownClick={(e: React.MouseEvent) => {
        e.preventDefault()
        uiDispatch({
          type: 'setSearchSortOpen', payload: !uiState.searchSortOpen
        })
      }}
      currentItem={currentItem}
      itemChecked={(e: string) => e === `${searchCondition.sortProp}_${searchCondition.sortOrder}`}
      itemChange={(e: React.KeyboardEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value
        const [sortProp, sortOrder] = value.split("_")
        search(sortProp, sortOrder)
      }}
    />
  );
}

export default HeaderNavSearchResult;
