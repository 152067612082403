import React, { useEffect, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form'
import { useHistory, Prompt } from 'react-router-dom';

import {
  useCreateAchivementMutation,
  AchivementModel,
  AchivementInput,
} from '../../../api'

import {
  AchivementSchema,
  AchivementForm,
} from '../../../schema'

import AlertModal from "../../modals/AlertModal";

import DefaultLayout from "../../layouts/DefaultAdminLayout";

import LoadingOverlay from "../../common/LoadingOverlay";

import FormTitleSection  from '../forms/AchivementFormTitleSection'
import FormVisualSection  from '../forms/AchivementFormVisualSection'
import FormMetadataSection  from '../forms/AchivementFormMetadataSection'

import text_create_new_works from '../../../assets/images/text_create_new_works.svg';

// import { DefaultQuery as adminHomeDefaultQuery } from './Home'

const statusProp = 'status'

const normalizeData = (data: AchivementInput): AchivementInput => {
  return {
    ...data,
    categories: data.categories || [],
    linkUrls: data.linkUrls.filter(x => x),
    subVisuals: data.subVisuals.filter(x => x),
  }
}

const getEditPagePath = (data: AchivementModel): string => {
  return `/admin/achivements/${data.id}/edit`
}

const AchivementNewPage = () => {
  const history = useHistory()

  const [showValidationErrorModal, setShowValidationErrorModal] = useState<boolean>(false);
  const [createMutation, createMutationState] = useCreateAchivementMutation();

  const formMethods = useForm<AchivementForm>({
    validationSchema: AchivementSchema,
    // MARK: trueにするとref={register}した要素がvalidation errorの場合にauthFocusする
    // ref={register}してない要素にfocusできないっぽいのでfalseに設定した
    submitFocusError: false,
  })
  const { reset, register, unregister, handleSubmit, setValue, formState, errors } = formMethods

  useEffect(() => {
    register({ name: statusProp }, {});

    return () => {
      unregister(statusProp);
    }
  }, [register, unregister])

  useEffect(() => {
    const hasValidationError = Object.keys(errors).length > 0
    setShowValidationErrorModal(hasValidationError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.submitCount])

  const onSubmit = async (data: AchivementForm) => {
    const input = normalizeData(data as AchivementInput)
    const response = await createMutation({
      // refetchQueries: [adminHomeDefaultQuery],
      // awaitRefetchQueries: true,
      variables: { input },
    })
    reset()
    history.push(getEditPagePath(response.data!.createAchivement))
  }

  return (
    <DefaultLayout>
      <Prompt
        when={formState.dirty}
        message={location => '実績はまだ保存されていません。よろしいですか？'}
      />
      <LoadingOverlay active={createMutationState.loading} />
      {
        showValidationErrorModal &&
        <AlertModal
          message="入力項目に誤りがあります。"
          visibleSec={5}
          onClose={() => {
            setShowValidationErrorModal(false)
          }}
        />
      }
      {
        createMutationState.called && !createMutationState.loading && (
          createMutationState.error ? 
            <AlertModal
              message="ネットワークに接続できませんでした。データを保存するには再度「保存」ボタンを押してください。"
              visibleSec={5}
            />:
            <></>
        )
      }
      <FormContext {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="section section-works-detail">
            <h3 className="section-title">
              <img src={text_create_new_works} alt="CREATE A NEW WORKS" />
            </h3>
            <div className="works-edit d-flex align-items-start">
              <div className="works-edit-main bg-white">
                <FormTitleSection />
                <FormVisualSection />
                <FormMetadataSection />
              </div>

              <div className="works-edit-nav">
                <div className="works-edit-nav-block mb-3 bg-white">
                  <div className="form-section py-4">
                    <label className="font-weight-bold mb-0">公開設定</label>
                    <span className="d-inlin-block text-secondary">
                      &nbsp;
                      -
                      &nbsp;
                      <span>
                        新規作成
                      </span> 
                    </span>
                  </div>
                  <div className="form-section">
                    <div className="form-check form-check-inline mb-0">
                      <input
                        ref={register}
                        id="isPickup"
                        name="isPickup"
                        type="checkbox"
                        className="form-check-input" />
                      <label className="form-check-label" htmlFor="isPickup">
                        PICKUPに表示
                      </label>
                    </div>
                  </div>
                  <div className="form-section">
                    <input
                      onClick={e => setValue(statusProp, 'draft')}
                      type="submit"
                      className="btn btn-border w-100"
                      value="下書き保存"  />

                    <input
                      onClick={e => setValue(statusProp, 'opened')}
                      type="submit"
                      className="btn btn-primary w-100 mt-2"
                      value="公開" />
                  </div>
                  <div className="form-section">
                    <div className="form-gruop">
                      <label>共有用URL</label>

                      <input
                        id="public_url"
                        type="url"
                        className="form-control form-control-md mb-2"
                        placeholder="保存 or プレビュー後に発行"
                        disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </FormContext>
    </DefaultLayout>
  )
}

export default AchivementNewPage;
