import React from "react";

import HeaderNav from "../headers/HeaderNavAdmin";
import Sidebar from "../common/Sidebar";

const DefaultAdminLayout: React.FC = ({ children }) => {
  return (
    <>
      <Sidebar pcOnly />
      <main className="main-content only-wide">
        <div className="main-container">
          <HeaderNav />

          { children }
        </div>
      </main>
    </>
  );
}

export default DefaultAdminLayout;
