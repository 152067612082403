import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Moment from 'react-moment'
import { DocumentModel } from '../../api'

import img_no_item from '../../assets/images/img_no_item.png';

interface Props {
  document: DocumentModel;
  handleSelect: Function;
  className?: string;
}

const Card = (props: Props) => {
  const { document, handleSelect } = props;

  return (
    <div className={`card card-list-item is-small ${props.className}`}>
      <div className="card-img-top">
        <LazyLoadImage className="lazy-load" alt={""} src={document.thumbnailImgUrl || img_no_item} width={'100%'} height={'100%'} />

        <button
          className="btn btn-lined-white"
          onClick={e => {
            e.preventDefault()
            handleSelect();
          }}
        >
          <i className="icon icon-plus"></i>
          資料に追加
        </button>
      </div>
      <div className="card-body">
        <h5 className="card-title">{document.title}</h5>
        <p className="card-text">
          <span className="text-light-gray">UPDATE: </span>
          <Moment format="YYYY年MM月DD日">
            {document.updatedAt}
          </Moment>
        </p>
      </div>
      <a href="!#" className="blocklink" onClick={e => {
        e.preventDefault()
        handleSelect()
      }}> </a>
    </div>
  )
}

export default Card;
