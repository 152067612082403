import moment from 'moment';
import {
  // DocumentModel,
  DocumentPageModel,
  AchivementModel,
  AchivementCopyModel,
} from '../api';

import { DocumentPageEditableProp } from "../components/documents/DocumentPage";

export class DocumentModelUtil {
  static addNewPage(
    pages: DocumentPageModel[], 
    targetPageIndex: number,
    achivement: AchivementModel): DocumentPageModel[] | undefined {

    // detail、profileの途中ページにはページ追加不可
    const page = pages[targetPageIndex]
    if (page && page.layoutPage >= 2) return;

    const newPage = DocumentModelUtil.createDefaultPage(achivement)
    const copyPages = [...pages]
    copyPages.splice(targetPageIndex, 0, newPage);

    return copyPages
  }

  static addNewProfilePages(
    pages: DocumentPageModel[], 
    targetPageIndex: number): DocumentPageModel[] | undefined {

    // detail、profileの途中ページにはページ追加不可
    const page = pages[targetPageIndex]
    if (page && page.layoutPage >= 2) return;

    const copyPages = [...pages]
    const newPages = [
      {
        type: 'profile',
        layoutType: 'simple',
        layoutPage: 1,
        baseAchivement: null,
      },
      {
        type: 'profile',
        layoutType: 'simple',
        layoutPage: 2,
        baseAchivement: null,
      },
      {
        type: 'profile',
        layoutType: 'simple',
        layoutPage: 3,
        baseAchivement: null,
      },
    ]
    copyPages.splice(targetPageIndex, 0, ...newPages);
    return copyPages
  }

  static updatePage(
    pages: DocumentPageModel[], 
    index: number,
    prop: DocumentPageEditableProp,
    value: string): DocumentPageModel[] {
    const copyPages = [...pages]
    copyPages[index][prop] = value
    return copyPages
  }

  static deletePage(
    pages: DocumentPageModel[], 
    index: number): DocumentPageModel[] | undefined {

    const targetPage = pages[index]

    if (!targetPage) return

    // MARK: detailの2番目ページ、profileの2, 3番目ページからは削除不可
    const currentPage = targetPage.layoutPage
    if (currentPage >= 2) return;

    const copyPages = [...pages]
    
    // MARK: 削除対象がdetailページの場合は2ページ, profileの場合は3ページ削除する
    let deleteSize = 1
    if (targetPage.type === 'profile') { deleteSize = 3 }
    else if (targetPage.layoutType === 'detail') { deleteSize = 2 }

    copyPages.splice(index, deleteSize);

    return copyPages
  }

  static changePageLayout(
    pages: DocumentPageModel[], 
    index: number,
    newLayout: string): DocumentPageModel[] | undefined {

    const currentLayout = pages[index].layoutType
    const currentType = pages[index].type
    const currentPage = pages[index].layoutPage

    if (currentLayout === newLayout) return;

    // MARK: 会社案内はレイアウト変更不可
    if (currentType === 'profile') return;

    // MARK: detailの2番目ページからはレイアウト変更不可
    if (currentPage >= 2) return;

    const copyPages = [...pages]

    if (currentLayout === 'detail') {
      // MARK: detailから変更の場合は2ページ目を削除する
      copyPages.splice(index + 1, 1);
    }

    copyPages[index].layoutType = newLayout
    copyPages[index].layoutPage = 1

    if (newLayout === 'detail') {
      // MARK: detailに変更の場合は2ページ目を追加する
      const secondDetailPage = {
        ...copyPages[index],
        layoutPage: 2,
      }
      copyPages.splice(index + 1, 0, secondDetailPage);
    }

    return copyPages
  }

  static createDefaultPage(a: AchivementModel | AchivementCopyModel): DocumentPageModel {
    return {
      type: 'achivement',
      layoutPage: 1,
      layoutType: 'image',
      baseAchivement: a as AchivementCopyModel,
      title: a.title,
      catchphrase: a.catchphrase,
      targetSection: a.targetSection,
      backgroundSection: (a.backgroundSection || '').replace(/\r?\n/g, '<br>'),
      overviewSection: ( a.overviewSection || '').replace(/\r?\n/g, '<br>'),
      pointSection: (a.pointSection || '').replace(/\r?\n/g, '<br>'),
      resultSection: (a.resultSection || '').replace(/\r?\n/g, '<br>'),
      clientName: a.clientName,
      productName: a.productName,
      startedMonth: moment(a.startedAt!).format('YYYY.MM'),
      linkUrl: a.linkUrls.length > 0 ? a.linkUrls[0] : '',
    }
  }
}